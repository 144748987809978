import clsx from "clsx";
import {
  ISingleColumnMediaFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { ContentfulCenteredImage } from "../shared";
import { useEffectOnce } from "react-use";

type SingleColumnProps = {
  fields: SpecificLocaleFields<ISingleColumnMediaFields>;
};

export default function SingleColumn({ fields }: SingleColumnProps) {
  useEffectOnce(() => {
    const allSections = document.querySelectorAll(
      '[data-content-type="singleColumnMedia"]'
    );

    if (!allSections) return;

    allSections.forEach((el) => {
      const sibling = el.previousSibling as HTMLElement;
      if (el && sibling?.dataset.contentType === "cardsGrid") {
        const spanWithColorClass = sibling.querySelector(
          `span.bg-${sibling.dataset.color}`
        ) as HTMLElement;
        const colorFromGridCards =
          window.getComputedStyle(spanWithColorClass).backgroundColor;
        const divToColor = el.querySelector(".top-part") as HTMLElement;
        divToColor.style.backgroundColor = colorFromGridCards;
      }
    });
  });

  return (
    <section
      data-content-type="singleColumnMedia"
      data-module-title={fields.moduleTitle}
      className={clsx("relative w-screen", {
        "bg-black": fields.bottomColor === "black",
        "bg-white": fields.bottomColor === "white",
        "bg-slate": fields.bottomColor === "slate",
      })}
      style={{ backgroundColor: fields.colorPalette?.fields.accentColor }}
    >
      <div
        className={clsx("top-part absolute top-0 h-1/2 w-screen", {
          "bg-black": fields.topColor === "black",
          "bg-white": fields.topColor === "white",
          "bg-slate": fields.topColor === "slate",
        })}
        style={{ backgroundColor: fields.colorPalette?.fields.backgroundColor }}
      />
      <div className="tbi-grid relative z-10 py-12 sm:py-16">
        <div className="col-span-4 aspect-3/4 overflow-hidden lg:col-span-12 lg:aspect-video">
          <ContentfulCenteredImage
            image={fields.image.fields}
            bg="transparent"
            className="h-full w-full bg-transparent"
          />
        </div>
      </div>
    </section>
  );
}
