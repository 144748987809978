import { ReactNode, useEffect, useRef, useState } from "react";

type TableBlockProps = {
  children: ReactNode;
};

export default function TableBlock({ children }: TableBlockProps) {
  const tableContainer = useRef<HTMLDivElement>(null);
  let [isOverflowingRight, setIsOverflowingRight] = useState(false);
  let [isOverflowingLeft, setIsOverflowingLeft] = useState(false);
  useEffect(() => {
    if (tableContainer.current) {
      let width = tableContainer.current.querySelector("table")?.offsetWidth;
      if (width) {
        setIsOverflowingRight(tableContainer.current.scrollWidth < width);
      }
    }
  }, []);

  const onScroll = (e: React.UIEvent<HTMLElement>) => {
    if (tableContainer.current) {
      let width = tableContainer.current.querySelector("table")?.offsetWidth;
      if (width) {
        const el = e.target as HTMLElement;
        setIsOverflowingRight(el.scrollLeft < el.scrollWidth - el.offsetWidth);
        setIsOverflowingLeft(el.scrollLeft > 0);
      }
    }
  };

  return (
    <div
      ref={tableContainer}
      className="global-grid relative col-span-full mt-10"
    >
      <div
        onScroll={onScroll}
        className="always-show-scrollbar col-span-full"
        tabIndex={0}
      >
        {isOverflowingLeft ? (
          <div className="absolute left-20 top-0 bottom-0 w-[1px] bg-black" />
        ) : null}
        {isOverflowingRight ? (
          <div className="absolute right-20 top-0 bottom-0 w-[1px] bg-black" />
        ) : null}
        <table
          role="table"
          className="width-max-content mx-auto min-w-[100%] text-left lg:min-w-[600px]"
        >
          <tbody>{children}</tbody>
        </table>
      </div>
      <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
    </div>
  );
}
