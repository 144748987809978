import clsx from "clsx";
import { MouseEvent } from "react";
import NodeIcon from "./NodeIcon";
import { CtaIconButtonProps, EventElementTypes } from "../types";
import NavOrExternalLink from "../shared/NavOrExternalLink";

const CircleIconCtaKinds = {
  black: "black",
  white: "white",
  hollow: "hollow",
  whiteHollow: "white-hollow",
};

type CircleIconCtaKind = "black" | "white" | "hollow" | "white-hollow";

export interface CircleIconCtaProps extends CtaIconButtonProps {
  kind: CircleIconCtaKind;
}

export default function CircleIconCta({
  label,
  icon,
  kind,
  disabled,
  to,
  onClick,
  navLinkProps,
  className = "",
  iconClassName = "",
  as = "button",
  id,
  dataAttrs,
}: CircleIconCtaProps) {
  const btnClassName = clsx({
    "cursor-pointer": true,
    "h-[40px]": true,
    "w-[40px]": true,
    "lg:h-[64px]": true,
    "lg:w-[64px]": true,
    cta: true,
    "rounded-full": true,
    "hover:underline": !disabled,
    flex: true,
    "justify-center": true,
    "items-center": true,
    border: [
      CircleIconCtaKinds.hollow,
      CircleIconCtaKinds.whiteHollow,
    ].includes(kind),
    "border-solid": [
      CircleIconCtaKinds.hollow,
      CircleIconCtaKinds.whiteHollow,
    ].includes(kind),
    "border-black": [CircleIconCtaKinds.hollow].includes(kind) && !disabled,
    "border-white":
      [CircleIconCtaKinds.whiteHollow].includes(kind) && !disabled,
    "border-secondary_text":
      [CircleIconCtaKinds.hollow, CircleIconCtaKinds.whiteHollow].includes(
        kind
      ) && disabled,
    "bg-black": [CircleIconCtaKinds.black].includes(kind) && !disabled,
    "bg-white": [CircleIconCtaKinds.white].includes(kind) && !disabled,
    "bg-transparent":
      [CircleIconCtaKinds.hollow, CircleIconCtaKinds.whiteHollow].includes(
        kind
      ) && !disabled,
    "bg-secondary_text":
      ![CircleIconCtaKinds.hollow, CircleIconCtaKinds.whiteHollow].includes(
        kind
      ) && disabled,
    "text-black":
      [CircleIconCtaKinds.white].includes(kind) ||
      ([CircleIconCtaKinds.hollow].includes(kind) && !disabled),
    "text-white":
      ([CircleIconCtaKinds.black].includes(kind) && !disabled) ||
      ([CircleIconCtaKinds.whiteHollow].includes(kind) && !disabled),
    "text-secondary_text":
      [CircleIconCtaKinds.hollow, CircleIconCtaKinds.whiteHollow].includes(
        kind
      ) && disabled,
    "hover:bg-slate":
      [
        CircleIconCtaKinds.white,
        CircleIconCtaKinds.hollow,
        CircleIconCtaKinds.whiteHollow,
      ].includes(kind) && !disabled,
    "hover:text-black":
      [CircleIconCtaKinds.whiteHollow].includes(kind) && !disabled,
    "focus:bg-charcoal": [CircleIconCtaKinds.black].includes(kind),
    "focus:bg-dark_grey": [
      CircleIconCtaKinds.white,
      CircleIconCtaKinds.hollow,
      CircleIconCtaKinds.whiteHollow,
    ].includes(kind),
    "active:bg-onyx": [CircleIconCtaKinds.black].includes(kind) && !disabled,
    "active:bg-slate": [CircleIconCtaKinds.white].includes(kind) && !disabled,
  });

  const handleClick = (e: MouseEvent<EventElementTypes>) => {
    if (onClick && !disabled) {
      onClick(e);
    }
  };

  if (to && !disabled) {
    return (
      <NavOrExternalLink
        id={id}
        to={to}
        className={`${btnClassName} ${className}`}
        onClick={handleClick}
        title={label}
        {...navLinkProps}
        {...dataAttrs}
      >
        <NodeIcon aria-label={label} className={iconClassName}>
          {icon}
        </NodeIcon>
      </NavOrExternalLink>
    );
  }

  const As = as;

  return (
    <As
      id={id}
      disabled={disabled}
      className={`${btnClassName} ${className}`}
      onClick={handleClick}
      title={label}
      {...dataAttrs}
    >
      <NodeIcon aria-label={label} className={iconClassName}>
        {icon}
      </NodeIcon>
    </As>
  );
}
