import {
  IImageFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";

type ImgProps = {
  image: SpecificLocaleFields<IImageFields>;
  className?: string;
};

export default function ContentfulImg({ image, className = "" }: ImgProps) {
  return (
    <img
      src={
        image.frontifyImage
          ? image.frontifyImage[0].generic_url
          : image.image?.fields?.file?.url
      }
      alt={image.alt ?? "image"}
      className={className}
      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
        (e.currentTarget.src =
          "https://tbi-assets.s3.eu-west-2.amazonaws.com/all-images/placeholder.png")
      }
    />
  );
}
