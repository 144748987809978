import { ReactNode } from "react";
import { Block, Inline } from "@contentful/rich-text-types";

type TableCellProps = {
  children: ReactNode;
  contentLength?: number;
  header?: boolean;
  node: Block | Inline;
};

export default function TableCell({
  children,
  contentLength = 0,
  header = false,
  node,
}: TableCellProps) {
  if (header)
    return (
      <th
        role="rowheader"
        colSpan={node.data?.colspan ? Number(node.data.colspan) : 1}
        rowSpan={node.data?.rowspan ? Number(node.data.rowspan) : 1}
        className="relative bg-zinc-200 py-5 pr-3 last:pr-5 lg:first:pl-5 [&>:first-child]:mt-0 [&>:last-child]:mb-0"
      >
        {children}
        <span
          className="absolute -left-[100vw] -right-[100vw] top-0 -bottom-[1px] border-b border-solid border-black bg-zinc-200"
          style={{ zIndex: -9 }}
        />
      </th>
    );

  let width = 400;
  if (contentLength < 10) {
    width = 100;
  } else if (contentLength < 30) {
    width = 200;
  }

  return (
    <td
      role="cell"
      className="max-w-[600px] py-5 pr-3 align-top lg:first:pl-5 [&>:first-child]:mt-0 [&>:last-child]:mb-0"
      style={{ minWidth: `max(${width}px, max-content)` }}
      colSpan={node.data?.colspan ? Number(node.data.colspan) : 1}
      rowSpan={node.data?.rowspan ? Number(node.data.rowspan) : 1}
    >
      {children}
    </td>
  );
}
