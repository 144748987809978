import { Popover } from "@headlessui/react";
import clsx from "clsx";
import { ALGOLIA_FACETS } from "~/config";
import { CloseCta, RoundedCta, TextCta } from "../ctas";
import { Arrow, Filter } from "../icons";
import rangeRight from "lodash/rangeRight";
import { FacetMenu } from "./FacetMenu";
import { PreventBodyScroll } from "../utils/PreventBodyScroll";
import { useFilterState } from "./filterState";
import { useNavShowing } from "~/contexts/NavShowingProvider";
import { useMedia } from "react-use";

export function getYears() {
  return rangeRight(1980, new Date().getFullYear() + 1).map(String);
}

export default function SearchFilters({ className }: { className?: string }) {
  const { setNavShowing, setNavDisabled, navShowing } = useNavShowing();

  const {
    menuState,
    numFiltersApplied,
    numFiltersChecked,
    numResults,
    handleChange,
    handleSubmit,
    clearFilters,
  } = useFilterState();

  // See tailwind config for this value
  const isLarge = useMedia("(min-width: 1024px)");

  const toggleNav = () => {
    if (isLarge) return;

    if (navShowing) {
      setNavShowing(false);
      setNavDisabled(true);
    } else {
      setNavShowing(true);
      setNavDisabled(false);
    }
  };

  return (
    <Popover className={className}>
      {({ open }) => (
        <>
          <PreventBodyScroll enabled={open} />
          <Popover.Button as="div" onClick={toggleNav}>
            <TextCta
              id="show-filters-cta"
              className=" md:gap-0"
              kind="white-tertiary"
              type="button"
              labelClassName="hidden md:block"
              label={`Show Filters`}
              icon={
                <div className="flex items-center whitespace-pre">
                  <span
                    className={clsx("inline text-secondary_text", {
                      hidden: !numFiltersApplied,
                    })}
                  >{` (${numFiltersApplied}) `}</span>
                  <Filter aria-label="Show filters" className="ml-0 md:ml-4" />
                </div>
              }
            />
          </Popover.Button>
          <Popover.Overlay
            id="search-filters-backdrop"
            className="fixed inset-0 bg-black opacity-80 lgmax:hidden"
          />
          <Popover.Panel className="w-screen text-white">
            <div
              id="search-filters-popover"
              data-open={open}
              className="lg:below-nav fixed inset-0 z-50 flex min-h-[425px] flex-col bg-black lg:bottom-[20%] small-height:bottom-0"
            >
              <div className="global-container flex h-mobile-nav w-full flex-none items-center justify-between bg-black lg:hidden">
                <p className="meta">Filters</p>
                <Popover.Button className="outline-none" onClick={toggleNav}>
                  <CloseCta as="div" kind="white" />
                </Popover.Button>
              </div>
              <div className="global-container hidden min-h-[132px] w-full items-end justify-end lg:flex">
                <Popover.Button id="close-filters-cta" onClick={toggleNav}>
                  <TextCta
                    as="div"
                    kind="white-tertiary"
                    label="Hide Filters"
                    icon={<Filter aria-label="Hide filters" />}
                  />
                </Popover.Button>
              </div>
              <div className="global-grid global-container h-full w-full overflow-y-scroll py-16">
                <FacetMenu
                  facetName={ALGOLIA_FACETS.insights.topic}
                  selected={menuState.topics}
                  onChange={handleChange("topics")}
                  label="By Topics"
                  classes={{ root: "col-span-3" }}
                />
                <FacetMenu
                  facetName={ALGOLIA_FACETS.insights.region}
                  selected={menuState.regions}
                  onChange={handleChange("regions")}
                  label="By Region"
                  classes={{ root: "col-span-3" }}
                />
                <FacetMenu
                  facetName={ALGOLIA_FACETS.insights.type}
                  selected={menuState.types}
                  onChange={handleChange("types")}
                  label="By Type"
                  classes={{ root: "col-span-3" }}
                />
                <FacetMenu
                  facetName={ALGOLIA_FACETS.insights.year}
                  selected={menuState.years}
                  onChange={handleChange("years")}
                  label="By Year"
                  customOptions={getYears()}
                  classes={{
                    root: "col-span-2",
                    ul: "border-white border-[1px] border-solid  w-2/3 max-h-48 min-w-full overflow-y-scroll",
                    li: "flex-row-reverse data-[checked=true]:bg-white data-[checked=true]:text-black pl-2 pr-4 min-w-full",
                    inputLabel: "flex-grow checked:bg-white meta-large",
                    input: "invisible checked:visible invert",
                  }}
                />
              </div>
              <div className="w-full bg-onyx">
                <div className="global-container flex h-24 w-full items-center justify-between">
                  <TextCta
                    id="filter-clear"
                    label={
                      numFiltersChecked
                        ? `Clear (${numFiltersChecked})`
                        : "Clear"
                    }
                    disabled={!numFiltersChecked}
                    kind="white"
                    onClick={clearFilters}
                  />
                  <Popover.Button
                    id="filter-show-cta"
                    as={RoundedCta}
                    label={
                      numResults === 0
                        ? "No Results"
                        : numFiltersChecked == 0
                        ? "Show"
                        : `Show ${numResults} Results`
                    }
                    disabled={numResults === 0}
                    kind="white"
                    icon={<Arrow />}
                    onClick={() => {
                      handleSubmit();
                      toggleNav();
                    }}
                  />
                </div>
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
