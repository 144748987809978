import clsx from "clsx";
import { useEffect, useRef } from "react";

type FlourishEmbedProps = {
  html: string;
};

export default function FlourishScrolly({ html }: FlourishEmbedProps) {
  const ref = useRef<HTMLDivElement>(null);
  const dataHeight = html.match(/data-height=["']([\d.]+[a-z%]*)["']/);
  useEffect(() => {
    if (ref.current) {
      const dataSrcMatch = html.match(/data-src=["'](.*?)["']/);
      const dataSrc = dataSrcMatch ? dataSrcMatch[1] : null;

      if (dataSrc) {
        ref.current.innerHTML = `
          <iframe
            title="Flourish Scrollytelling"
            src="https://public.flourish.studio/${dataSrc}/?full"
            width="100%"
            height=${dataHeight ? dataHeight[1] : "100%"}
            frameborder="0"
            allowfullscreen
            class="max-h-[calc(100vh-116px)]"
          ></iframe>
        `;
      }
    }
  }, [html, dataHeight]);

  return (
    <div
      ref={ref}
      className={clsx("col-span-full w-full", !dataHeight && "h-screen")}
    ></div>
  );
}
