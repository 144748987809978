import { SVGProps } from "react";
const SvgDownloadBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="m15.455 10.72-3.4 3.4s-.08.03-.11 0l-3.4-3.4M12.005 4.75v8.63M16.825 19.25h-9.65M7.175 19.22v-4.05M16.814 19.22v-4.05"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgDownloadBlack;
