import { InsightGetters } from "~/@types";
import NavOrExternalLink from "../shared/NavOrExternalLink";

type TopicProps = {
  topic: NonNullable<ReturnType<InsightGetters["getTopic"]>>;
  noDot?: boolean;
  className?: string;
};

export default function TopicComponent({
  topic,
  noDot,
  className,
}: TopicProps) {
  return (
    <NavOrExternalLink
      data-content-type="topic"
      data-module-title={topic.slug}
      to={`/insights/${topic.slug}`}
      className={`cta py-4 uppercase ${className}`}
      prefetch="intent"
    >
      {!noDot && (
        <span
          className="mr-2 inline-block h-[10px] w-[10px] rounded-full lg:h-[12px] lg:w-[12px]"
          style={{ backgroundColor: topic.color }}
        />
      )}
      {topic.title}
    </NavOrExternalLink>
  );
}
