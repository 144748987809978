import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import {
  IDownloadListFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";

import { IconCta } from "../ctas";
import { Download } from "../icons";

type DownloadsProps = {
  fields: SpecificLocaleFields<IDownloadListFields>;
};

// set up slate bg

export default function DownloadList({ fields }: DownloadsProps) {
  if (!fields.backgroundColor) fields.backgroundColor = "white";

  return (
    <section
      data-content-type="downloadList"
      data-module-title={fields.moduleTitle}
      className={clsx("w-full", {
        "text-white": ["black"].includes(fields.backgroundColor),
        "text-black": ["white", "slate"].includes(fields.backgroundColor),
        "bg-black": fields.backgroundColor === "black",
        "bg-white": fields.backgroundColor === "white",
        "bg-slate": fields.backgroundColor === "slate",
      })}
    >
      <div className="tbi-grid py-16">
        <div className="col-span-2">
          {fields.eyebrow && <p className="eyebrows">{fields.eyebrow}</p>}
        </div>
        <div className="col-span-4 lg:col-span-8">
          {fields.items &&
            fields.items.map((item, index) => (
              <div
                className="relative grid grid-cols-8 gap-x-5 lg:mb-10 lg:gap-x-6 last:lg:mb-0"
                key={index}
              >
                <div className="col-span-full mt-10 lg:col-span-7 lg:mt-0">
                  <h2
                    className={clsx("h4", {
                      "pb-6 lg:pb-10": !item.fields.body,
                      "pb-4 lg:pb-2": item.fields.body,
                    })}
                  >
                    {item.fields.heading}
                  </h2>
                  {item.fields.body && (
                    <div className="[&_p]:meta pb-6 lg:pb-10 [&>:first-child]:mt-0">
                      {documentToReactComponents(
                        item.fields.body,
                        getComponentFromContentfulRichTextEntry
                      )}
                    </div>
                  )}
                </div>
                {item.fields.file?.fields?.file?.url && (
                  <div className="col-span-1 flex items-center justify-start lg:justify-end">
                    <a
                      download
                      target="_blank"
                      href={item.fields.file?.fields.file.url}
                      rel="noreferrer"
                      className="pb-10"
                    >
                      <IconCta
                        kind={
                          fields.backgroundColor === "black" ? "white" : "black"
                        }
                        icon={<Download />}
                        label="Download"
                      />
                    </a>
                  </div>
                )}
                <hr className="col-span-full border border-solid border-[currentColor]" />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
