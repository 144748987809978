import { TextCta } from "../ctas";
import { Arrow } from "../icons";
import { useHits, useSearchBox } from "react-instantsearch-hooks-web";
import { Hit as AlgoliaHit, UiState } from "instantsearch.js/es/types";
import AlgoliaSearchInput from "./AlgoliaSearchInput";
import AlgoliaProvider from "./AlgoliaProvider";
import qs from "qs";
import { ALGOLIA_INDICES } from "~/config";
import clsx from "clsx";
import { useEffect, useState } from "react";
import NavOrExternalLink from "../shared/NavOrExternalLink";

type HitType = AlgoliaHit<{ query: string }>;

type FreeSearchProps = {
  onClose: () => void;
  onSentenceSearchClick: () => void;
};

function FreeSearch({ onSentenceSearchClick, onClose }: FreeSearchProps) {
  const { query } = useSearchBox();
  const { hits, sendEvent } = useHits<HitType>();
  const [selectedSuggestion, setSelectedSuggestion] = useState<
    number | undefined
  >();

  const searchQueryParam = qs.stringify(
    { [ALGOLIA_INDICES.insights]: { query } } as UiState,
    { encode: false }
  );

  useEffect(() => {
    setSelectedSuggestion(0);
  }, [query]);

  const handleClick = (hit: HitType) => {
    sendEvent("click", hit, "Suggestion Click");
    onClose();
  };

  const handleArrowPress = (key: "up" | "down") => {
    setSelectedSuggestion((prev) => {
      const lastHitIdx = hits.length - 1;
      switch (key) {
        case "up":
          if (prev === undefined || prev === 0) {
            return lastHitIdx;
          } else {
            return prev - 1;
          }
        case "down":
          if (prev === undefined || prev === lastHitIdx) {
            return 0;
          } else {
            return prev + 1;
          }
      }
    });
  };

  return (
    <>
      <div className="w-full bg-black pt-2">
        <div className="global-container">
          <AlgoliaSearchInput
            autofocus
            suggestion={
              typeof selectedSuggestion === "number"
                ? hits?.[selectedSuggestion]?.query
                : undefined
            }
            formAction="/search"
            inputName={searchQueryParam}
            onArrowPress={handleArrowPress}
          />
        </div>
      </div>
      <div className="global-container w-full overflow-y-scroll">
        <div className="py-6">
          {hits.map((hit, i) => (
            <Hit
              key={hit.objectID}
              hit={hit}
              onClick={() => handleClick(hit)}
              selected={i === selectedSuggestion}
            />
          ))}
        </div>
      </div>
      {hits.length === 0 && (
        <div className="fixed bottom-36 mx-auto w-screen">
          <TextCta
            id="sentence-search-button"
            kind="white"
            label="Try Sentence Search"
            className="mx-auto"
            icon={<Arrow />}
            type="button"
            onClick={onSentenceSearchClick}
          />
        </div>
      )}
    </>
  );
}

type HitProps = {
  hit: HitType;
  onClick: () => void;
  selected?: boolean;
};

function Hit({ hit, onClick, selected }: HitProps) {
  const newState: UiState = {
    [ALGOLIA_INDICES.insights]: {
      query: hit.query,
    },
  };

  return (
    <NavOrExternalLink
      id="search-suggestion"
      to={{
        pathname: "/search",
        search: qs.stringify(newState),
      }}
      className={clsx("body-large block px-14 text-white", {
        "rounded-xl bg-onyx bg-opacity-80": selected,
      })}
      aria-selected={selected}
      onClick={onClick}
    >
      {hit.query}
    </NavOrExternalLink>
  );
}

export default function FreeSearchWrapper(props: FreeSearchProps) {
  return (
    <AlgoliaProvider
      indexName={ALGOLIA_INDICES.insightsSuggestions}
      disableSearchOnEmpty
    >
      <FreeSearch {...props} />
    </AlgoliaProvider>
  );
}
