import useEmblaCarousel from "embla-carousel-react";
import { useState, useEffect, useCallback } from "react";
import { Arrow } from "../icons";
import CircleIconCta from "../ctas/CircleIconCta";
import {
  IImageCarouselFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { ContentfulCenteredImage } from "../shared";
import ImageSlowScroll from "../shared/ImageSlowScroll";
import clsx from "clsx";

type ImageGalleryCarouselProps = {
  fields: SpecificLocaleFields<IImageCarouselFields>;
};

export default function ImageGalleryCarousel({
  fields,
}: ImageGalleryCarouselProps) {
  const [emblaRef, embla] = useEmblaCarousel({
    loop: true,
    skipSnaps: false,
    startIndex: 0,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollTo = useCallback(
    (index: number) => embla && embla.scrollTo(index),
    [embla]
  );

  useEffect(() => {
    if (!embla) return;
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", () => setSelectedIndex(embla.selectedScrollSnap()));
  }, [embla, setScrollSnaps]);
  return (
    <div
      data-content-type="imageCarousel"
      data-module-title={fields.moduleTitle}
      className="embla relative left-[50%] right-[50%] col-span-full mx-[-50vw] w-screen overflow-hidden py-20"
    >
      <div className="embla__viewport" ref={emblaRef}>
        <div
          className={clsx(
            "embla__container mx-auto grid max-w-content-max auto-cols-[90%] grid-flow-col",
            fields.size
              ? "md:auto-cols-[72.5%] xl:auto-cols-[75.5%]"
              : "md:auto-cols-[86.5%] xl:auto-cols-[90.5%]"
          )}
        >
          {fields.images &&
            fields.images.map((image, index) => (
              <div
                key={index}
                className={clsx(
                  "embla__slide mr-2 aspect-video h-full object-cover lg:mr-6 lg:aspect-video"
                )}
              >
                <ImageSlowScroll>
                  <ContentfulCenteredImage
                    image={image.fields}
                    className="h-full w-full"
                  />
                </ImageSlowScroll>
              </div>
            ))}
        </div>
      </div>
      <div className="global-container global-grid">
        <div
          className={clsx(
            "col-span-full flex justify-center pt-5",
            fields.size ? "lg:col-span-10 lg:col-start-2" : "col-span-full"
          )}
        >
          {scrollSnaps.map((_, ix, scrollSnaps) => (
            <button
              className={clsx(
                "relative flex h-10 w-full cursor-pointer items-center bg-transparent p-0 after:h-[1px] after:w-full",
                {
                  "after:rounded-l-sm": ix === 0,
                },
                ix <= selectedIndex ? "after:bg-black" : "after:bg-dark_grey",
                ix <= scrollSnaps.length - 1 ? "after:rounded-r-sm" : ""
              )}
              key={ix}
              onClick={() => scrollTo(ix)}
              aria-label={"Go to slide #" + ix}
            />
          ))}
        </div>
      </div>
      <div className="global-container mt-1 flex gap-4">
        <div className="grow" />
        <CircleIconCta
          className={"rotate-180"}
          label={"previous carousel item"}
          kind={"hollow"}
          icon={<Arrow />}
          onClick={scrollPrev}
        />
        <CircleIconCta
          label={"next carousel item"}
          kind={"hollow"}
          icon={<Arrow />}
          onClick={scrollNext}
        />
      </div>
    </div>
  );
}
