import { createContext, useContext, useState } from "react";
import type { ReactNode } from "react";
import { CookiesProvider, useCookies } from "react-cookie";

const COOKIE_OPTIONS = { maxAge: 15780000 }; // 6 months in seconds

interface CookieContextType {
  cookies: {
    [k: string]: any;
  };
  setCookie: (cookie: string, value: any) => void;
}

const CookieContext = createContext<CookieContextType | undefined>(undefined);

function CookieProvider({ children }: { children: ReactNode }) {
  const [_cookies, _setCookies] = useCookies();
  const [cookies, _setCookieState] = useState(_cookies);

  const setCookie = (cookie: string, value: any) => {
    _setCookies(cookie, JSON.stringify(value), COOKIE_OPTIONS);
    _setCookieState((currState) => {
      currState[cookie] = value;
      return currState;
    });
  };

  return (
    <CookieContext.Provider
      value={{
        cookies,
        setCookie,
      }}
    >
      <CookiesProvider>{children}</CookiesProvider>
    </CookieContext.Provider>
  );
}

function useCookieContext() {
  const context = useContext(CookieContext);
  if (context === undefined) {
    throw new Error("useCookieContext must be used within a CookieProvider");
  }
  return context;
}

export { CookieProvider, useCookieContext };
