import { motion, useScroll, useTransform } from "framer-motion";
import { ReactNode, useRef } from "react";
import { useRefScrollProgress } from "../utils/useRefScrollProgress";

type ImageSlowScrollProps = {
  children?: ReactNode;
  maxPcnt?: number;
  style?: {
    [k: string]: any;
  };
  [k: string]: any;
};

export default function ImageSlowScroll({
  children,
  maxPcnt = 30,
  style,
  ...otherProps
}: ImageSlowScrollProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { start, end } = useRefScrollProgress(ref);
  const { scrollYProgress } = useScroll();

  const y = useTransform(
    scrollYProgress,
    [start, end],
    [`-${maxPcnt}%`, `${maxPcnt}%`]
  );

  return (
    <motion.div
      ref={ref}
      className="h-full w-full overflow-hidden"
      {...otherProps}
    >
      <motion.div
        style={{
          y,
          scale: 1.2,
          ...style,
        }}
        className="h-full w-full"
      >
        {children && children}
      </motion.div>
    </motion.div>
  );
}
