import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document as ContentfulDocument } from "@contentful/rich-text-types";
import { HashLink } from "react-router-hash-link";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import {
  InsightComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import { Carrot } from "../icons";

export default function FootnotesList() {
  const { footnotes } = useComponentData() as InsightComponentData;

  if (!footnotes || !Object.keys(footnotes).length) return null;

  return (
    <section className="tbi-grid py-20">
      <h2 className="article-columns pb-10">Footnotes</h2>
      <ol className="article-columns text-secondary_text">
        {footnotes.map(({ footnote, id }, ix) => (
          <li
            key={id + "_" + ix}
            className="body-small [&_p]:body-small my-4 [&_p]:inline [&_svg]:inline"
            data-content-type="footnote"
            data-module-title={footnote.moduleTitle}
          >
            <span>
              {ix + 1}.
              <HashLink
                id={`footnote_list_item_${ix + 1}`}
                to={`#footnote_${ix + 1}`}
                className="scroll-mt-20"
              >
                <Carrot
                  className="relative bottom-1 rotate-180"
                  aria-label="Back to content"
                />
              </HashLink>
            </span>
            {footnote.body && (
              <>
                {documentToReactComponents(
                  footnote.body as ContentfulDocument,
                  getComponentFromContentfulRichTextEntry
                )}
              </>
            )}
          </li>
        ))}
      </ol>
    </section>
  );
}
