import { useState, useEffect, useRef, RefObject } from "react";

const useIsVisible = (
  elementRef: RefObject<Element>,
  options: IntersectionObserverInit = { threshold: 0.1 }
): boolean => {
  const [isVisible, setIsVisible] = useState(false);
  const hasIntersected = useRef(false);

  useEffect(() => {
    const currentElement = elementRef.current;
    if (!currentElement) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !hasIntersected.current) {
        setIsVisible(true);
        hasIntersected.current = true;
        observer.disconnect(); // disconnect the observer after the first intersection
      }
    }, options);

    observer.observe(currentElement);

    return () => {
      observer.disconnect();
    };
  }, [elementRef, options]);

  return isVisible;
};

export default useIsVisible;
