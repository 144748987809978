import clsx from "clsx";
import {
  ITwoColumnMediaFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { ContentfulCenteredImage, ForcedAspectRatio } from "../shared";
import ImageSlowScroll from "../shared/ImageSlowScroll";
import { useEffectOnce } from "react-use";
import { bgColorOverrides } from "../Insight/CardsGrid";

type TwoColumnProps = {
  fields: SpecificLocaleFields<ITwoColumnMediaFields>;
};

export default function TwoColumn({ fields }: TwoColumnProps) {
  useEffectOnce(() => {
    const allSections = document.querySelectorAll("section");
    if (!allSections) return;
    allSections.forEach((el: HTMLElement) => {
      const sibling = el.previousSibling as HTMLElement;
      if (
        el.dataset.contentType === "twoColumnMedia" &&
        sibling &&
        sibling?.dataset.contentType === "gridCards"
      ) {
        const divToColor = el.querySelector(".top-part");
        divToColor?.classList.add(
          sibling.dataset.color && bgColorOverrides[sibling.dataset.color]
        );
      }
    });
  });
  return (
    <section
      data-content-type="twoColumnMedia"
      data-module-title={fields.moduleTitle}
      className={clsx("relative w-screen", {
        "bg-black": fields.bottomColor === "black",
        "bg-white": fields.bottomColor === "white",
        "bg-slate": fields.bottomColor === "slate",
      })}
    >
      <div
        className={clsx("top-part absolute top-0 h-1/2 w-screen", {
          "bg-black": fields.topColor === "black",
          "bg-white": fields.topColor === "white",
          "bg-slate": fields.topColor === "slate",
        })}
      />
      <div
        className={"global-container global-grid relative z-10 py-12 lg:py-16"}
      >
        <ForcedAspectRatio className="col-span-3 lg:col-span-6">
          <ImageSlowScroll>
            {fields.largeImage && (
              <ContentfulCenteredImage
                image={fields.largeImage.fields}
                className="h-full w-full"
              />
            )}
          </ImageSlowScroll>
        </ForcedAspectRatio>
        <ForcedAspectRatio className="col-span-1 col-start-4 lg:col-span-2 lg:col-start-8">
          <ImageSlowScroll>
            {fields.smallImage && (
              <ContentfulCenteredImage
                image={fields.smallImage.fields}
                className="h-full w-full"
              />
            )}
          </ImageSlowScroll>
        </ForcedAspectRatio>
      </div>
    </section>
  );
}
