import { ReactNode, useEffect, useRef } from "react";

export type FadeInProps = {
  className?: string;
  children?: ReactNode;
  durationInMilliseconds?: number;
  delayInMilliseconds?: number;
};

export default function FadeIn({
  className = "",
  children,
  durationInMilliseconds = 1000,
  delayInMilliseconds = 1000,
}: FadeInProps) {
  let _innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (_innerRef.current) {
      _innerRef.current.style.opacity = "1";
    }
  }, []);

  return (
    <div
      ref={_innerRef}
      className={`ease ${className}`}
      style={{
        transitionProperty: "opacity",
        transitionDuration: `${durationInMilliseconds}ms`,
        transitionDelay: `${delayInMilliseconds}ms`,
        opacity: "0",
      }}
    >
      {children}
    </div>
  );
}
