import { ReactNode } from "react";

type TableRowProps = {
  children: ReactNode;
};

export default function TableRow({ children }: TableRowProps) {
  return (
    <tr
      role="row"
      className="border-b border-solid border-black first:border-b-0 last:border-b-0"
    >
      {children}
    </tr>
  );
}
