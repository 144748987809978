export default function MobileHomeIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Tony Blair Institute for Global Change"
    >
      <rect
        x="0.5"
        y="0.5"
        width="39"
        height="39"
        rx="19.5"
        stroke="currentColor"
      />
      <path
        d="M9.336 15.2H16.994V16.628H13.97V25H12.36V16.628H9.336V15.2ZM21.5265 15.2C22.5998 15.2 23.4305 15.424 24.0185 15.872C24.6158 16.32 24.9145 16.9453 24.9145 17.748C24.9145 18.336 24.7558 18.8213 24.4385 19.204C24.1305 19.5773 23.6825 19.8293 23.0945 19.96C24.5598 20.212 25.2925 20.982 25.2925 22.27C25.2925 23.1193 24.9752 23.7867 24.3405 24.272C23.7152 24.7573 22.8192 25 21.6525 25H17.9285V15.2H21.5265ZM19.5385 19.358H21.5685C22.1192 19.358 22.5438 19.232 22.8425 18.98C23.1505 18.728 23.3045 18.3827 23.3045 17.944C23.3045 17.5053 23.1505 17.1647 22.8425 16.922C22.5438 16.67 22.1192 16.544 21.5685 16.544H19.5385V19.358ZM19.5385 23.642H21.6665C22.3012 23.642 22.7865 23.516 23.1225 23.264C23.4678 23.0027 23.6405 22.6433 23.6405 22.186C23.6405 21.71 23.4678 21.3413 23.1225 21.08C22.7772 20.8187 22.2918 20.688 21.6665 20.688H19.5385V23.642ZM26.5079 15.2H28.1179V25H26.5079V15.2Z"
        fill="currentColor"
      />
    </svg>
  );
}
