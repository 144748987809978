import { SVGProps } from "react";
const SvgVerticalLine = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width="2"
    height="20"
    viewBox="0 0 2 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 0.5V19.5" stroke="currentColor" />
  </svg>
);
export default SvgVerticalLine;
