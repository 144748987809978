import { ClientSide } from "~/@types";
import { IInsight } from "~/@types/generated/contentful";
import {
  InsightComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import { CircleIconCta, IconCta } from "../ctas";
import { Download } from "../icons";
import FloatingNav from "../shared/FloatingNav";
import ChapterTakeover from "./ChapterTakeover";
import ShareButton from "./ShareButton";

type InsightNavProps = {
  insight: ClientSide<IInsight>;
};

export default function InsightNav({ insight }: InsightNavProps) {
  const { chapters } = useComponentData() as InsightComponentData;
  const hasChapters = chapters && Object.keys(chapters)?.length > 1;
  return (
    <FloatingNav>
      {hasChapters ? <ChapterTakeover /> : null}
      <ShareButton insight={insight} />
      {insight.fields.pdf?.fields?.file?.url ? (
        <>
          <CircleIconCta
            kind="white"
            icon={<Download />}
            label="Download"
            className="border border-solid border-dark_grey lgmax:hidden"
            to={`https:${insight.fields.pdf.fields.file.url}`}
          />
          <IconCta
            kind="black"
            icon={<Download />}
            label="Download"
            className="lg:hidden"
            to={`https:${insight.fields.pdf.fields.file.url}`}
          />
        </>
      ) : null}
    </FloatingNav>
  );
}
