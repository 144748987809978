import { useLocation } from "@remix-run/react";
import { RoundedCta } from "../ctas";
import {
  Arrow,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  YouTube,
} from "../icons";
import NavOrExternalLink from "./NavOrExternalLink";
import NewsletterButton from "./newsletter/NewsletterButton";

const social = [
  {
    name: "X",
    href: "https://twitter.com/InstituteGC",
    icon: Twitter,
  },
  {
    name: "instagram",
    href: "https://www.instagram.com/institutegc/",
    icon: Instagram,
  },
  {
    name: "linkedin",
    href: "https://www.linkedin.com/company/tony-blair-institute-for-global-change/",
    icon: Linkedin,
  },
  {
    name: "youtube",
    href: "https://www.youtube.com/TonyBlairInstituteforGlobalChange/",
    icon: YouTube,
  },
  {
    name: "facebook",
    href: "https://www.facebook.com/instituteglobal/",
    icon: Facebook,
  },
];

type LinkItem = {
  title: string;
  entries: LinkEntry[];
};

type LinkEntry = {
  name: string;
  href: string;
  external?: boolean;
};

const linkTable: LinkItem[] = [
  {
    title: "What we do",
    entries: [
      { name: "Approach", href: "/what-we-do/approach" },
      { name: "Partnerships", href: "/what-we-do/partnerships" },
      { name: "Future of Britain", href: "/future-of-britain" },
    ],
  },
  {
    title: "Insights",
    entries: [
      { name: "Insights", href: "/insights" },
      { name: "Experts", href: "/experts" },
    ],
  },
  {
    title: "Who we are",
    entries: [
      { name: "About us", href: "/who-we-are/about" },
      { name: "Leadership", href: "/who-we-are/executive-leadership" },
      { name: "Careers", href: "/who-we-are/careers" },
    ],
  },
];

export default function Footer() {
  const { pathname } = useLocation();

  // Don't show footer on the bio page
  if (pathname.includes("experts") && pathname.endsWith("bio")) return null;

  const renderSocial = () => (
    <>
      {social.map((item) => (
        <a
          data-content-type="footer-social"
          data-module-title={item.name}
          key={item.name}
          href={item.href}
          target="_blank"
          rel="noreferrer"
          className="mr-5 rounded-full bg-white p-1 text-black"
          title={item.name}
        >
          {item.icon({ "aria-label": item.name })}
        </a>
      ))}
    </>
  );

  return (
    <footer id="footer" className="bg-black pt-16 lg:pt-20">
      <div className="mx-auto flex max-w-content-max flex-col px-mobile-gutter text-white lg:px-desktop-gutter">
        <div className="mb-16 lg:mb-20 lg:hidden">
          <p className="flex items-center text-14 uppercase">
            tony blair institute for global change
          </p>
        </div>
        <div className="grid lg:grid-cols-2">
          <div className="mb-16 flex flex-col">
            <p className="ppe pb-4 text-20 font-normal tracking-tight lg:text-24">
              Intelligence in your inbox.
            </p>
            <p className="aeonik max-w-[253px] pb-6 text-12 text-secondary_text lg:max-w-[320px]">
              Get the latest big ideas in strategy, policy and delivery in your
              inbox every month with our newsletter.
            </p>
            <div className="lg:mb-10">
              <NewsletterButton>
                <RoundedCta
                  id="footer-newsletter-sign-up"
                  kind="white-hollow"
                  icon={<Arrow />}
                  label="Sign Me Up"
                />
              </NewsletterButton>
            </div>
            <div className="flex lgmax:hidden">{renderSocial()}</div>
          </div>
          <div className="grid grid-cols-2 gap-12 lg:grid-cols-3 ">
            {linkTable.map((lt) => (
              <div key={lt.title}>
                <p className="ppe text-24 font-normal tracking-tight">
                  {lt.title}
                </p>
                <ul className="mt-6">
                  {lt.entries.map((item) => (
                    <li key={item.name} className="mb-6">
                      <NavOrExternalLink
                        id={`footer-link-${item.name}`}
                        to={item.href}
                        className="py-4 text-14 uppercase"
                        prefetch="intent"
                      >
                        {item.name}
                      </NavOrExternalLink>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center bg-onyx">
        <div className="flex w-full max-w-content-max flex-col-reverse px-mobile-gutter pt-10 pb-10 text-dark_grey lg:grid lg:grid-cols-2 lg:px-desktop-gutter">
          <div className="mr-8 max-w-[530px]">
            <p className="text-10">
              Tony Blair Institute, trading as Tony Blair Institute for Global
              Change, is a company limited by guarantee registered in England
              and Wales (registered company number: 10505963) whose registered
              office is One Bartholomew Close, London, EC1A 7BL.
            </p>
          </div>
          <div className="flex lg:mb-10 lg:hidden lgmax:py-10">
            {renderSocial()}
          </div>
          <div className="grid grid-cols-2 gap-y-6 text-12 uppercase lg:flex lg:flex-wrap lg:gap-9">
            <p>
              <NavOrExternalLink to="cookies" className="py-4">
                Cookies
              </NavOrExternalLink>
            </p>
            <p>
              <NavOrExternalLink to="terms-of-use" className="py-4">
                Terms of use
              </NavOrExternalLink>
            </p>
            <p>
              <NavOrExternalLink to="privacy-policy" className="py-4">
                Privacy Policy
              </NavOrExternalLink>
            </p>
            <p>
              <NavOrExternalLink to="accessibility" className="py-4">
                Accessibility
              </NavOrExternalLink>
            </p>
            <p>
              <NavOrExternalLink to="financial-statements" className="py-4">
                Financial Statements
              </NavOrExternalLink>
            </p>
            <p>
              <NavOrExternalLink to="media-centre" className="py-4">
                Media Centre
              </NavOrExternalLink>
            </p>
            <p>
              <NavOrExternalLink to="contact-us" className="py-4">
                Contact Us
              </NavOrExternalLink>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
