import { ClientSide } from "~/@types";
import { IImageFields } from "~/@types/generated/contentful";
import { getFrontifyImageURL } from "./getFrontifyImage";

export const getImageURL = (imageField: ClientSide<IImageFields>) => {
  if (imageField.frontifyImage) {
    return getFrontifyImageURL(imageField);
  }

  return imageField.image?.fields.file.url;
};
