import clsx from "clsx";
import { useRef } from "react";
import { useEffectOnce } from "react-use";
import {
  IHtmlStringFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import {
  PageComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import Flourish from "../Insight/Flourish";
import InsightVideoPlayer from "../Insight/InsightVideoPlayer";
import SoundCloudEmbedded from "../Insight/SoundCloudEmbedded";
import FlourishScrolly from "../Insight/FluorishScrolly";

type HtmlStringProps = {
  fields: SpecificLocaleFields<IHtmlStringFields>;
  beforeSecondChapter?: boolean;
};

export default function HtmlString({
  fields,
  beforeSecondChapter = false,
}: HtmlStringProps) {
  const ref = useRef<HTMLDivElement>(null);

  const { page } = useComponentData() as PageComponentData;

  useEffectOnce(() => {
    if (ref.current) {
      ref.current.innerHTML = fields.html;
    }
  });

  const renderHtml = () => {
    switch (fields.type) {
      case "flourish":
        return (
          <Flourish
            className="col-span-full pt-10"
            html={fields.html}
            beforeSecondChapter={beforeSecondChapter}
          />
        );
      case "video":
        return <InsightVideoPlayer url={fields.html} />;
      case "audio":
        return <SoundCloudEmbedded fields={fields} />;
      case "scrolly":
        return <FlourishScrolly html={fields.html} />;

      case "highchart":
      case "svg":
      case "style":
      case "script":
      case "embed":
      default:
        return (
          <div
            className={clsx("flex-center col-span-full", page && "flex-col")}
            ref={ref}
          />
        );
    }
  };
  return (
    <div
      data-content-type="htmlString"
      data-module-title={fields.moduleTitle}
      className={clsx(
        "article-columns-max flex-center global-grid relative",
        page && "global-container mt-10"
      )}
      style={{
        color: fields.colorway?.fields.fontColor,
        zIndex: 1,
      }}
    >
      {renderHtml()}
      {beforeSecondChapter && (
        <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
      )}
      {fields.colorway?.fields.backgroundColor && (
        <span
          className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0"
          style={{
            backgroundColor: fields.colorway?.fields.backgroundColor,
            zIndex: -1,
          }}
        />
      )}
    </div>
  );
}
