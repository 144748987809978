export function adjustValueBasedOnScreenSize(
  initialValue: number,
  isMobile: boolean,
  isTablet: boolean
) {
  if (isMobile) {
    return initialValue * 0.5;
  } else if (isTablet) {
    return initialValue * 0.75;
  } else {
    return initialValue;
  }
}
