import { ClientSide } from "~/@types";
import { IImageFields } from "~/@types/generated/contentful";
import { getBackgroundImageStyles } from "../utils/getBackgroundImageStyles";

export interface ContentfulCenteredImageProps {
  image: ClientSide<IImageFields>;
  className?: string;
  bg?: "fade" | "gradient" | "grey" | "transparent";
  style?: any;
  [k: string]: any;
}

export default function ContentfulCenteredImage({
  className = "",
  image,
  bg,
  style = {},
  ...otherProps
}: ContentfulCenteredImageProps) {
  return (
    <div
      data-content-type="image"
      data-module-title={image?.moduleTitle}
      role="img"
      aria-label={image?.alt + "" ?? "background image"}
      className={`${className}`}
      {...otherProps}
      style={{
        ...getBackgroundImageStyles(image, bg),
        ...style,
      }}
    >
      {!image && "No image found"}
    </div>
  );
}
