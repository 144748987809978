import clsx from "clsx";
import {
  IPageRoutingFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { Arrow } from "~/components/icons";
import NavOrExternalLink from "../shared/NavOrExternalLink";

type PageRoutingProps = {
  fields: SpecificLocaleFields<IPageRoutingFields>;
};

export default function PageRouting({ fields }: PageRoutingProps) {
  return (
    <div
      data-content-type="pageRouting"
      data-module-title={fields.moduleTitle}
      className={clsx("w-screen", {
        "text-white": ["black"].includes(fields.backgroundColor),
        "text-black": ["white", "slate"].includes(fields.backgroundColor),
        "bg-onyx": fields.backgroundColor === "black",
        "bg-white": fields.backgroundColor === "white",
        "bg-slate": fields.backgroundColor === "slate",
      })}
      style={{
        backgroundColor: fields.colorPalette?.fields.backgroundColor,
        color: fields.colorPalette?.fields.fontColor,
      }}
    >
      <div
        className={clsx("global-container global-grid w-screen py-20 lg:py-32")}
      >
        <p className="eyebrows col-span-2 mb-16">{fields.eyebrow}</p>
        <nav className="col-span-full lg:col-span-8">
          {fields.routes &&
            fields.routes.map((route) => (
              <NavOrExternalLink
                to={route.fields?.url ?? ""}
                key={route.sys.id}
                prefetch="intent"
                className="mb-10 block last-of-type:mb-0"
              >
                <div className="flex items-center justify-between">
                  {route.fields.heading && (
                    <h3 className="">{route.fields.heading}</h3>
                  )}
                  <div>
                    <Arrow aria-label="arrow" className="block" />
                  </div>
                </div>
                <div
                  className={clsx("mt-6 h-[1px] w-full lg:mt-10", {
                    "text-white": ["black"].includes(fields.backgroundColor),
                    "text-black": ["white", "slate"].includes(
                      fields.backgroundColor
                    ),
                    "bg-onyx": ["white", "slate"].includes(
                      fields.backgroundColor
                    ),
                    "bg-white": fields.backgroundColor === "black",
                  })}
                  style={{
                    backgroundColor: fields.colorPalette?.fields.fontColor,
                  }}
                />
              </NavOrExternalLink>
            ))}
        </nav>
      </div>
    </div>
  );
}
