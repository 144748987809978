import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import { useRef } from "react";
import {
  ISingleImageParallaxFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { ContentfulCenteredImage } from "~/components/shared";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import { useSingleImageParallax } from "./useSingleImageParallax";

type MonologueAndMediaProps = {
  fields: SpecificLocaleFields<ISingleImageParallaxFields>;
};

export default function MonologueAndMedia({ fields }: MonologueAndMediaProps) {
  const intersectionRef = useRef<HTMLDivElement>(null);
  const stickyRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  useSingleImageParallax({
    intersectionRef,
    stickyRef,
    scrollRef,
  });

  return (
    <section
      data-content-type="singleImageParallax"
      data-module-title={fields.moduleTitle}
      ref={intersectionRef}
      className="global-container relative mx-auto mb-20 flex h-[200vh]  max-w-[1200px] flex-col items-center"
    >
      <div
        ref={stickyRef}
        className="-z-10 flex h-screen items-center justify-center overflow-hidden"
      >
        <div className="h-min">
          {fields.body && (
            <div
              className={clsx([
                "global-container",
                "[&>*]:ppe",
                "[&>*]:text-center",
                // Line height
                "[&>*]:leading-[90px]",
                "[&>*]:md:leading-[144px]",
                "[&>*]:lg:leading-[150px]",
                // Text size
                "[&>*]:text-52",
                "[&>*]:md:text-100",
              ])}
            >
              {documentToReactComponents(
                fields.body,
                getComponentFromContentfulRichTextEntry
              )}
            </div>
          )}
        </div>
      </div>
      <div className="h-screen w-screen" />
      <div
        className="flex h-screen w-full items-center justify-center"
        ref={scrollRef}
      >
        <div className="h-[240px] w-[180px] md:h-[400px] md:w-[300px]">
          <ContentfulCenteredImage
            image={fields.image.fields}
            className="h-full w-full pb-6"
          />
        </div>
      </div>
    </section>
  );
}
