import { SVGProps } from "react";
const SvgPlusBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M12 6.2v11.6M17.8 12H6.2"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgPlusBlack;
