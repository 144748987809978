import clsx from "clsx";
import { useEffect, useRef } from "react";
import { useFacetValues } from "./useFacetValues";

type FacetMenuProps = {
  facetName: string;
  label: string;
  selected: string[];
  onChange: (item: string, value: boolean) => void;
  /**
   * `customOptions` Forgoes the algolia lookup and uses the provided values.
   */
  customOptions?: string[];
  classes?: {
    root?: string;
    ul?: string;
    li?: string;
    input?: string;
    inputLabel?: string;
    highlight?: string;
  };
};

export function FacetMenu({
  facetName,
  classes,
  label,
  onChange,
  selected,
  customOptions,
}: FacetMenuProps) {
  const facetHits = useFacetValues(facetName, { disabled: !!customOptions });
  const facetValues = customOptions ?? facetHits?.map((hit) => hit.value);

  const ulRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    // Scroll the selected element into view if there is only one.
    if (selected.length === 1 && ulRef.current) {
      const index = facetValues.findIndex((v) => v === selected[0]);
      const scrollHeight = ulRef.current.scrollHeight;
      const height = ulRef.current.offsetHeight;
      ulRef.current.scroll({
        top: (scrollHeight / facetValues.length) * index - height / 3,
        behavior: "smooth",
      });
    }
  }, [facetValues, selected]);

  return (
    <section
      data-content-type="filter-checkbox-menu"
      data-module-title={label}
      className={clsx("mb-10 text-white lg:mb-0", classes?.root)}
    >
      <p className="eyebrows pb-6">{label}</p>
      <ul className={classes?.ul} ref={ulRef}>
        {facetValues.map((facetVal) => {
          const inputId = `input-${facetName}-${facetVal}`;
          const isChecked = selected.includes(facetVal);
          return (
            <li
              key={facetVal}
              data-checked={isChecked}
              className={clsx(
                "flex min-w-0 cursor-pointer items-center",
                classes?.li
              )}
            >
              <input
                type="checkbox"
                id={inputId}
                className={clsx("checkbox ", classes?.input)}
                checked={isChecked}
                onChange={() => onChange(facetVal, !isChecked)}
                name={label}
                value={facetVal}
              />
              <label
                htmlFor={inputId}
                className={clsx(
                  "eyebrows min-w-0 flex-grow cursor-pointer py-2 pl-3",
                  classes?.inputLabel
                )}
              >
                {facetVal}
              </label>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
