import { ReactNode, useEffect, useRef } from "react";

export type BarnDoorSwipeProps = {
  className?: string;
  children?: ReactNode;
  durationInMilliseconds?: number;
  delayInMilliseconds?: number;
};

export default function BarnDoorSwipe({
  className = "",
  children,
  durationInMilliseconds = 1000,
  delayInMilliseconds = 1000,
}: BarnDoorSwipeProps) {
  let _innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (_innerRef.current) {
      _innerRef.current.style.width = "100%";
    }
  }, []);

  return (
    <div
      ref={_innerRef}
      className={`ease transition-width mx-auto h-full overflow-hidden ${className}`}
      style={{
        transitionProperty: "width",
        transitionDuration: `${durationInMilliseconds}ms`,
        transitionDelay: `${delayInMilliseconds}ms`,
        width: "0%",
      }}
    >
      {children}
    </div>
  );
}
