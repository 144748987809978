import { SVGProps } from "react";
const SvgSearchBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M11.23 17.73a6.48 6.48 0 1 0 0-12.96 6.48 6.48 0 0 0 0 12.96ZM15.84 15.85l3.41 3.38"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgSearchBlack;
