import {
  InsightComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import { ContentfulCenteredImage } from "../shared";
import ExpertCarouselTakeover from "../experts/ExpertCarouselTakeover";
import { VerticalLine } from "../icons";
import { getPrettyDate } from "../utils/getPrettyDate";
import { motion } from "framer-motion";
import ScaleUpOnScroll from "../shared/ScaleUpOnScroll";
import clsx from "clsx";
import NavOrExternalLink from "../shared/NavOrExternalLink";
import { PAGE_TRANSITION_DELAY } from "~/contexts/PageTransitionProvider";

export default function InsightHero() {
  const { chapters, insight } = useComponentData() as InsightComponentData;

  if (!insight?.fields || !Object.keys(insight.fields).length) return null;

  return (
    <section
      data-content-type="insightHero"
      data-module-title={insight.fields.slug}
      className="mt-mobile-nav h-max lg:mx-[36px] lg:mt-desktop-nav lg:w-[calc(100vw-72px)]"
    >
      <motion.div
        className="flex flex-col items-center pt-32 text-white lg:min-h-[66vh] lg:pt-48"
        style={{
          // fallback of slate
          backgroundColor: insight.fields.topic?.fields.color || "#F5F5F5",
        }}
      >
        <div className="tbi-grid mx-auto mb-10 flex w-screen flex-col items-start justify-center lg:w-full">
          <div className="article-columns-max">
            <NavOrExternalLink
              to={`/insights/${insight.fields.topic?.fields.slug}`}
              prefetch="intent"
            >
              <motion.p
                // initial={{ opacity: 0 }}
                // animate={{
                //   opacity: 1,
                //   transition: {
                //     duration: 0.7,
                //     delay: 0.7 + PAGE_TRANSITION_DELAY / 1000,
                //   },
                // }}
                className="eyebrows lg:p b-10 pb-6"
              >
                {insight.fields.topic?.fields.title}
              </motion.p>
            </NavOrExternalLink>
            {insight.fields.title && (
              <motion.h1
                // initial={{ opacity: 0 }}
                // animate={{
                //   opacity: 1,
                //   transition: {
                //     duration: 0.7,
                //     delay: 0.7 + PAGE_TRANSITION_DELAY / 1000,
                //   },
                // }}
                className="mb-9 flex items-center lg:mb-20"
              >
                {insight.fields.title}
              </motion.h1>
            )}
            <motion.hr
              initial={{
                clipPath: "polygon(0% 0%, 0 0, 0 100%, 0 100%)",
                WebkitClipPath: "polygon(0% 0%, 0 0, 0 100%, 0 100%)",
              }}
              animate={{
                clipPath: "polygon(0% 0%, 100% 0, 100% 100%, 0 100%)",
                WebkitClipPath: "polygon(0% 0%, 100% 0, 100% 100%, 0 100%)",
                transition: {
                  duration: 1,
                  delay: PAGE_TRANSITION_DELAY / 1000,
                },
              }}
              className="mb-6 w-full text-white lg:mb-5"
            />
            <motion.div
              // initial={{ opacity: 0 }}
              // animate={{
              //   opacity: 1,
              //   transition: {
              //     duration: 0.7,
              //     delay: 0.7 + PAGE_TRANSITION_DELAY / 1000,
              //   },
              // }}
              className="flex w-full flex-col lg:flex-row lg:justify-between"
            >
              <p className="eyebrows flex items-center">
                {insight.fields.type && (
                  <span className="mr-2">{insight.fields.type}</span>
                )}
                <VerticalLine />

                {insight.fields.date && (
                  <span className="ml-2">
                    {getPrettyDate(insight.fields.date)}
                  </span>
                )}
              </p>
              <div className="lgmax:mt-6">
                {insight.fields.experts?.length ? (
                  <div className="flex w-max">
                    <ExpertCarouselTakeover
                      experts={insight.fields.experts.map(
                        ({ fields }) => fields
                      )}
                      backgroundColor={insight.fields.topic?.fields.color}
                    />
                  </div>
                ) : null}
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
      {insight.fields.image && (
        <div className="relative z-[1] w-full lg:min-h-[66vh]">
          <motion.div
            className="absolute inset-0 -z-10 h-1/2"
            style={{
              backgroundColor: insight.fields.topic?.fields.color ?? "#F5F5F5",
            }}
          />
          <div
            className={clsx(
              "absolute bottom-0 -left-[100vw] -right-[100vw] -z-10 h-1/2",
              {
                "bg-slate": chapters && Object.keys(chapters)?.length,
              }
            )}
          />
          <motion.div
            // initial={{ opacity: 0 }}
            // animate={{
            //   opacity: 1,
            //   transition: {
            //     duration: 1,
            //     delay: 0.7 + PAGE_TRANSITION_DELAY / 1000,
            //   },
            // }}
            className="tbi-grid relative -z-10 w-screen lg:mx-auto lg:pb-20"
          >
            <div className="article-columns-max">
              <div className="mx-auto aspect-3/4 w-full overflow-hidden lg:aspect-video">
                <ScaleUpOnScroll className="h-full w-full">
                  <ContentfulCenteredImage
                    className="h-full w-full"
                    image={insight.fields.image.fields}
                  />
                </ScaleUpOnScroll>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </section>
  );
}
