import { useLocation } from "@remix-run/react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useEffect, useRef, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useEffectOnce } from "react-use";
import { IFootnote, SpecificLocale } from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";

type FootnoteProps = {
  footnote: SpecificLocale<IFootnote>;
};

export default function Footnote({ footnote }: FootnoteProps) {
  const rootRef = useRef<HTMLAnchorElement>(null);
  const [footIx, setFootIx] = useState<number | null>(null);
  const location = useLocation();

  useEffectOnce(() => {
    const footnoteEls = document.querySelectorAll("[data-footnote]");
    const index =
      Array.from(footnoteEls).findIndex((el) => el === rootRef.current) + 1;
    setFootIx(index);
  });

  useEffect(() => {
    if (
      footIx &&
      footIx + "" === location.hash.split("#footnote_")[1] &&
      rootRef.current
    ) {
      rootRef.current.scrollIntoView();
    }
  }, [footIx, location]);

  if (!footIx)
    return (
      <sup data-footnote ref={rootRef}>
        [_]
      </sup>
    );

  return (
    <div className="group relative inline-block">
      <HashLink
        to={`#footnote_list_item_${footIx}`}
        id={`footnote_${footIx}`}
        className="scroll-mt-40"
        data-footnote
        ref={rootRef}
      >
        <sup>[{footIx}]</sup>
        <span className="sr-only">Link to footnote</span>
      </HashLink>
      {/* TODO: Switch the p tags to spans or something here because this will be inside of another paragraph */}
      {footnote.fields.body && (
        <>
          <div
            id={"tooltip-default-" + footIx}
            role="tooltip"
            className="tooltip [&>*]:body-small absolute -left-[50px] top-[110%] z-10 hidden w-max max-w-[250px] rounded-lg bg-white px-3 py-2 opacity-0 shadow-md transition-opacity duration-300 group-hover:inline-block group-hover:opacity-100 lg:max-w-[400px] [&>*]:my-0 [&>*]:text-secondary_text"
          >
            {documentToReactComponents(
              footnote.fields.body,
              getComponentFromContentfulRichTextEntry
            )}
          </div>
        </>
      )}
    </div>
  );
}
