import { TextCta } from "../ctas";
import { Arrow, Search } from "../icons";
import { useSearchBox } from "react-instantsearch-hooks-web";
import clsx from "clsx";
import React, {
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";

type AlgoliaSearchInputProps = {
  formAction?: string;
  inputName?: string;
  preventDefault?: boolean;
  autofocus?: boolean;
  suggestion?: string;
  onArrowPress?: (key: "up" | "down") => void;
};

type QueryHook = (query: string, hook: (value: string) => void) => void;

const debounceRefine = debounce<QueryHook>(
  (query, hook) => {
    return hook(query);
  },
  300,
  { leading: true, maxWait: 500 }
);

const throttledSetter = throttle<QueryHook>(
  (query, hook) => {
    return hook(query);
  },
  500,
  { leading: false, trailing: true }
);

/**
 * Must be a decendant of <AlgoliaProvider>
 */
export default function AlgoliaSearchInput({
  formAction,
  inputName,
  preventDefault,
  autofocus,
  suggestion,
  onArrowPress,
}: AlgoliaSearchInputProps) {
  const searchBox = useSearchBox();
  const [input, setInput] = useState(searchBox?.query || "");
  const inputRef = useRef<HTMLInputElement | null>(null);

  // Get the part after the input query.
  const splitSuggestion =
    suggestion && input && suggestion?.match(`^${input}(.+)`)?.[1];

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    try {
      const newValue = e.target.value;
      // Cancel state overrides if the user is typing.
      throttledSetter.cancel();
      setInput(newValue);
      debounceRefine(newValue, searchBox.refine);
    } catch (error) {
      console.error("Error updating input:", error);
    }
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        onArrowPress?.("up");
        break;
      case "ArrowDown":
        e.preventDefault();
        onArrowPress?.("down");
        break;
      case "Tab":
        if (!suggestion) break;
        throttledSetter.cancel();
        setInput(suggestion);
        searchBox.refine(suggestion);
        e.preventDefault();
        break;
    }
  };

  const handleClear = () => {
    try {
      searchBox.clear();
      setInput("");
    } catch (error) {
      console.error("Error clearing input:", error);
    }
  };

  const handleSubmit: React.FormEventHandler = (e) => {
    if (preventDefault) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    try {
      // Throttle to prevent overriding when a user is typing.
      throttledSetter(searchBox.query, setInput);
    } catch (error) {
      console.error("Error syncing input with query:", error);
    }
  }, [searchBox.query]);

  return (
    <form
      className="relative w-full rounded-[100px] bg-onyx"
      action={formAction}
      onSubmit={handleSubmit}
      onClick={() => inputRef.current?.focus()}
      onKeyDown={handleKeyDown}
      data-content-type="search-input"
      data-search-query={searchBox.query}
    >
      {!!input.length && splitSuggestion && (
        <div className="eyebrows pointer-events-none absolute left-[1px] flex h-full items-center whitespace-pre px-14 text-secondary_text">
          <div className="invisible">{input}</div>
          <div>{splitSuggestion}</div>
        </div>
      )}
      <input
        autoFocus={autofocus}
        ref={inputRef}
        name={inputName}
        value={input}
        placeholder="Search"
        onChange={handleChange}
        className={
          "eyebrows h-14 w-full rounded-[100px] border-0 border-charcoal bg-transparent px-14 text-white outline-none hide-webkit-cancel-button "
        }
      />
      <div className="flex-center absolute top-0 left-6 h-full text-white">
        <button type="submit" aria-label="search">
          <Search className="text-secondary_text" role="presentation" />
        </button>
      </div>
      <div className="flex-center absolute top-0 right-6 h-full">
        {searchBox.query?.length ? (
          <TextCta
            label="Clear"
            kind="white"
            className={clsx("text-secondary_text no-underline")}
            onClick={handleClear}
            type="button"
          />
        ) : (
          <Arrow className="text-white" role="presentation" />
        )}
      </div>
    </form>
  );
}
