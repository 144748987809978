import clsx from "clsx";
import { InsightGetters } from "~/@types";
import { CircleIconCta } from "../ctas";
import { Arrow } from "../icons";
import NavOrExternalLink from "../shared/NavOrExternalLink";
import { getPrettyDate } from "../utils/getPrettyDate";
import Experts from "./Experts";

type ListCardProps = {
  insight: InsightGetters;
};

export default function ListCard({ insight }: ListCardProps) {
  if (!insight || !insight.getId()) return null;

  return (
    <div
      className={clsx([
        "mx-auto",
        "max-w-content-max",
        "px-mobile-gutter",
        "lg:px-desktop-gutter",
      ])}
    >
      <div
        className={clsx([
          "flex",
          "flex-col",
          "lg:grid",
          "lg:grid-cols-12",
          "py-6",
          "border-b",
          "border-b-black",
        ])}
      >
        <div className="mb-4 lg:col-span-3 lgmax:text-secondary_text">
          {insight.getExperts()?.length ? (
            <Experts experts={insight.getExperts()!} />
          ) : null}
        </div>
        <NavOrExternalLink
          to={`/insights/${insight.getTopic()?.slug}/${insight.getSlug()}`}
          className="group flex items-center justify-between lg:col-span-9"
          prefetch="intent"
          data-content-type="listCard"
          data-module-title={insight.getSlug()}
        >
          <div className="max-w-[600px]">
            <h5 className="mb-4 lg:mb-1">{insight.getTitle()}</h5>
            <p className="meta text-secondary_text">
              {getPrettyDate(insight.getDate())}
            </p>
          </div>
          <div>
            <CircleIconCta
              kind="black"
              icon={<Arrow />}
              label={insight.getTitle()}
              className="-translate-x-2 opacity-0 transition-[opacity,transform] duration-300 group-hover:translate-x-0 group-hover:opacity-100"
            />
          </div>
        </NavOrExternalLink>
      </div>
    </div>
  );
}
