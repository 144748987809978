import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import {
  ITextSplitFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import { isLastBlockEmptyParagraph } from "~/contexts/dataUtils/isLastBlockEmptyParagraph";

export type FiftyFiftyTextSplitProps = {
  fields: SpecificLocaleFields<ITextSplitFields>;
};

const FiftyFiftyTextSplitComponent = ({ fields }: FiftyFiftyTextSplitProps) => {
  const headingContent = fields.heading?.content;
  if (headingContent && isLastBlockEmptyParagraph(headingContent)) {
    headingContent.pop();
  }

  return (
    <section
      data-content-type="50-50-text-split"
      data-module-title={fields.moduleTitle}
      className={clsx("w-screen", {
        "text-white": ["black"].includes(fields.backgroundColor),
        "text-black": ["white", "slate"].includes(fields.backgroundColor),
        "bg-black": fields.backgroundColor === "black",
        "bg-white": fields.backgroundColor === "white",
        "bg-slate": fields.backgroundColor === "slate",
      })}
      style={{
        backgroundColor: fields.colorPalette?.fields.backgroundColor,
        color: fields.colorPalette?.fields.fontColor,
      }}
    >
      <div className="tbi-grid flex flex-col py-16 lg:flex-row lg:py-20">
        <div
          className={clsx("col-span-4 lg:col-span-5", {
            "lgmax:hidden": !fields.eyebrow && !fields.heading,
          })}
        >
          {fields.eyebrow?.length && (
            <p className="eyebrows mb-4">{fields.eyebrow}</p>
          )}
          {fields.heading && (
            <div className="mb-4 [&>:first-child]:mt-0 [&_hr]:p-0">
              {fields.heading &&
                documentToReactComponents(
                  fields.heading,
                  getComponentFromContentfulRichTextEntry
                )}
            </div>
          )}
        </div>

        <div className="col-span-4 lg:col-span-7 [&>:first-child]:mt-0">
          {fields.headline && <h3 className="mb-6">{fields.headline}</h3>}
          {fields.bodyRichText &&
            documentToReactComponents(
              fields.bodyRichText,
              getComponentFromContentfulRichTextEntry
            )}
        </div>
      </div>
    </section>
  );
};
export default FiftyFiftyTextSplitComponent;
