import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "@remix-run/react";
import type { ReactNode } from "react";
import { usePrevious, useShallowCompareEffect, useWindowSize } from "react-use";

// See tailwind config for these vals
const desktopNavHeight = 96;
const mobileNavHeight = 80;
const lgScreenWidth = 1024;

export type NavShowing = boolean | null;

type NavShowingContextType = {
  navShowing: NavShowing;
  atTopOfPage: boolean;
  setNavShowing: Dispatch<SetStateAction<NavShowing>>;
  setNavDisabled: Dispatch<SetStateAction<boolean>>;
  setNavLocked: Dispatch<SetStateAction<boolean>>;
  navHeight: number;
};

const NavShowingContext = createContext<NavShowingContextType | undefined>(
  undefined
);

function NavShowingProvider({ children }: { children: ReactNode }) {
  const { width } = useWindowSize();
  const location = useLocation();
  const [navDisabled, setNavDisabled] = useState(false);
  const [navShowing, setNavShowing] = useState<NavShowing>(true);
  const [atTopOfPage, setAtTopOfPage] = useState(true);
  const [navHeight, setNavHeight] = useState(desktopNavHeight);
  const [navLocked, setNavLocked] = useState(false);

  // Always re-activate the nav bar when navigating
  const prevLocation = usePrevious(location);
  useShallowCompareEffect(() => {
    if (location.pathname !== prevLocation?.pathname) {
      setNavDisabled(false);
      setNavShowing(true);
    }
  }, [location]);

  useEffect(() => {
    const nh =
      width && width < Number(lgScreenWidth)
        ? mobileNavHeight
        : desktopNavHeight;

    setNavHeight(nh);
  }, [width, navHeight]);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    if (navDisabled) {
      setNavShowing(false);
      return;
    }

    if (navLocked) {
      setNavShowing(true);
      return;
    }

    const updateShowNav = () => {
      if (navDisabled || navLocked) {
        return;
      }

      const distanceFromTopNavStartsBeingHidden = 30;
      const topOfPageMark = distanceFromTopNavStartsBeingHidden + 1;
      const scrollSensitivity = 2;
      const scrollY = window.pageYOffset;
      const shouldShowNav =
        scrollY > lastScrollY &&
        window.scrollY > distanceFromTopNavStartsBeingHidden
          ? false
          : true;

      const scrollDelta = scrollY - lastScrollY;

      if (
        // If the state needs to be changed and the scroll delta is greater than 2
        shouldShowNav !== navShowing &&
        (scrollDelta > scrollSensitivity || scrollDelta < -scrollSensitivity)
      ) {
        setNavShowing(shouldShowNav);
      }

      if (scrollY <= topOfPageMark && !atTopOfPage) {
        setAtTopOfPage(true);
        setNavShowing(true);
      } else if (scrollY >= topOfPageMark * 2 && atTopOfPage) {
        setAtTopOfPage(false);
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    updateShowNav();

    window.addEventListener("scroll", updateShowNav);
    return () => {
      window.removeEventListener("scroll", updateShowNav);
    };
  }, [navShowing, atTopOfPage, navDisabled, navHeight, navLocked]);

  return (
    <NavShowingContext.Provider
      value={{
        navShowing,
        atTopOfPage,
        setNavShowing,
        setNavDisabled,
        navHeight,
        setNavLocked,
      }}
    >
      {children}
    </NavShowingContext.Provider>
  );
}

function useNavShowing() {
  const context = useContext(NavShowingContext);
  if (context === undefined) {
    throw new Error("useNavShowing must be used within a NavShowingProvider");
  }
  return context;
}

export { NavShowingProvider, useNavShowing };
