import ExpertBioTakeover from "./ExpertBioTakeover";
import { IExpert } from "~/@types/generated/contentful";
import { ForcedAspectRatio, ContentfulCenteredImage } from "../shared";
import ExpertSocialLinks from "./ExpertSocialLinks";
import { ClientSide } from "~/@types";

export type ExpertHeroProps = {
  expert: ClientSide<IExpert>;
};

export default function ExpertHero({ expert }: ExpertHeroProps) {
  return (
    <section
      data-content-type="expertHero"
      data-module-title={expert.fields.slug}
      className="global-container flex h-screen w-full flex-col items-center justify-center pt-mobile-nav pb-20 text-center lg:pt-48 lg:pb-11"
    >
      <h1 className="h2 mb-6">
        {expert.fields.firstName} {expert.fields.lastName}
      </h1>
      {expert.fields.image &&
      expert.fields.image.fields &&
      expert.fields.type === "Current TBI" ? (
        <ForcedAspectRatio ratio={[3, 4]} className={"mb-6 min-w-[10vw] grow"}>
          <ContentfulCenteredImage
            className="h-full w-full"
            image={expert.fields.image.fields ?? ""}
          />
        </ForcedAspectRatio>
      ) : (
        <div className="h3 align-center flex-center mb-6 flex aspect-3/4 min-w-[10vw] grow bg-slate px-4">
          {expert.fields.firstName?.[0]}
          {expert.fields.lastName?.[0]}
        </div>
      )}
      <p className="eyebrows mx-4 mb-6">{expert.fields.role}</p>
      {expert.fields.showBio && expert.fields.type === "Current TBI" && (
        <div className="mb-6 flex justify-center">
          <ExpertBioTakeover expert={expert} />
        </div>
      )}
      <ExpertSocialLinks expert={expert} className="justify-center" />
    </section>
  );
}
