import { InsightGetters } from "~/@types";
import ListCard from "./ListCard";

type ListViewProps = {
  insights: InsightGetters[];
};

export default function ListView({ insights }: ListViewProps) {
  return (
    <>
      {insights.map((insight) => (
        <ListCard insight={insight} key={insight.getId()} />
      ))}
    </>
  );
}
