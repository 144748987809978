import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import { useRef } from "react";
import {
  ISingleImageParallaxFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { ContentfulCenteredImage } from "~/components/shared/";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import { useSingleImageParallax } from "./useSingleImageParallax";

type SingleParallaxProps = {
  fields: SpecificLocaleFields<ISingleImageParallaxFields>;
};

export default function SingleParallax({ fields }: SingleParallaxProps) {
  const intersectionRef = useRef<HTMLDivElement>(null);
  const stickyRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  useSingleImageParallax({
    intersectionRef,
    stickyRef,
    scrollRef,
  });

  return (
    <section
      data-content-type="singleImageParallax"
      data-module-title={fields.moduleTitle}
      ref={intersectionRef}
      className="global-container relative mx-auto mb-20 flex h-[200vh] max-w-[800px] flex-col items-center"
    >
      <div
        ref={stickyRef}
        className={clsx([
          "absolute",
          "top-0",
          "-z-10",
          "h-screen",
          "w-screen",
          "flex",
          "justify-center",
          "items-center",
        ])}
      >
        <div className="h-[240px] w-[180px] md:h-[400px] md:w-[300px]">
          <ContentfulCenteredImage
            image={fields.image.fields}
            className="h-full w-full pb-6"
          />
        </div>
      </div>
      <div className="h-screen w-screen" />
      <div
        ref={scrollRef}
        className="relative flex h-screen w-full items-center justify-center"
      >
        {fields.body && (
          <div
            className={clsx([
              "[&>*]:leading-[120%]",
              "[&>*]:ppe",
              "[&>*]:block",
              // Text size
              `[&>*]:text-52`,
              `[&>*]:md:text-100`,
              "mdmax-inclusive:w-min",
              "mdmax-inclusive:break-normal",
              "mdmax-inclusive:min-w-[80vw]",
            ])}
          >
            {documentToReactComponents(
              fields.body,
              getComponentFromContentfulRichTextEntry
            )}
          </div>
        )}
      </div>
    </section>
  );
}
