import { useState, useEffect } from "react";

function useIsMobile(maxMobileWidth = 768): boolean {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function checkIfMobile() {
      setIsMobile(window.innerWidth < maxMobileWidth);
    }

    checkIfMobile(); // Check on initial render

    window.addEventListener("resize", checkIfMobile);

    return () => window.removeEventListener("resize", checkIfMobile);
  }, [maxMobileWidth]); // Include maxMobileWidth in the dependency array

  return isMobile;
}

export default useIsMobile;
