import { Arrow } from "../icons";
import TextCta from "../ctas/TextCta";
import { useRef, useState } from "react";
import {
  IFiftyFiftyCarouselFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import EmblaCarousel from "./EmblaCarousel";
import { ContentfulCenteredImage, ForcedAspectRatio } from "../shared";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import { ZoomOut } from "../animations";
import FlutterText from "../animations/flutterText";
import { motion } from "framer-motion";
import clsx from "clsx";

export type FiftyFiftyCarouselProps = {
  fields: SpecificLocaleFields<IFiftyFiftyCarouselFields>;
};

export default function FiftyFiftyCarousel({
  fields,
}: FiftyFiftyCarouselProps) {
  const [selected, setSelected] = useState(0);
  const _innerRef = useRef<HTMLDivElement>(null);

  const [lastIndex, setLastIndex] = useState<number | null>(null);
  const [newIndex, setNewIndex] = useState<number>(selected);

  let _onClickItem = function (index: number) {
    setSelected((last) => {
      setLastIndex(last);
      setNewIndex(index);
      return index;
    });
  };

  if (!fields.items?.length) return null;

  const selectedBody = fields.items[selected].fields.body;

  if (!fields.backgroundColor) fields.backgroundColor = "white";

  return (
    <section
      data-content-type="50-50-carousel"
      data-module-title={fields.moduleTitle}
      ref={_innerRef}
      data-component="fiftyFiftyCarousel"
      className={clsx("tbi-grid w-full py-20", {
        "text-white": ["black"].includes(fields.backgroundColor),
        "text-black": ["white", "slate"].includes(fields.backgroundColor),
        "bg-black": fields.backgroundColor === "black",
        "bg-white": fields.backgroundColor === "white",
        "bg-slate": fields.backgroundColor === "slate",
      })}
    >
      <div className="relative col-span-6 lgmax:hidden">
        <p className="eyebrows mb-10 mt-24">{fields.eyebrow}</p>
        {fields.items.map((item, index) => (
          <h3
            key={index}
            className={`relative cursor-pointer pb-6 ${
              index == selected
                ? "before:absolute before:-left-10 before:content-['•']"
                : "text-secondary_text"
            }`}
            onClick={() => _onClickItem(index)}
            tabIndex={0}
          >
            {item.fields && item.fields.heading && (
              <FlutterText text={item.fields.heading} />
            )}
          </h3>
        ))}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 1, delay: 0.3 },
          }}
          className="absolute bottom-24 max-w-[400px] font-medium"
        >
          {selectedBody && (
            <>
              {documentToReactComponents(
                selectedBody,
                getComponentFromContentfulRichTextEntry
              )}
            </>
          )}
          <TextCta
            label={"learn more"}
            kind={fields.backgroundColor === "black" ? "white" : "black"}
            icon={<Arrow />}
            to={fields.items[selected].fields.url}
            className="mt-10"
          />
        </motion.div>
      </div>
      <p className="eyebrows col-span-full mb-10 lg:hidden">{fields.eyebrow}</p>
      <div className="relative col-span-6 flex aspect-3/4 w-full overflow-hidden lgmax:hidden">
        <ZoomOut fields={fields} lastIndex={lastIndex} newIndex={newIndex} />
      </div>
      <div className="relative col-span-6 flex w-full lg:hidden">
        <EmblaCarousel
          className={"-ml-2 overflow-visible lg:hidden"}
          navigationType={"footer"}
          peek
        >
          {fields.items.map((item, index) => (
            <div key={index} className="mr-2 -translate-x-2">
              <ForcedAspectRatio ratio={[3, 4]}>
                {item.fields.image && (
                  <ContentfulCenteredImage
                    className={`mb-6 h-full w-full`}
                    image={item.fields.image.fields}
                  />
                )}
              </ForcedAspectRatio>
              <h3 className="mb-3">{item.fields.heading}</h3>
              {item.fields.body && (
                <>
                  {documentToReactComponents(
                    item.fields.body,
                    getComponentFromContentfulRichTextEntry
                  )}
                </>
              )}
              <TextCta
                label={"Learn More"}
                kind={fields.backgroundColor === "black" ? "white" : "black"}
                className={"absolute"}
                icon={<Arrow />}
                to={item.fields.url}
              />
            </div>
          ))}
        </EmblaCarousel>
      </div>
    </section>
  );
}
