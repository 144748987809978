import clsx from "clsx";
import { MouseEvent } from "react";
import NodeIcon from "./NodeIcon";
import { CtaIconButtonProps, EventElementTypes } from "../types";
import NavOrExternalLink from "../shared/NavOrExternalLink";

const TextCtaKinds = {
  black: "black",
  blackTertiary: "black-tertiary",
  white: "white",
  whiteTertiary: "white-tertiary",
};

type TextCtaKind = "black" | "white" | "black-tertiary" | "white-tertiary";

export interface TextCtaProps extends CtaIconButtonProps {
  kind: TextCtaKind;
  labelClassName?: string;
  underline?: boolean;
  colorOverride?: string;
  iconOverride?: string;
}

export default function TextCta({
  label,
  kind,
  icon,
  disabled,
  to,
  onClick,
  name,
  value,
  type,
  className = "",
  iconClassName = "",
  labelClassName = "",
  as = "button",
  underline,
  id,
  dataAttrs,
  navLinkProps,
  colorOverride,
  iconOverride,
}: TextCtaProps) {
  underline = underline ?? !icon;
  const btnClassName = clsx({
    group: true,
    flex: true,
    "flex-row": true,
    "items-center": true,
    "justify-center": true,
    "transition-all": true,
    "duration-75": true,
    "gap-[16px]": true,
    "w-max": true,
    uppercase: true,
    body: true,
    cta: true,
    aeonik: true,
    "whitespace-nowrap": true,
    "outline-none": true,
    underline: underline,
    "hover:no-underline": underline,
    "hover:underline": !disabled && !underline,
    "active:underline": !disabled && !underline,
    "disabled:underline": underline,
    "text-white": [TextCtaKinds.white, TextCtaKinds.whiteTertiary].includes(
      kind
    ),
    "text-black": [TextCtaKinds.blackTertiary, TextCtaKinds.black].includes(
      kind
    ),
    "focus:bg-dark_grey":
      [TextCtaKinds.blackTertiary].includes(kind) || colorOverride,
    "focus:text-charcoal": [TextCtaKinds.black].includes(kind),
    "focus:text-dark_grey": [
      TextCtaKinds.white,
      TextCtaKinds.whiteTertiary,
    ].includes(kind),
    "active:text-charcoal": [TextCtaKinds.black].includes(kind) && !disabled,
    "active:text-dark_grey": [TextCtaKinds.white].includes(kind) && !disabled,
    "text-secondary_text":
      [
        TextCtaKinds.white,
        TextCtaKinds.black,
        TextCtaKinds.whiteTertiary,
        TextCtaKinds.blackTertiary,
      ].includes(kind) && disabled,
    "disabled:text-secondary_text": [
      TextCtaKinds.white,
      TextCtaKinds.black,
      TextCtaKinds.whiteTertiary,
      TextCtaKinds.blackTertiary,
    ].includes(kind),
  });

  const handleClick = (e: MouseEvent<EventElementTypes>) => {
    if (onClick && !disabled) {
      onClick(e);
    }
  };

  const renderIcon = () => (
    <span
      className={`${clsx({
        "rounded-full": [TextCtaKinds.black, TextCtaKinds.white].includes(kind),
        "bg-black": [TextCtaKinds.black].includes(kind),
        "bg-white": [TextCtaKinds.white].includes(kind),
        "text-black":
          [TextCtaKinds.white].includes(kind) ||
          (disabled && [TextCtaKinds.white].includes(kind)),
        "text-white":
          [TextCtaKinds.black].includes(kind) ||
          (disabled && [TextCtaKinds.black].includes(kind)),
        "group-focus:bg-charcoal": [TextCtaKinds.black].includes(kind),
        "group-focus:bg-dark_grey": [TextCtaKinds.white].includes(kind),
        "group-active:bg-charcoal":
          [TextCtaKinds.black].includes(kind) && !disabled,
        "group-active:bg-dark_grey":
          [TextCtaKinds.white].includes(kind) && !disabled,
        "group-active:text-charcoal":
          [TextCtaKinds.blackTertiary].includes(kind) && !disabled,
        "group-active:text-dark_grey":
          [TextCtaKinds.whiteTertiary].includes(kind) && !disabled,
        "bg-secondary_text":
          disabled && [TextCtaKinds.white, TextCtaKinds.black].includes(kind),
      })} ${iconClassName}`}
      style={{ backgroundColor: colorOverride }}
    >
      <NodeIcon aria-hidden="true" style={{ color: iconOverride }}>
        {icon}
      </NodeIcon>
    </span>
  );

  const labelEl = <span className={labelClassName}>{label}</span>;

  if (to && !disabled) {
    return (
      <NavOrExternalLink
        id={id}
        to={to}
        className={`${btnClassName} ${className}`}
        onClick={handleClick}
        title={label}
        {...navLinkProps}
        {...dataAttrs}
        style={{ color: colorOverride }}
      >
        {labelEl}
        {icon && renderIcon()}
      </NavOrExternalLink>
    );
  }

  const As = as;

  return (
    <As
      id={id}
      disabled={disabled}
      className={`${btnClassName} ${className}`}
      onClick={handleClick}
      title={label}
      name={name}
      value={value}
      type={type}
      {...dataAttrs}
    >
      {labelEl}
      {icon && renderIcon()}
    </As>
  );
}
