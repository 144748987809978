import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import {
  ITextBlockFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";

type TextBlockProps = {
  fields: SpecificLocaleFields<ITextBlockFields>;
  className?: string;
};

export default function TextBlock({ fields, className }: TextBlockProps) {
  if (!fields.backgroundColor) fields.backgroundColor = "white";

  return (
    <section
      id={fields.id}
      data-content-type="textBlock"
      data-module-title={fields.moduleTitle}
      className={clsx(className, {
        "text-white": ["black"].includes(fields.backgroundColor),
        "text-black": ["white", "slate"].includes(fields.backgroundColor),
        "bg-black": fields.backgroundColor === "black",
        "bg-white": fields.backgroundColor === "white",
        "bg-slate": fields.backgroundColor === "slate",
      })}
      style={{
        backgroundColor: fields.colorPalette?.fields.backgroundColor,
        color: fields.colorPalette?.fields.fontColor,
      }}
    >
      <div className="tbi-grid">
        {fields.body && (
          <>
            <div
              className="[&_p]:body lgmax:hidden [&_p]:mt-6"
              style={{
                gridColumn: `span ${fields.columns} / span ${fields.columns}`,
                gridColumnStart: fields.columnStart,
              }}
            >
              {documentToReactComponents(
                fields.body,
                getComponentFromContentfulRichTextEntry
              )}
            </div>
            <div className="[&_p]:body col-span-4 lg:hidden [&_p]:mt-6">
              {documentToReactComponents(
                fields.body,
                getComponentFromContentfulRichTextEntry
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
}
