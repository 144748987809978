import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState } from "react";
import { useEffectOnce, useEvent } from "react-use";
import {
  IFeaturedCarouselFields,
  IInsightFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { TextCta } from "../ctas";
import { Arrow } from "../icons";
import FeaturedInsightCard from "./FeaturedInsightCard";

function ScrollArrows({
  className,
  show,
  onClick,
  svgClassName,
}: {
  className?: string;
  svgClassName?: string;
  show?: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={clsx(
        className,
        "flex-center absolute h-[calc(100%+36px)] w-8 transition-opacity lg:hidden",
        {
          "opacity-100": show,
          "opacity-0": !show,
        }
      )}
      onClick={onClick}
    >
      <svg
        stroke="currentColor"
        fill="currentColor"
        className={clsx("relative bottom-[6px]", svgClassName)}
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.296 7.71 14.621 12l-4.325 4.29 1.408 1.42L17.461 12l-5.757-5.71z"></path>
        <path d="M6.704 6.29 5.296 7.71 9.621 12l-4.325 4.29 1.408 1.42L12.461 12z"></path>
      </svg>
    </div>
  );
}

type InsightColorCarouselProps = {
  fields: SpecificLocaleFields<IFeaturedCarouselFields>;
};

export default function InsightColorCarousel({
  fields,
}: InsightColorCarouselProps) {
  const ref = useRef<HTMLDivElement>(null);
  const activeInsights = fields.insights.filter(({ fields }) => !!fields);

  const [currentInsight, setCurrentArticle] = useState<
    SpecificLocaleFields<IInsightFields>
  >({ ...activeInsights[0].fields });

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [showRight, setShowRight] = useState(false);
  const [showLeft, setShowLeft] = useState(false);

  const setShowArrows = () => {
    if (ref.current) {
      const shouldShowRight =
        ref.current.scrollLeft !==
        ref.current.scrollWidth - ref.current.offsetWidth;
      setShowRight(shouldShowRight);

      const shouldShowLeft = ref.current.scrollLeft !== 0;
      setShowLeft(shouldShowLeft);
    }
  };

  const scrollTopicsRight = () => {
    if (ref.current) {
      ref.current.scroll({
        left: ref.current.scrollLeft + 200,
        behavior: "smooth",
      });
    }
  };

  const scrollTopicsLeft = () => {
    if (ref.current) {
      ref.current.scroll({
        left: ref.current.scrollLeft - 200,
        behavior: "smooth",
      });
    }
  };

  useEffectOnce(() => {
    setShowArrows();
  });

  useEvent("scroll", setShowArrows, ref.current);

  // Don't render this at all if no featured articles have been set
  if (!activeInsights.length) return null;

  return (
    <section
      data-content-type="featuredCarousel"
      data-module-title={fields.moduleTitle}
      className="h-full w-screen px-mobile-gutter py-16 pt-mobile-nav lg:py-20 lg:px-desktop-gutter"
      style={{
        backgroundColor: currentInsight?.topic?.fields.color,
      }}
    >
      <div className="mx-auto max-w-content-max text-white">
        <div className="flex justify-between border-b border-white pb-6">
          <AnimatePresence mode="wait" initial={false}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.5 } }}
              exit={{ opacity: 0.1, transition: { duration: 0.5 } }}
              key={currentInsight.title}
            >
              <h4>{currentInsight.topic.fields.title}</h4>
            </motion.div>
          </AnimatePresence>
          <div className="lgmax:hidden">
            <TextCta
              kind="white"
              icon={<Arrow />}
              label="See all"
              to={`/insights/${currentInsight?.topic?.fields?.slug}`}
            />
          </div>
        </div>
        <div className="relative">
          <ScrollArrows
            className="-right-8"
            onClick={scrollTopicsRight}
            show={showRight}
          />
          <ScrollArrows
            className="-left-8"
            svgClassName="rotate-180"
            onClick={scrollTopicsLeft}
            show={showLeft}
          />
          <div className="no-scrollbar overflow-x-scroll" ref={ref}>
            <div className="flex w-max">
              {activeInsights.map((insight, index) =>
                insight.fields && Object.keys(insight.fields).length ? (
                  <div key={insight.sys.id}>
                    <button
                      className={clsx({
                        "mr-4 pt-6": true,
                        "min-w-max": true,
                        "text-14": true,
                        uppercase: true,
                      })}
                      onClick={() => {
                        setCurrentArticle(insight.fields);
                        setSelectedIndex(index);
                      }}
                    >
                      {insight.fields.topic?.fields.title}
                    </button>
                    <AnimatePresence>
                      {insight.fields.slug === currentInsight?.slug && (
                        <motion.div
                          key={currentInsight?.slug}
                          className="mr-4 flex border-b-2 border-solid border-white"
                          initial={{
                            transform: "scaleX(0)",
                          }}
                          animate={{
                            transform: "scaleX(1)",
                            transition: { duration: 0.5 },
                          }}
                          exit={{
                            transform: "scaleX(0)",
                            transition: { duration: 0.5 },
                          }}
                        />
                      )}
                    </AnimatePresence>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-9 lg:mt-10">
        <div className="relative overflow-hidden">
          <AnimatePresence
            initial={false}
            exitBeforeEnter={false}
            mode="popLayout"
          >
            {activeInsights.map((insight, index) => {
              return (
                index == selectedIndex && (
                  <motion.div
                    key={insight.fields.slug + index}
                    initial={{
                      x: 0,
                      clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)",
                      WebkitClipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)",
                    }}
                    animate={{
                      x: 0,
                      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                      WebkitClipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                      transition: { duration: 0.7, ease: "easeInOut" },
                    }}
                    exit={{
                      x: 50,
                      transition: { duration: 1, ease: "easeInOut" },
                    }}
                  >
                    <FeaturedInsightCard
                      fields={insight.fields}
                      cardType="Color Carousel"
                    />
                  </motion.div>
                )
              );
            })}
          </AnimatePresence>
        </div>
      </div>
      <div className="mt-10 px-mobile-gutter lg:hidden">
        <TextCta
          kind="white"
          icon={<Arrow />}
          label="See all"
          to={`/insights/${currentInsight.topic?.fields?.slug}`}
        />
      </div>
    </section>
  );
}
