import { SVGProps } from "react";
const SvgShareBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="m17.305 7.025-10.08 5.05M16.935 16.785l-9.7-4.89"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M7.645 14.185a2.19 2.19 0 1 0 0-4.38 2.19 2.19 0 0 0 0 4.38ZM16.355 9.835a2.19 2.19 0 1 0 0-4.38 2.19 2.19 0 0 0 0 4.38ZM16.355 18.546a2.19 2.19 0 1 0 0-4.38 2.19 2.19 0 0 0 0 4.38Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgShareBlack;
