import ReactPlayer from "react-player";

type InsightVideoPlayerProps = {
  url: string;
};

export default function InsightVideoPlayer({ url }: InsightVideoPlayerProps) {
  return (
    <section className="article-columns-max relative my-20 aspect-video">
      <ReactPlayer url={url} width="100%" height="100%" />
    </section>
  );
}
