import { RoundedCta, TextCta } from "../ctas";
import { Portal } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import { Checkmark } from "../icons";
import clsx from "clsx";
import { useCookieContext } from "../utils/useCookies";
import { PAGE_TRANSITION_DELAY } from "~/contexts/PageTransitionProvider";

function MoreSettingsBtn() {
  const { setCookie } = useCookieContext();
  let [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflow = "";
  }

  function openModal() {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        closeModal();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <TextCta
        label="Information and Settings"
        kind="black-tertiary"
        onClick={openModal}
      />

      <Portal>
        <div
          className={clsx(
            "fixed inset-0 flex h-screen w-screen items-center justify-center bg-black transition-all",
            {
              "-z-50 bg-opacity-0": !isOpen,
              "z-50 bg-opacity-25": isOpen,
            }
          )}
        >
          <div
            className="absolute inset-0 z-40 h-full w-full"
            onClick={() => setIsOpen(false)}
          />
          <div
            className={clsx(
              "z-50 w-full max-w-md transform overflow-hidden bg-slate p-8 text-left align-middle text-black shadow-xl transition-all",
              {
                "opacity-0": !isOpen,
                "opacity-100": isOpen,
              }
            )}
            ref={modalRef}
          >
            <section
              role="dialog"
              aria-label="Cookie information and settings dialog"
            >
              <p className="body-large">
                To improve the experience of our users, we use cookies on this
                site.
              </p>
              <p className="body-large">
                Tony Blair Institute for Global Change uses cookies on this
                website.
              </p>
              <p className="body-large">
                They help us to know more about how you use our website, which
                improves the browsing experience and the ways we can suggest
                content - both for you and for others.
              </p>
              <p className="body-large">
                They are stored locally on your computer or mobile device. For
                more information and preferences go to the cookie policy. You
                will see this message only once.
              </p>
              <p className="body-large">
                By using our site you accept the terms of our Privacy Policy.
              </p>
              <div className="mt-8 flex w-full items-center justify-center gap-4">
                <RoundedCta
                  label="Turn cookies off"
                  kind="black"
                  className="p-2"
                  onClick={() => {
                    setCookie("analytics", { accepted: false });
                    closeModal();
                  }}
                />
                <RoundedCta
                  label="Turn cookies on"
                  kind="black"
                  className="p-2"
                  onClick={() => {
                    setCookie("analytics", { accepted: true });
                    closeModal();
                  }}
                />
              </div>
            </section>
          </div>
        </div>
      </Portal>
    </>
  );
}

export default function CookieConsent() {
  const { cookies, setCookie } = useCookieContext();
  const { analytics } = cookies;
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!analytics) {
      window.setTimeout(() => setIsMounted(true), PAGE_TRANSITION_DELAY + 1000);
    } else if (analytics.accepted || !analytics.accepted) {
      setIsMounted(false);
    }
  }, [analytics]);

  return (
    <Portal>
      <div
        role="alertdialog"
        aria-labelledby="cookie-banner"
        className={clsx(
          "fixed bottom-0 z-50 flex h-min w-screen flex-col items-center justify-between border-t border-solid border-dark_grey bg-slate px-mobile-gutter py-4 transition-[transform] duration-700 lg:flex-row lg:px-desktop-gutter lgmax:min-h-[140px]",
          {
            "translate-y-full": !isMounted,
            "translate-y-0": isMounted,
          }
        )}
      >
        <div className="flex h-full items-center justify-start lg:mr-6">
          <p id="cookie-banner" className="body-large">
            To improve the experience of our users, we use cookies on this site.
          </p>
        </div>
        <div className="my-6 flex flex-col-reverse items-center justify-between gap-4 lg:flex-row lg:gap-6">
          <MoreSettingsBtn />
          <RoundedCta
            label="I'm fine with this"
            kind="black"
            icon={<Checkmark />}
            onClick={() => setCookie("analytics", { accepted: true })}
          />
        </div>
      </div>
    </Portal>
  );
}
