import { useEffect, useRef, useState } from "react";

const scriptId = "flourish_script";

export function useFlourish(): Record<string, any> | undefined {
  const [loaded, setLoaded] = useState(
    typeof window == "undefined"
      ? undefined
      : !!document.getElementById(scriptId)
  );

  useEffect(() => {
    if (loaded) return;

    const script = document.createElement("script");
    script.src = "https://public.flourish.studio/resources/embed.js";
    script.setAttribute("id", scriptId);
    script.async = true;
    script.defer = true;
    script.addEventListener("load", () => setLoaded(true));
    document.head.prepend(script);
  }, [loaded]);

  if (typeof window !== "undefined") {
    return window.Flourish;
  }
}

type FlourishProps = {
  html: string;
  className?: string;
  beforeSecondChapter?: boolean;
};

export default function Flourish({
  html,
  className,
  beforeSecondChapter = false,
}: FlourishProps) {
  const ref = useRef<HTMLDivElement>(null);

  useFlourish();

  const dataSrc = html.match(/data-src=["'](.*?)["']/)?.[1];
  return (
    <div
      data-content-type="htmlString-flourish"
      className={`relative ${className}`}
    >
      <div
        ref={ref}
        className="flourish-embed flourish-chart"
        data-src={dataSrc}
      ></div>
      {beforeSecondChapter && (
        <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
      )}
    </div>
  );
}
