import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { createPortal } from "react-dom";
import { ClientSide } from "~/@types";
import { IExpertFields } from "~/@types/generated/contentful";
import { useNavShowing } from "~/contexts/NavShowingProvider";
import { ContentfulCenteredImage } from "../shared";
import { SimpleExpert } from "./ExpertsCard";
import ExpertsCarousel from "./ExpertsCarousel";

type ModalProps = {
  experts: ClientSide<IExpertFields>[] | SimpleExpert[];
  isOpen: boolean;
  onClose: () => void;
};

export const ExpertsCarouselModal = ({
  experts,
  isOpen,
  onClose,
}: ModalProps) => {
  if (typeof window === "undefined") return null;

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="flex-center fixed left-0 top-0 bottom-0 z-[60] w-screen overflow-hidden overflow-y-scroll  bg-slate text-black"
          initial={{ x: "100vw" }}
          animate={{ x: 0 }}
          exit={{ x: "100vw" }}
          transition={{ bounce: 0.1 }}
        >
          <ExpertsCarousel
            className="w-full py-0"
            closeFunction={onClose}
            experts={experts}
          />
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

type ExpertCarouselTakeoverProps = {
  backgroundColor?: string;
  experts: ClientSide<IExpertFields>[];
};

export default function ExpertCarouselTakeover({
  experts,
  backgroundColor,
}: ExpertCarouselTakeoverProps) {
  const { setNavDisabled, setNavShowing } = useNavShowing();
  const [open, _setIsOpen] = useState(false);
  const setIsOpen = (isOpen: boolean) => {
    _setIsOpen(isOpen);
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };

  return (
    <>
      <ExpertsCarouselModal
        experts={experts}
        isOpen={open}
        onClose={() => {
          setIsOpen(false);
          setNavDisabled(false);
          setNavShowing(true);
        }}
      />
      <button
        className="flex items-center"
        onClick={() => {
          setNavDisabled(true);
          setIsOpen(true);
        }}
      >
        {experts[0].image?.fields && experts[0].type === "Current TBI" ? (
          <ContentfulCenteredImage
            className="z-10 inline-block h-[30px] w-[30px] rounded-full"
            image={experts[0].image.fields}
          />
        ) : (
          <div
            className="flex-center z-10  h-[30px] w-[30px] rounded-full bg-white uppercase leading-[30px]"
            style={{ color: backgroundColor }}
          >
            {experts[0].firstName?.trim()[0]}
            {experts[0].lastName?.trim()[0]}
          </div>
        )}
        {experts[1] && experts[1].type === "Current TBI" ? (
          experts[1].image?.fields ? (
            <ContentfulCenteredImage
              className="relative right-1 inline-block h-[30px] w-[30px] rounded-full"
              image={experts[1].image.fields}
            />
          ) : (
            <div
              className="flex-center relative right-1 h-[30px] w-[30px] rounded-full bg-white uppercase leading-[30px]"
              style={{ color: backgroundColor }}
            >
              {experts[1].firstName?.trim()[0]}
              {experts[1].lastName?.trim()[0]}
            </div>
          )
        ) : null}
        <p className="eyebrows ml-4 underline">
          {experts.length > 1
            ? `By multiple experts (${experts.length})`
            : `${experts[0].firstName} ${experts[0].lastName}`}
        </p>
      </button>
    </>
  );
}
