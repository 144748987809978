import { useParams } from "@remix-run/react";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { ForcedAspectRatio, ContentfulCenteredImage } from "../shared";
import Experts from "./Experts";
import TopicComponent from "./Topic";
import { getPrettyDate } from "../utils/getPrettyDate";
import { InsightGetters } from "~/@types";
import NavOrExternalLink from "../shared/NavOrExternalLink";

type ColumnCardProps = {
  insights: InsightGetters[];
  className?: string;
  onClick?: (insight: InsightGetters) => void;
};

export default function ColumnCard({
  insights,
  className,
  onClick,
}: ColumnCardProps) {
  const { topicSlug } = useParams();
  const [hoveredArticle, setHoveredArticle] = useState<string | null>(null);

  if (!insights) return null;

  const getTrimmedArticleTitle = (insight: InsightGetters) => {
    const title = insight.getTitle();
    if (!title) {
      console.error(`Insight has no title ${insight.getId()}`);
      return "";
    }
    return title.slice(0, 59) + (title.length > 60 ? "..." : "");
  };

  const getCardStuff = (insight: InsightGetters, ix: number) => (
    <>
      {insight.getImageFields() ? (
        <ContentfulCenteredImage
          image={insight.getImageFields()!}
          className={clsx([
            "h-full",
            "w-full",
            "group-hover:scale-105",
            "transition-transform",
            "duration-1000",
            "ease-in-out",
            "-z-10",
            `${hoveredArticle === insight.getId() ? "scale-105" : ""}`,
          ])}
        />
      ) : (
        <div
          className="flex h-full w-full items-center justify-center text-white"
          style={{
            backgroundColor: insight.getTopic()?.color ?? "#F5F5F5",
          }}
        >
          <h4>{insight.getTopic()?.title}</h4>
        </div>
      )}
    </>
  );

  return (
    <section className={clsx("lg:py-10", className)}>
      <div className="lg:hidden">
        {insights.map((insight) => {
          if (insight)
            return (
              <Fragment key={insight.getId()}>
                <article
                  className="tbi-grid my-8"
                  data-content-type="columnCard"
                  data-module-title={insight.getSlug()}
                >
                  <NavOrExternalLink
                    onClick={() => onClick?.(insight)}
                    to={`/insights/${
                      insight.getTopic()?.slug
                    }/${insight.getSlug()}`}
                    prefetch="intent"
                  >
                    <ForcedAspectRatio className="col-span-1">
                      {insight.getImageFields() ? (
                        <ContentfulCenteredImage
                          image={insight.getImageFields()!}
                          className="h-full w-full"
                        />
                      ) : (
                        <div
                          className="flex h-full w-full items-center justify-center text-white"
                          style={{
                            backgroundColor:
                              insight.getTopic()?.color ?? "#F5F5F5",
                          }}
                        >
                          <h4 className="text-center">
                            {insight.getTopic()?.title}
                          </h4>
                        </div>
                      )}
                    </ForcedAspectRatio>
                  </NavOrExternalLink>
                  <div className="h-inherit col-span-3 flex flex-col">
                    {topicSlug ? (
                      <Experts experts={insight.getExperts()!} />
                    ) : (
                      <>
                        {insight.getTopic() ? (
                          <TopicComponent topic={insight.getTopic()!} />
                        ) : null}
                      </>
                    )}
                    <NavOrExternalLink
                      onClick={() => onClick?.(insight)}
                      to={`/insights/${
                        insight.getTopic()?.slug
                      }/${insight.getSlug()}`}
                      prefetch={"intent"}
                    >
                      <h5 className="mt-3">
                        {getTrimmedArticleTitle(insight)}
                      </h5>
                    </NavOrExternalLink>
                    <p className="meta mt-3 text-secondary_text">
                      {getPrettyDate(insight.getDate())}
                    </p>
                  </div>
                </article>
              </Fragment>
            );
          return null;
        })}
      </div>
      <div className="tbi-grid lgmax:hidden">
        {insights.map((insight, ix) => {
          if (insight)
            return (
              <Fragment key={insight.getId()}>
                {insight ? (
                  <NavOrExternalLink
                    data-content-type="columnCard"
                    data-module-title={insight.getSlug()}
                    to={`/insights/${
                      insight.getTopic()?.slug
                    }/${insight.getSlug()}`}
                    prefetch={"intent"}
                    onClick={() => onClick?.(insight)}
                    key={insight.getId()}
                    className="group col-span-4"
                  >
                    <ForcedAspectRatio className="relative h-full overflow-hidden">
                      {getCardStuff(insight, ix)}
                    </ForcedAspectRatio>
                  </NavOrExternalLink>
                ) : null}
              </Fragment>
            );
          return null;
        })}
      </div>
      <div className="tbi-grid lgmax:hidden">
        {insights.map((insight, ix) => {
          if (insight)
            return (
              <Fragment key={insight.getId()}>
                {insight ? (
                  <div
                    data-content-type="columnCard"
                    data-module-title={insight.getSlug()}
                    key={insight.getId()}
                    className="col-span-4 flex flex-col"
                  >
                    <div className="mt-9">
                      {/* Only show the experts when it's on a topic page. 
                      The existence of a topicSlug indicates we're on a topic page */}
                      {topicSlug ? (
                        <Experts experts={insight.getExperts()!} />
                      ) : (
                        <>
                          {insight.getTopic() ? (
                            <TopicComponent topic={insight.getTopic()!} />
                          ) : null}
                        </>
                      )}
                    </div>
                    <NavOrExternalLink
                      onClick={() => onClick?.(insight)}
                      to={`/insights/${
                        insight.getTopic()?.slug
                      }/${insight.getSlug()}`}
                      prefetch={"intent"}
                    >
                      <h5
                        className="mt-4"
                        onMouseEnter={() => setHoveredArticle(insight.getId())}
                        onMouseLeave={() => setHoveredArticle(null)}
                      >
                        {insight.getTitle()}
                      </h5>
                    </NavOrExternalLink>
                    <p className="meta mt-4 uppercase text-secondary_text">
                      {getPrettyDate(insight.getDate())}
                    </p>
                  </div>
                ) : null}
              </Fragment>
            );
          return null;
        })}
      </div>
    </section>
  );
}
