import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document as ContentfulDocument } from "@contentful/rich-text-types";
import { ClientSide } from "~/@types";
import { IItemStandfirstFields } from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";

export default function Standfirst({
  standfirstTitle,
  standfirstBody,
}: ClientSide<IItemStandfirstFields>) {
  return (
    <>
      {standfirstBody && (
        <section
          data-content-type="standfirst"
          data-module-title={standfirstTitle}
          className="global-grid relative col-span-full my-8"
        >
          <div className="article-columns body-large standfirst relative whitespace-pre-wrap [&>*]:font-bold">
            {documentToReactComponents(
              standfirstBody as ContentfulDocument,
              getComponentFromContentfulRichTextEntry
            )}
          </div>
        </section>
      )}
    </>
  );
}
