import { ClientSide } from "~/@types";
import { IExpertFields } from "~/@types/generated/contentful";
import { ContentfulCenteredImage, ForcedAspectRatio } from "../shared";
import NavOrExternalLink from "../shared/NavOrExternalLink";

export const assertExpert = (
  expert: ClientSide<IExpertFields> | { name: string; slug: string }
): expert is ClientSide<IExpertFields> => {
  return (
    expert.hasOwnProperty("firstName") && expert.hasOwnProperty("lastName")
  );
};

export type SimpleExpert = { name: string; slug: string; type?: string };
const assertSimpleExpert = (
  expert: ClientSide<IExpertFields> | SimpleExpert
): expert is SimpleExpert => expert.hasOwnProperty("name");

export type ExpertsCardProps = {
  className?: string;
  imageBackgroundColor?: string;
  expert:
    | Pick<
        ClientSide<IExpertFields>,
        "slug" | "image" | "firstName" | "lastName" | "role" | "type"
      >
    | SimpleExpert;
  onClick?: () => void;
};

export default function ExpertsCard({
  className = "",
  imageBackgroundColor = "bg-white",
  expert,
  onClick,
}: ExpertsCardProps) {
  const Root =
    assertExpert(expert) && expert.type === "Current TBI"
      ? NavOrExternalLink
      : "div";

  return (
    <Root
      className={`group ${className}`}
      onClick={Root === "div" ? undefined : onClick}
      to={`/experts/${expert.slug}`}
      prefetch="intent"
      data-content-type="expertCard"
      data-module-title={expert.slug}
    >
      {assertExpert(expert) &&
      expert.image?.fields &&
      expert.type === "Current TBI" ? (
        <ForcedAspectRatio className="mb-6 overflow-hidden" ratio={[3, 4]}>
          <ContentfulCenteredImage
            className="h-full duration-1000 lg:group-hover:scale-105"
            image={expert.image.fields ?? ""}
          />
        </ForcedAspectRatio>
      ) : (
        <ForcedAspectRatio
          className={`h3 mb-6 overflow-hidden ${imageBackgroundColor} align-center flex-center flex`}
          ratio={[3, 4]}
        >
          {assertExpert(expert)
            ? expert.firstName?.trim()[0]
            : assertSimpleExpert(expert) && expert.name.split(" ")[0][0]}
          {assertExpert(expert)
            ? expert.lastName?.trim()[0]
            : assertSimpleExpert(expert) && expert.name.split(" ")[1][0]}
        </ForcedAspectRatio>
      )}
      <h5 className="mb-3">
        {assertExpert(expert)
          ? `${expert.firstName} ${expert.lastName}`
          : assertSimpleExpert(expert) && expert.name}
      </h5>
      {assertExpert(expert) && expert.role && (
        <p className="meta lg:meta-large text-secondary_text">{expert.role}</p>
      )}
      {assertExpert(expert) && expert.type === "guest" && (
        <span className="meta lg:meta-large mt-3 block w-fit rounded-[6px] bg-black p-2 text-white lg:mt-4">
          guest author
        </span>
      )}
      {assertExpert(expert) && expert.type === "alumni" && (
        <span className="meta lg:meta-large mt-3 block w-fit rounded-[6px] border border-black bg-white p-2 text-black lg:mt-4">
          institute alumni
        </span>
      )}
      {assertExpert(expert) && !expert.type && (
        <span className="meta lg:meta-large mt-3 block w-fit rounded-[6px] border border-black bg-white p-2 text-black lg:mt-4">
          institute alumni
        </span>
      )}
    </Root>
  );
}
