import { Children, cloneElement, isValidElement, ReactNode } from "react";
import { useNewsletter } from "~/contexts/NewsletterProvider";

type NewsletterButtonProps = {
  children: ReactNode;
};

export default function NewsletterButton({ children }: NewsletterButtonProps) {
  const { openModal } = useNewsletter();

  const renderChildren = () => {
    const childrenWithProps = Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, { onClick: openModal });
      }
      return child;
    });

    return <>{childrenWithProps}</>;
  };

  return <>{renderChildren()}</>;
}
