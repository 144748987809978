import { IExpertFields } from "~/@types/generated/contentful";
import { ClientSide } from "~/@types";
import { getAllEntriesOfType } from "~/contentful/index.server";

export const getActiveExperts = async (locale: string) => {
  return (
    await getAllEntriesOfType({
      content_type: "expert",
      locale,
      otherParams: {
        order: "fields.lastName",
      },
    })
  ).map(({ fields }) => fields) as ClientSide<IExpertFields>[];
};
