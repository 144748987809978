import { useLocation } from "@remix-run/react";
import { useState } from "react";
import { useEffectOnce } from "react-use";
import { off, on } from "./misc";

const getValue = (search: string) => {
  const urlParams = new URLSearchParams(search);
  return Object.fromEntries(
    [...new Set(Array.from(urlParams.keys()))].map((k) => [
      k,
      urlParams.getAll(k),
    ])
  );
};

export type UseQueryParam = () => { [k: string]: string[] };

export const useSearchParams: UseQueryParam = () => {
  const remixLocation = useLocation();
  let location: any = remixLocation;
  if (typeof window !== "undefined") {
    location = window.location;
  }
  const [value, setValue] = useState<{ [k: string]: string[] }>(() =>
    getValue(location.search)
  );

  useEffectOnce(() => {
    const onChange = () => {
      setValue(getValue(location.search));
    };

    on(window, "popstate", onChange);
    on(window, "pushstate", onChange);
    on(window, "replacestate", onChange);

    return () => {
      off(window, "popstate", onChange);
      off(window, "pushstate", onChange);
      off(window, "replacestate", onChange);
    };
  });

  return value;
};
