import { RefObject, useCallback, useEffect, useState } from "react";
import { useEvent, useIntersection, useWindowSize } from "react-use";

export const useSingleImageParallax = ({
  intersectionRef,
  stickyRef,
  scrollRef,
}: {
  intersectionRef: RefObject<HTMLDivElement>;
  stickyRef: RefObject<HTMLDivElement>;
  scrollRef: RefObject<HTMLDivElement>;
}) => {
  const [mounted, setIsMounted] = useState(false);
  const windowSize = useWindowSize();
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  });

  const handleScroll = useCallback(() => {
    if (intersection?.isIntersecting || !mounted) {
      if (stickyRef.current && scrollRef.current) {
        const imageBox = stickyRef.current.getBoundingClientRect();
        const textBox = scrollRef.current.getBoundingClientRect();

        if (imageBox.top >= 0 && textBox.top > windowSize.height) {
          stickyRef.current.style.position = "absolute";
          stickyRef.current.style.top = "0px";
        } else if (imageBox.top <= 0 && textBox.top >= 0) {
          stickyRef.current.style.position = "fixed";
          stickyRef.current.style.top = "0px";
        } else if (imageBox.top >= 0 && textBox.top >= 0) {
          stickyRef.current.style.position = "fixed";
          stickyRef.current.style.top = "0px";
        } else if (textBox.top <= 0) {
          stickyRef.current.style.position = "absolute";
          stickyRef.current.style.top = "100vh";
        }
      }
    }
  }, [intersection?.isIntersecting, stickyRef, scrollRef, windowSize, mounted]);

  useEffect(() => {
    if (!mounted) {
      handleScroll();
      setIsMounted(true);
    }
  }, [mounted, handleScroll]);
  useEvent("scroll", handleScroll);
};
