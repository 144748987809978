import { AnimatePresence, motion, useAnimationControls } from "framer-motion";
import { ReactNode } from "react";
import { useEffectOnce } from "react-use";
import FlutterText from "./flutterText";

function ChildStuff({ children }: { children: ReactNode }) {
  const bg = useAnimationControls();
  const childWrap = useAnimationControls();

  const sequence = async () => {
    await bg.start({
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      WebkitClipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      display: "flex",
      transition: {
        clipPath: {
          duration: 0.4,
          ease: "easeInOut",
        },
        WebkitClipPath: {
          duration: 0.4,
          ease: "easeInOut",
        },
        display: {
          duration: 0,
          delay: 0,
        },
      },
    });

    await childWrap.start({
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      WebkitClipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      opacity: 1,
      transition: {
        clipPath: {
          duration: 0.4,
          ease: "easeInOut",
          delay: 0.2,
        },
        WebkitClipPath: {
          duration: 0.4,
          ease: "easeInOut",
          delay: 0.2,
        },
        display: {
          duration: 0,
          delay: 0,
        },
      },
    });

    return await bg.start({
      clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
      WebkitClipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
      display: "none",
      transition: {
        clipPath: {
          duration: 0.4,
          ease: "easeInOut",
        },
        WebkitClipPath: {
          duration: 0.4,
          ease: "easeInOut",
        },
        display: {
          duration: 0,
          delay: 0,
        },
      },
    });
  };

  useEffectOnce(() => {
    sequence();
  });

  return (
    <>
      <motion.div
        className="fixed inset-0 z-[9999] flex h-screen w-screen items-center justify-center bg-black text-white"
        initial={{
          clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
          WebkitClipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
        }}
        animate={bg}
      >
        <FlutterText
          className="aeonik text-14 uppercase"
          text="Tony Blair Institute for Global Change"
        />
      </motion.div>
      <motion.div
        key="child_wrap"
        className="fixed inset-0 z-[9999] flex h-screen w-screen items-center justify-center "
        initial={{
          opacity: 0,
          clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
          WebkitClipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
        }}
        animate={childWrap}
        exit={{
          clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
          WebkitClipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
          transition: { duration: 0.4, ease: "easeInOut" },
        }}
      >
        {children}
      </motion.div>
    </>
  );
}

export default function SimpleSwipeUp({
  children,
  open,
}: {
  children: ReactNode;
  open: boolean;
}) {
  return (
    <AnimatePresence mode="wait">
      {open && <ChildStuff>{children}</ChildStuff>}
    </AnimatePresence>
  );
}
