import { ReactNode } from "react";

export interface ForcedAspectRatioProps {
  className?: string;
  children: ReactNode;
  ratio?: [number, number];
  [k: string]: any;
}

export default function ForcedAspectRatio({
  className = "",
  children,
  ratio = [3, 4],
  ...otherProps
}: ForcedAspectRatioProps) {
  return (
    <div
      className={`${className}`}
      style={{
        aspectRatio: `${ratio[0]}/${ratio[1]}`,
      }}
      {...otherProps}
    >
      {children}
    </div>
  );
}
