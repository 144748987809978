import { ElementType } from "react";
import {
  IHtmlStringFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";

// Code shamelessly stolen: https://github.com/h0tw4t3r/react-soundcloud-embedded
export type SoundCloudEmbeddedProps = {
  width?: string;
  height?: string;
  fields: SpecificLocaleFields<IHtmlStringFields>;
  autoPlay?: boolean;
  hideRelated?: boolean;
  showComments?: boolean;
  showUser?: boolean;
  showReposts?: boolean;
  visual?: boolean;
  color?: string;
};

const SoundCloudEmbedded: ElementType<SoundCloudEmbeddedProps> = ({
  fields,
  width = "100%",
  height = "450px",
  autoPlay = false,
  hideRelated = false,
  showComments = false,
  showUser = true,
  showReposts = false,
  visual = false,
  color = "ff5500",
}: SoundCloudEmbeddedProps) => {
  const src = visual
    ? `https://w.soundcloud.com/player/?url=${fields.html}&amp;auto_play=${autoPlay}&amp;hide_related=${hideRelated}&amp;show_comments=${showComments}&amp;show_user=${showUser}&amp;show_reposts=${showReposts}&amp;visual=${visual}`
    : `https://w.soundcloud.com/player/?url=${fields.html}&amp;color=${color}&amp;auto_play=${autoPlay}&amp;hide_related=${hideRelated}&amp;show_comments=${showComments}&amp;show_user=${showUser}&amp;show_reposts=${showReposts}`;

  return (
    <section className="article-columns py-20">
      <iframe
        title={fields.moduleTitle}
        width={width}
        height={visual ? height : "auto"}
        scrolling="no"
        src={src}
      />
    </section>
  );
};

export default SoundCloudEmbedded;
