import clsx from "clsx";
import { HashLink } from "react-router-hash-link";
import {
  InsightComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import slugify from "slugify";
import { IChapterHeading, SpecificLocale } from "~/@types/generated/contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import FlutterText from "../animations/flutterText";

type ChapterHeadingProps = {
  sysId: string;
  chapter: SpecificLocale<IChapterHeading>;
};

/**
 * Article Chapter Block
 */
export default function ChapterHeading({
  sysId,
  chapter,
}: ChapterHeadingProps) {
  const { chapters } = useComponentData() as InsightComponentData;
  const ctxChapter = chapters?.[sysId];
  const chapterHeading = chapter.fields.heading ?? "";

  return (
    <HashLink
      data-content-type="chapterHeading"
      data-module-title={chapter.fields.moduleTitle}
      to={`#${slugify(chapterHeading, {
        lower: true,
        strict: true,
        remove: new RegExp("^[0-9]*"),
      })}`}
      id={slugify(chapterHeading, {
        lower: true,
        strict: true,
        remove: new RegExp("^[0-9]*"),
      })}
      className={clsx(
        "global-grid relative col-span-full block scroll-mt-10 py-10",
        {
          "bg-slate": ctxChapter?.number === 1,
        }
      )}
    >
      <div className="col-span-full mx-auto flex w-full flex-col items-start justify-center lg:col-start-2 lg:col-end-12">
        <hr className="mb-10 w-full bg-dark_grey" />
      </div>
      <div className="col-span-full mx-auto flex w-full flex-col items-start lg:col-start-2 lg:col-end-4">
        {ctxChapter?.number ? (
          <p className="eyebrows mb-6">Chapter {ctxChapter.number}</p>
        ) : null}
      </div>
      <FlutterText
        className="h2 col-span-full w-full lg:col-span-8"
        text={chapterHeading}
      />
      {chapter.fields.subtitle && (
        <div className="col-span-full w-full lg:col-start-4 lg:col-end-9">
          {documentToReactComponents(
            chapter.fields.subtitle,
            getComponentFromContentfulRichTextEntry
          )}
        </div>
      )}
      {ctxChapter?.number === 1 && (
        <span className="absolute -left-[100vw] -right-[100vw] -top-10 -bottom-10 -z-10 bg-slate"></span>
      )}
    </HashLink>
  );
}
