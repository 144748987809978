import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useEffect, useRef, useState } from "react";
import {
  IDualParallaxFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { ContentfulCenteredImage } from "~/components/shared";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";

type TextInBackTwoImagesProps = {
  fields: SpecificLocaleFields<IDualParallaxFields>;
};
export default function TextInBackTwoImages({
  fields,
}: TextInBackTwoImagesProps) {
  const [imageScrollBoostPx1, setImageScrollBoostPx1] = useState(0);
  const [imageScrollBoostPx2, setImageScrollBoostPx2] = useState(0);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (textRef.current) {
        setImageScrollBoostPx1(
          textRef.current.getBoundingClientRect().top * 1.25 +
            window.innerHeight / 2
        );
        setImageScrollBoostPx2(
          textRef.current.getBoundingClientRect().top + window.innerHeight / 2
        );
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section
      data-content-type="dualParallax"
      data-module-title={fields.moduleTitle}
      className="lg:min-h-900 global-container my-20 h-[70vh] overflow-hidden"
    >
      <div className="flex h-0 items-center justify-center">
        <div
          className="top-44 z-20 h-[200px] w-[150px] md:h-[280px] md:w-[210px]"
          style={{
            transform: `translateX(4vw) translateY(${imageScrollBoostPx1}px)`,
          }}
        >
          {fields.images && fields.images.length > 0 && fields.images[0] && (
            <ContentfulCenteredImage
              image={fields.images[0].fields}
              className="h-full w-full pb-6"
            />
          )}
        </div>

        <div
          className="top-44 z-10 h-[240px] w-[180px] md:h-[400px] md:w-[300px]"
          style={{ transform: `translateY(${imageScrollBoostPx2}px)` }}
        >
          {fields.images && fields.images.length >= 1 && fields.images[1] && (
            <ContentfulCenteredImage
              image={fields.images[1].fields}
              className="h-full w-full pb-6"
            />
          )}
        </div>
      </div>
      <div className="flex h-full items-center justify-center">
        {fields.body && (
          <div
            ref={textRef}
            className="[&>*]:ppe text-center [&>*]:block [&>*]:text-100 [&>*]:leading-[120%] [&>*]:md:text-160 [&>*]:lg:text-250"
          >
            {documentToReactComponents(
              fields.body,
              getComponentFromContentfulRichTextEntry
            )}
          </div>
        )}
      </div>
    </section>
  );
}
