import { SVGProps } from "react";
const SvgFacebookBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="m15.47 13 .41-2.89h-2.59V8.24c0-.79.36-1.56 1.52-1.56h1.18V4.22s-1.07-.2-2.09-.2c-2.13 0-3.52 1.38-3.52 3.89v2.2H8.01V13h2.37v6.98h2.92V13h2.17Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFacebookBlack;
