import { SVGProps } from "react";
const SvgInstagramBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M11.999 5.47c2.13 0 2.38 0 3.22.05.78.04 1.2.17 1.48.27.35.13.66.33.92.6.26.26.47.57.6.92.11.28.24.7.27 1.48.04.84.05 1.09.05 3.22s0 2.38-.05 3.22c-.04.78-.17 1.2-.27 1.48a2.664 2.664 0 0 1-1.52 1.52c-.28.11-.7.24-1.48.27-.84.04-1.09.05-3.22.05s-2.38 0-3.22-.05c-.78-.04-1.2-.17-1.48-.27-.35-.13-.66-.33-.92-.6-.26-.26-.47-.57-.6-.92-.11-.28-.24-.7-.27-1.48-.04-.84-.05-1.09-.05-3.22s0-2.38.05-3.22c.04-.78.17-1.2.27-1.48.13-.35.33-.66.6-.92.26-.26.57-.47.92-.6.28-.11.7-.24 1.48-.27.84-.04 1.09-.05 3.22-.05Zm0-1.43c-2.16 0-2.43 0-3.28.05-.85.04-1.43.17-1.93.37-.53.2-1.01.51-1.41.92-.41.4-.72.88-.92 1.41-.2.51-.33 1.09-.37 1.93-.04.85-.05 1.12-.05 3.28 0 2.16 0 2.43.05 3.28.04.85.17 1.43.37 1.93.2.53.51 1.01.92 1.41.4.41.88.72 1.41.92.51.2 1.09.33 1.93.37.85.04 1.12.05 3.28.05 2.16 0 2.43 0 3.28-.05.85-.04 1.43-.17 1.93-.37.53-.2 1.01-.52 1.41-.92.4-.4.71-.88.92-1.41.2-.51.33-1.09.37-1.93.04-.85.05-1.12.05-3.28 0-2.16 0-2.43-.05-3.28-.04-.85-.17-1.43-.37-1.93-.2-.53-.51-1.01-.92-1.41-.4-.41-.88-.72-1.41-.92-.51-.2-1.09-.33-1.93-.37-.85-.04-1.12-.05-3.28-.05Z"
      fill="currentColor"
    />
    <path
      d="M11.999 7.908c-.81 0-1.6.24-2.27.69-.67.45-1.2 1.09-1.51 1.84-.31.75-.39 1.57-.23 2.36.16.79.55 1.52 1.12 2.09.57.57 1.3.96 2.09 1.12.79.16 1.62.08 2.36-.23.75-.31 1.39-.83 1.84-1.51.45-.67.69-1.46.69-2.27 0-.54-.11-1.07-.31-1.57-.21-.5-.51-.95-.89-1.33-.38-.38-.83-.68-1.33-.89-.5-.21-1.03-.31-1.57-.31l.01.01Zm0 6.75c-.53 0-1.04-.16-1.48-.45a2.646 2.646 0 0 1-.4-4.08c.37-.37.84-.62 1.36-.73.52-.1 1.05-.05 1.53.15.49.2.9.54 1.19.98.29.44.45.95.45 1.48a2.665 2.665 0 0 1-2.66 2.66l.01-.01ZM16.249 8.71a.96.96 0 1 0 0-1.921.96.96 0 0 0 0 1.92Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgInstagramBlack;
