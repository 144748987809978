import { SVGProps } from "react";
const SvgFilterBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M19.735 8.375h-8.7m-5.08 0h-1.69"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M8.615 6.195c-.29 0-.57.06-.83.17-.26.11-.5.27-.71.47-.21.2-.36.44-.47.71-.11.26-.17.55-.17.83 0 .28.06.57.17.83.11.26.27.5.47.71.2.21.44.36.71.47.26.11.55.17.83.17.58 0 1.13-.23 1.54-.64a2.172 2.172 0 0 0 0-3.08c-.41-.41-.96-.64-1.54-.64Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M4.265 15.625h8.46m4.83 0h2.18"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M15.385 13.445c-.58 0-1.13.23-1.54.64a2.172 2.172 0 0 0 0 3.08 2.172 2.172 0 0 0 3.08 0 2.172 2.172 0 0 0 0-3.08c-.41-.41-.96-.64-1.54-.64Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);
export default SvgFilterBlack;
