import { TwitterTweetEmbed } from "react-twitter-embed";
import {
  ITwitterEmbedFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";

type TwitterEmbedProps = {
  fields: SpecificLocaleFields<ITwitterEmbedFields>;
  beforeSecondChapter?: boolean;
};

export default function TwitterEmbed({
  fields,
  beforeSecondChapter = false,
}: TwitterEmbedProps) {
  // TODO: Handle the old style of twitter embed
  if (!fields.tweetId) return null;

  return (
    <div
      data-content-type="twitter-embed"
      data-module-title={fields.moduleTitle}
      className="global-grid relative col-span-full py-10 lg:py-20"
    >
      <div className="col-span-full lg:col-span-4 lg:col-start-5">
        <TwitterTweetEmbed tweetId={fields.tweetId} />
      </div>
      {beforeSecondChapter && (
        <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
      )}
    </div>
  );
}
