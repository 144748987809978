import { Arrow } from "../icons";
import { CircleIconCta, RoundedCta } from "../ctas";
import {
  IThreeUpRoutingFields,
  IThreeUpRouteFields,
  SpecificLocaleFields,
  SpecificLocale,
  DefaultLocalizedField,
  IRoundedCtaButton,
} from "~/@types/generated/contentful";
import {
  CenteredImageAspectSwitchOnView,
  CenteredImageZoomInOnView,
} from "../shared";
import FlutterText from "../animations/flutterText";
import clsx from "clsx";
import { useCallback, useRef, useState } from "react";
import { useEffectOnce, useEvent } from "react-use";
import { Entry } from "contentful";
import NavOrExternalLink from "../shared/NavOrExternalLink";

export type ThreeUpRoutingProps = {
  fields: SpecificLocaleFields<IThreeUpRoutingFields> & {
    cta?: DefaultLocalizedField<IRoundedCtaButton> | undefined;
  };
  cta?: boolean;
};

export default function ThreeUpRouting({ fields, cta }: ThreeUpRoutingProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [desktopImageHeight, _setDesktopImageHeight] = useState("75%");

  const setDesktopImageHeight = useCallback(() => {
    if (containerRef.current) {
      const refWidth = containerRef.current.getBoundingClientRect().width;
      // calculate the height for a 3/4 aspect ratio
      const oneColumnWidth = refWidth / 3 - 12;
      const ratio = [3, 4];
      const desktopHeight = `${(oneColumnWidth / ratio[0]) * ratio[1]}px`;
      _setDesktopImageHeight(desktopHeight);
    }
  }, [containerRef]);

  useEffectOnce(() => {
    setDesktopImageHeight();
  });

  useEvent("resize", setDesktopImageHeight);

  const [hoverIx, setHoverIx] = useState<null | number>(null);

  const routeContent = (
    route: SpecificLocale<
      Entry<
        IThreeUpRouteFields & {
          cta?: DefaultLocalizedField<IRoundedCtaButton> | undefined;
        }
      >
    >,
    index: number,
    btn?: boolean
  ) => (
    <>
      {route.fields.url && (
        <div className="absolute top-10 right-10 z-10 opacity-0 transition-opacity duration-700 group-hover/item:opacity-100 group-hover/item:delay-500 lgmax:hidden">
          <CircleIconCta
            label={route.fields.heading}
            icon={<Arrow />}
            kind={"white"}
            as="div"
          />
        </div>
      )}
      {route.fields.image && (
        <CenteredImageAspectSwitchOnView
          once
          className="mb-6 h-full w-full lg:hidden"
          image={route.fields.image.fields}
        />
      )}
      {route.fields.image && (
        <CenteredImageZoomInOnView
          initialScale={1}
          inViewScale={1.1}
          className="mb-6 w-full overflow-hidden lgmax:hidden"
          image={route.fields.image.fields}
          style={{
            height: desktopImageHeight,
          }}
        />
      )}

      {route.fields.url ? (
        <NavOrExternalLink
          to={`/${route.fields.url}`}
          prefetch="intent"
          className="before:absolute before:inset-0 before:content-['']"
        >
          {route.fields.heading && (
            <h4
              className={clsx(
                "mb-2 whitespace-normal transition-opacity delay-300 duration-700",
                `${
                  hoverIx !== null
                    ? hoverIx === index
                      ? "lg:opacity-100"
                      : "lg:opacity-0"
                    : "lg:opacity-100"
                }`
              )}
            >
              <FlutterText text={route.fields.heading} />
            </h4>
          )}
        </NavOrExternalLink>
      ) : (
        <h4
          className={clsx(
            "mb-2 whitespace-normal transition-opacity delay-300 duration-700",
            `${
              hoverIx !== null
                ? hoverIx === index
                  ? "lg:opacity-100"
                  : "lg:opacity-0"
                : "lg:opacity-100"
            }`
          )}
        >
          {route.fields.heading && <FlutterText text={route.fields.heading} />}
        </h4>
      )}
      <p
        className={clsx(
          "body-large transition-opacity delay-300 duration-700 lg:max-h-24 lg:overflow-y-hidden",
          {
            "lg:opacity-100": hoverIx === index,
            "lg:opacity-0": hoverIx !== index,
          }
        )}
      >
        {route.fields.body}
      </p>
      {btn && route.fields.cta && (
        <RoundedCta
          className="mt-6"
          to={route.fields.cta.fields.buttonUrl}
          label={route.fields.cta.fields.buttonText}
          kind={route.fields.cta.fields.type}
          icon={<Arrow />}
          custom={route.fields.cta.fields.colorPalette?.fields}
        />
      )}
    </>
  );

  return (
    <section
      data-content-type="threeUpRouting"
      data-module-title={fields.moduleTitle}
      className={clsx("w-screen", {
        "bg-white": fields.backgroundColor !== "slate",
        "bg-slate": fields.backgroundColor === "slate",
      })}
      style={{
        backgroundColor: fields.colorPalette?.fields.backgroundColor,
        color: fields.colorPalette?.fields.fontColor,
      }}
    >
      <div className="global-container">
        <div
          className="flex flex-col py-10 lg:flex-row lg:py-16"
          ref={containerRef}
        >
          {fields.routes?.map((route, index) => {
            if (!route.fields.url) {
              return (
                <div
                  data-content-type="threeUpRoute"
                  data-module-title={route.fields.moduleTitle}
                  key={route.sys.id}
                  onMouseEnter={() => setHoverIx(index)}
                  onMouseLeave={() => setHoverIx(null)}
                  className={clsx(
                    "group/item relative mb-20 flex-col lg:mb-10 lg:transition-[width] lg:duration-700 lgmax:w-full",
                    {
                      "lg:ml-6": index !== 0,
                      "lg:w-[calc(33%-12px)]": hoverIx === null,
                      "lg:w-1/6": hoverIx !== null && hoverIx !== index,
                      "lg:w-2/3": hoverIx === index,
                    }
                  )}
                >
                  {routeContent(route, index, cta)}
                </div>
              );
            }

            return (
              <div
                data-content-type="threeUpRoute"
                data-module-title={route.fields.moduleTitle}
                key={route.sys.id}
                onMouseEnter={() => setHoverIx(index)}
                onMouseLeave={() => setHoverIx(null)}
                className={clsx(
                  "group/item relative mb-20 flex-col lg:mb-10 lg:transition-[width] lg:duration-700 lgmax:w-full",
                  {
                    "lg:ml-6": index !== 0,
                    "lg:w-[calc(33%-12px)]": hoverIx === null,
                    "lg:w-1/6": hoverIx !== null && hoverIx !== index,
                    "lg:w-2/3": hoverIx === index,
                  }
                )}
              >
                {routeContent(route, index, cta)}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
