import clsx from "clsx";
import { MouseEvent } from "react";
import NodeIcon from "./NodeIcon";
import { CtaIconButtonProps, EventElementTypes } from "../types";
import NavOrExternalLink from "../shared/NavOrExternalLink";

const IconCtaKinds = {
  black: "black",
  white: "white",
};

type IconCtaKind = "black" | "white";

export interface IconCtaProps extends CtaIconButtonProps {
  kind: IconCtaKind;
}

export default function IconCta({
  label,
  icon,
  kind,
  disabled,
  to,
  onClick,
  className = "",
  iconClassName = "",
  as = "button",
  id,
  dataAttrs,
  navLinkProps,
}: IconCtaProps) {
  const btnClassName = clsx({
    cta: true,
    "p-3": true,
    "w-min": true,
    "h-min": true,
    "rounded-full": true,
    "outline-none": true,
    "hover:underline": !disabled,
    flex: true,
    "justify-center": true,
    "items-center": true,
    "hover:bg-black": [IconCtaKinds.black].includes(kind) && !disabled,
    "active:bg-black": [IconCtaKinds.black].includes(kind) && !disabled,
    "hover:bg-white": [IconCtaKinds.white].includes(kind) && !disabled,
    "active:bg-white": [IconCtaKinds.white].includes(kind) && !disabled,
    "text-black": [IconCtaKinds.black].includes(kind) && !disabled,
    "hover:text-white": [IconCtaKinds.black].includes(kind) && !disabled,
    "active:text-white": [IconCtaKinds.black].includes(kind) && !disabled,
    "text-white": [IconCtaKinds.white].includes(kind) && !disabled,
    "hover:text-black": [IconCtaKinds.white].includes(kind) && !disabled,
    "active:text-black": [IconCtaKinds.white].includes(kind) && !disabled,
    "focus:bg-dark_grey": [IconCtaKinds.black].includes(kind) && !disabled,
    "focus:bg-charcoal": [IconCtaKinds.white].includes(kind) && !disabled,
    "active:text-charcoal": [IconCtaKinds.black].includes(kind) && !disabled,
    "active:text-dark_grey": [IconCtaKinds.white].includes(kind) && !disabled,
    "text-secondary_text": disabled,
  });

  const handleClick = (e: MouseEvent<EventElementTypes>) => {
    if (onClick && !disabled) {
      onClick(e);
    }
  };

  if (to && !disabled) {
    return (
      <NavOrExternalLink
        id={id}
        to={to}
        className={`${btnClassName} ${className}`}
        onClick={handleClick}
        title={label}
        {...navLinkProps}
        {...dataAttrs}
      >
        <NodeIcon aria-label={label} className={iconClassName}>
          {icon}
        </NodeIcon>
      </NavOrExternalLink>
    );
  }

  const As = as;

  return (
    <As
      id={id}
      disabled={disabled}
      className={`${btnClassName} ${className}`}
      onClick={handleClick}
      title={label}
      {...dataAttrs}
    >
      <NodeIcon aria-label={label} className={iconClassName}>
        {icon}
      </NodeIcon>
    </As>
  );
}
