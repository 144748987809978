import { Plus, X } from "~/components/icons";
import { useEffect, useRef, useState } from "react";
import {
  ContentfulCenteredImage,
  ForcedAspectRatio,
} from "~/components/shared";
import CircleIconCta from "~/components/ctas/CircleIconCta";
import { ICaseStudy, SpecificLocale } from "~/@types/generated/contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import {
  InsightComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import ImageSlowScroll from "../shared/ImageSlowScroll";
import { AnimatePresence, motion } from "framer-motion";
import FlutterText from "../animations/flutterText";
import clsx from "clsx";
import { useMedia, useWindowSize } from "react-use";

type CaseStudyProps = {
  caseStudy: SpecificLocale<ICaseStudy>;
};

// TODO: Add margins above all the insights ones
// Check the shared ones...

export default function CaseStudy({ caseStudy }: CaseStudyProps) {
  const { insight } = useComponentData() as InsightComponentData;
  const [isShowing, setIsShowing] = useState(false);
  const isLarge = useMedia("(min-width: 1024px)");
  const isXLarge = useMedia("(min-width: 1440px)");
  const { width, height } = useWindowSize();
  const headingRef = useRef<HTMLDivElement>(null);
  const aspectRef = useRef<HTMLDivElement>(null);
  const [textBlockHeight, setTextBlockHeight] = useState<number | null>(null);
  const [headingHeight, setHeadingHeight] = useState<number | null>(null);
  const caseStudyBody = useRef<HTMLDivElement>(null);
  const showButton =
    caseStudyBody.current &&
    textBlockHeight &&
    caseStudyBody.current?.offsetHeight > textBlockHeight;

  useEffect(() => {
    if (headingRef.current) {
      setHeadingHeight(headingRef.current.offsetHeight + 40);
    }
    if (aspectRef.current) {
      if (isLarge && headingRef.current) {
        setTextBlockHeight(
          aspectRef.current.offsetHeight - headingRef.current.offsetHeight
        );
      } else if (isXLarge && headingRef.current) {
        setTextBlockHeight(
          aspectRef.current.offsetHeight - headingRef.current.offsetHeight + 10
        );
      } else {
        setTextBlockHeight(aspectRef.current.offsetHeight);
      }
    }
  }, [isXLarge, isLarge, width, height]);

  const handleShowing = () => {
    if (isShowing === true) {
      headingRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setIsShowing(!isShowing);
  };

  return (
    <div
      data-content-type="caseStudy"
      data-module-title={caseStudy.fields.moduleTitle}
      className="relative z-[1] col-span-full my-10 text-white"
    >
      <span className="inline-block" />
      <div className="global-grid relative py-20">
        {caseStudy.fields.eyebrow && (
          <p className="eyebrows col-span-full mb-6 lg:col-span-2 lg:col-start-2">
            {caseStudy.fields.eyebrow}
          </p>
        )}
        {caseStudy.fields.heading && (
          <div ref={headingRef} className="z-50 col-span-full lg:col-span-6">
            <FlutterText
              className="h3 col-span-full mb-10 lg:col-span-6"
              text={caseStudy.fields.heading}
            />
          </div>
        )}
        <div className="global-grid absolute top-0 left-0 h-full w-full py-20">
          <div
            ref={aspectRef}
            className="grid-col relative col-[1_/_5] aspect-3/4 lg:col-[9_/_13] "
            style={{
              ...(headingHeight && !isLarge
                ? { marginTop: `${headingHeight}px` }
                : {}),
            }}
          >
            {caseStudy.fields.image ? (
              <ForcedAspectRatio
                className="col-span-4 lgmax:relative lgmax:left-[calc(75%+20px)]"
                // ratio={[3, 4]}
              >
                <ImageSlowScroll className="h-full w-full overflow-hidden lg:absolute lg:top-0 lg:left-[50%]">
                  <ContentfulCenteredImage
                    className="h-full"
                    image={caseStudy.fields.image?.fields}
                  />
                </ImageSlowScroll>
              </ForcedAspectRatio>
            ) : (
              <div className="col-span-4 aspect-3/4 lgmax:relative lgmax:left-3/4" />
            )}
          </div>
        </div>
        <AnimatePresence>
          <motion.div
            initial={{
              ...(headingHeight ? { maxHeight: `${textBlockHeight}px` } : {}),
            }}
            animate={{ maxHeight: "10000px" }}
            exit={{
              ...(headingHeight ? { maxHeight: `${textBlockHeight}px` } : {}),
            }}
            transition={{ duration: 0.7, ease: "easeInOut" }}
            className={clsx(
              isShowing ? "overflow-visible" : "overflow-hidden",
              "z-50",
              caseStudy.fields.image
                ? "col-[1_/_4] lg:col-[4_/_10]"
                : "col-[1_/_5] lg:col-[4_/_10]"
            )}
          >
            <div
              className={clsx("grid")}
              style={{
                height: isShowing
                  ? "auto"
                  : `${textBlockHeight ? `${textBlockHeight}px` : "auto"}`,
              }}
            >
              <div
                ref={caseStudyBody}
                className={clsx(
                  "case-study-body col-span-full [&>:first-child]:mt-0",
                  isShowing ? "mb-[4rem]" : ""
                )}
              >
                {caseStudy.fields.body && (
                  <>
                    {documentToReactComponents(
                      caseStudy.fields.body,
                      getComponentFromContentfulRichTextEntry
                    )}
                  </>
                )}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
        {showButton && (
          <div
            className={clsx(
              "absolute bottom-[80px] left-0 right-0 z-[100] flex justify-center"
            )}
          >
            <CircleIconCta
              className="mb-[-2rem] 2xl:mb-0"
              label={"case study expand"}
              kind={"white"}
              onClick={handleShowing}
              icon={isShowing ? <X /> : <Plus />}
            />
          </div>
        )}
      </div>
      <span
        className={clsx(
          "absolute -left-[100vw] -right-[100vw] top-0 -bottom-10 -z-10",
          !showButton && "bottom-[4.5rem] md:-bottom-10"
        )}
        style={{ backgroundColor: insight.fields.topic?.fields.color }}
      />
    </div>
  );
}
