import { SVGProps } from "react";
const SvgTopicBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M17.8 12H6.2M15.57 16.361H8.44M19.75 7.64H4.25"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgTopicBlack;
