import { ReactNode } from "react";
import {
  InsightComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";

type BlockQuoteProps = {
  children: ReactNode;
};

export default function BlockQuote({ children }: BlockQuoteProps) {
  const { insight } = useComponentData() as InsightComponentData;

  return (
    <div className="h2 global-grid relative col-span-full flex justify-center py-20 pb-10">
      <span className="article-columns inline-block indent-20 text-white">
        <span
          style={{ backgroundColor: insight?.fields.topic?.fields.color }}
          className={`[&>*]:h2 [&>*]:inline [&>p>span]:inline ${
            insight ? "" : " bg-charcoal"
          }`}
        >
          &ldquo;&nbsp;
          {children}
          &nbsp;&rdquo;
        </span>
      </span>
      <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
    </div>
  );
}
