import clsx from "clsx";
import { forwardRef, MouseEvent } from "react";
import NodeIcon from "./NodeIcon";
import { CtaIconButtonProps, EventElementTypes } from "../types";
import NavOrExternalLink from "../shared/NavOrExternalLink";
import {
  ICtaColorwayFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";

const RCtaKinds = {
  black: "black",
  hollow: "hollow",
  white: "white",
  whiteHollow: "white-hollow",
};

type RCtaKind = "black" | "white" | "hollow" | "white-hollow";

export interface RoundedCtaProps extends CtaIconButtonProps {
  kind: RCtaKind;
  custom?: SpecificLocaleFields<ICtaColorwayFields> | undefined;
}

export default forwardRef<HTMLElement, RoundedCtaProps>(function RoundedCta(
  {
    label,
    kind,
    icon,
    disabled,
    to,
    onClick,
    className = "",
    iconClassName = "",
    as = "button",
    id,
    dataAttrs,
    navLinkProps,
    custom,
  },
  ref
) {
  const btnClassName = clsx({
    group: true,
    "rounded-[100px]": true,
    flex: true,
    "flex-row": true,
    "items-center": true,
    "justify-center": true,
    "transition-all": true,
    "duration-75": true,
    "py-[12px]": true,
    "gap-[16px]": true,
    "w-max": true,
    "h-[40px]": true,
    "lg:h-[48px]": true,
    uppercase: true,
    body: true,
    cta: true,
    aeonik: true,
    "whitespace-nowrap": true,
    "outline-none": !custom && true,
    "pointer-events-none": disabled,
    // Assuming a link would never be disabled
    "hover:underline": !disabled,
    "active:underline": !disabled,
    "bg-black": RCtaKinds.black === kind,
    "bg-transparent": [RCtaKinds.hollow, RCtaKinds.whiteHollow].includes(kind),
    "bg-white": RCtaKinds.white === kind,
    "text-white": [RCtaKinds.black, RCtaKinds.whiteHollow].includes(kind),
    "text-black": [RCtaKinds.hollow, RCtaKinds.white].includes(kind),
    border: [RCtaKinds.hollow, RCtaKinds.whiteHollow].includes(kind),
    "border-solid": [RCtaKinds.hollow, RCtaKinds.whiteHollow].includes(kind),
    "border-white": RCtaKinds.whiteHollow === kind,
    "border-black": RCtaKinds.hollow === kind,
    "focus:bg-charcoal": [RCtaKinds.whiteHollow, RCtaKinds.black].includes(
      kind
    ),
    "focus:bg-slate": [RCtaKinds.hollow, RCtaKinds.white].includes(kind),
    "active:bg-charcoal": [RCtaKinds.whiteHollow, RCtaKinds.black].includes(
      kind
    ),
    "active:bg-slate": [RCtaKinds.hollow, RCtaKinds.white].includes(kind),
    "disabled:text-secondary_text": [
      RCtaKinds.whiteHollow,
      RCtaKinds.hollow,
    ].includes(kind),
    "disabled:border-secondary_text": [
      RCtaKinds.whiteHollow,
      RCtaKinds.hollow,
    ].includes(kind),
    "bg-secondary_text":
      [RCtaKinds.black, RCtaKinds.white].includes(kind) && disabled,
    "aria-disabled:bg-secondary_text disabled:bg-secondary_text": [
      RCtaKinds.black,
      RCtaKinds.white,
    ].includes(kind),
  });

  const handleClick = (e: MouseEvent<EventElementTypes>) => {
    if (onClick && !disabled) {
      onClick(e);
    }
  };

  const getChildren = () => (
    <>
      {icon ? (
        <>
          <NodeIcon
            aria-hidden="true"
            className={clsx(
              iconClassName,
              "ease-in-out -translate-x-4 opacity-0 transition-all duration-500 group-hover:translate-x-4 group-hover:opacity-100"
            )}
          >
            {icon}
          </NodeIcon>
          <span className="-translate-x-4 transition-all duration-500 group-hover:translate-x-4">
            {label}
          </span>
          <NodeIcon
            aria-hidden="true"
            className={clsx(
              iconClassName,
              "ease-in-out -translate-x-4 opacity-100 transition-all duration-500 group-hover:translate-x-4 group-hover:opacity-0"
            )}
          >
            {icon}
          </NodeIcon>
        </>
      ) : (
        <>{label}</>
      )}
    </>
  );

  if (to && !disabled) {
    return (
      <NavOrExternalLink
        id={id}
        ref={ref as any}
        aria-disabled={disabled}
        to={to}
        className={`${btnClassName} ${className}`}
        onClick={handleClick}
        title={label}
        {...navLinkProps}
        {...dataAttrs}
        style={{
          backgroundColor: custom?.fillColor,
          color: custom?.fontColor,
          border: custom?.outlineColor && `1px solid ${custom?.outlineColor}`,
        }}
      >
        {getChildren()}
      </NavOrExternalLink>
    );
  }

  const As = as;

  return (
    <As
      id={id}
      ref={ref as any}
      disabled={disabled}
      className={`${btnClassName} ${className}`}
      onClick={handleClick}
      title={label}
      {...dataAttrs}
    >
      {getChildren()}
    </As>
  );
});
