import { ClientSide } from "~/@types";
import { IImageFields } from "~/@types/generated/contentful";
import { motion } from "framer-motion";
import { getBackgroundImageStyles } from "../utils/getBackgroundImageStyles";

export interface CenteredImageZoomInOnViewProps {
  image: ClientSide<IImageFields>;
  className?: string;
  bg?: "fade" | "gradient" | "grey";
  once?: boolean;
  style?: any;
  [k: string]: any;
  initialScale?: number;
  inViewScale?: number;
}

export default function CenteredImageZoomInOnView({
  className = "",
  image,
  bg,
  once = false,
  style = {},
  initialScale = 0.8,
  inViewScale = 1,
  ...otherProps
}: CenteredImageZoomInOnViewProps) {
  return (
    <div className={`${className} overflow-hidden`}>
      <motion.div
        transition={{
          duration: 1,
          ease: "easeInOut",
        }}
        initial={{
          scale: initialScale,
        }}
        whileInView={{ scale: inViewScale }}
        viewport={{ once, amount: "some" }}
        className="h-full w-full"
        {...otherProps}
        style={{
          ...getBackgroundImageStyles(image, bg),
          ...style,
        }}
      >
        {!image && "No image found"}
      </motion.div>
    </div>
  );
}
