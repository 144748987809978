import { SVGProps } from "react";
const SvgYouTubeBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M20.154 8.34a1.9 1.9 0 0 0-.55-.85 2.06 2.06 0 0 0-.95-.49c-1.33-.32-6.65-.32-6.65-.32s-5.32 0-6.65.32c-.36.09-.69.26-.95.49-.26.24-.45.53-.55.85-.36 1.18-.36 3.66-.36 3.66s0 2.47.36 3.66c.1.32.29.62.55.85.26.24.59.41.95.49 1.33.32 6.65.32 6.65.32s5.32 0 6.65-.32c.36-.09.69-.26.95-.49.26-.24.45-.53.55-.85.35-1.18.35-3.66.35-3.66s0-2.47-.35-3.66Zm-9.86 5.93V9.72l4.42 2.28-4.42 2.28v-.01Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgYouTubeBlack;
