import {
  ITagFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import NavOrExternalLink from "../shared/NavOrExternalLink";

type TagsProps = {
  tags?: SpecificLocaleFields<ITagFields>[];
};

export default function Tags({ tags }: TagsProps) {
  return (
    <div className="my-10 max-w-content-max px-mobile-gutter lg:my-16 lg:px-desktop-gutter">
      <p className="eyebrows mb-6">Article Tags</p>
      <hr className="bg-black" />
      {tags?.length ? (
        <div className="mb-6 flex flex-wrap lg:mb-10">
          {tags.map((tag) => (
            <NavOrExternalLink
              data-content-type="tag"
              data-module-title={tag.slug}
              to={`/tags/${tag.slug}`}
              prefetch="intent"
              className="meta mt-6 mr-4 rounded-[6px] border border-black py-2 px-4 hover:underline lg:mt-10 lg:py-3 lg:px-5"
              key={tag.slug}
            >
              {tag.title}
            </NavOrExternalLink>
          ))}
        </div>
      ) : (
        <div className="mb-6 flex flex-wrap lg:mb-10">
          <p className="meta mt-6">No Tags Found</p>
        </div>
      )}
    </div>
  );
}
