import InsightsHeroComponent from "./InsightsHero";
import clsx from "clsx";
import {
  IInsightsHeroFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";

type InsightsListingHeroProps = {
  fields: SpecificLocaleFields<IInsightsHeroFields>;
};

export default function InsightsListingHero({
  fields,
}: InsightsListingHeroProps) {
  return (
    <>
      <div
        data-content-type="insightsHero"
        data-module-title={fields.moduleTitle}
        className={clsx([
          "flex",
          "min-h-hero-min",
          "h-[calc(100vh-80px)] lg:h-[calc(100vh-96px)]",
          "w-full",
          "flex-col",
          "items-center",
          "justify-center",
          "px-mobile-gutter",
          "relative",
          "lg:px-desktop-gutter",
          "bg-black",
          "text-white",
          "z-10",
        ])}
      >
        <div className="flex flex-col items-center justify-center">
          <InsightsHeroComponent fields={fields} />
        </div>
      </div>
    </>
  );
}
