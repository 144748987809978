// Configuration variables that are public and remain the same between environments are stored here
// all other configuration variables are environment variables in /.envrc (or look at /.envrc.example initially)

export const ALGOLIA_APP_ID = "BN78AKZAPO";
export const ALGOLIA_INDICES = {
  insights: "insights",
  insightsDateDesc: "insights_date_desc",
  insightsSuggestions: "insights_query_suggestions",
  experts: "experts",
  pages: "pages",
} as const;

export type AlgoliaIndexName =
  typeof ALGOLIA_INDICES[keyof typeof ALGOLIA_INDICES];

export const ALGOLIA_FACETS = {
  insights: {
    topic: "topic.title",
    region: "regions",
    year: "year",
    type: "type",
  },
} as const;
export type AlgoliaInsightFacet =
  typeof ALGOLIA_FACETS["insights"][keyof typeof ALGOLIA_FACETS["insights"]];

export const ALGOLIA_SEARCH_KEY = "b13c6f28963a17c22b1c42eb3438e1c2";
export const GLOBAL_CACHE_SECONDS = 300;

export function getEnv(property: string): string;
export function getEnv<T>(
  property: string,
  options: { default: T }
): string | T;
export function getEnv(
  property: string,
  options?: { default: string | null }
): string | null {
  const value = process.env[property];
  if (!value && options) return options.default;
  if (!value)
    throw new Error(
      `no environment variable found for "${property}" and no default provided`
    );
  return value;
}

export const INSIGHT_CARD_GROUP_SIZE = 12;
