import { ClientSide } from "~/@types";
import { IImageFields } from "~/@types/generated/contentful";
import { getFrontifyImageURL } from "./getFrontifyImage";

export const getBackgroundImageStyles = (
  image: ClientSide<IImageFields>,
  bg?: string
) => {
  const getBackgroundImage = () => {
    const urls = `url(${
      image?.frontifyImage
        ? getFrontifyImageURL(image)
        : `${image?.image?.fields?.file?.url}?fm=webp`
    }), url("https://tbi-assets.s3.eu-west-2.amazonaws.com/all-images/placeholder.png")`;

    const singleUrl = `url(${
      image?.frontifyImage
        ? getFrontifyImageURL(image)
        : `${image?.image?.fields?.file?.url}?fm=webp`
    })`;

    switch (bg) {
      case "fade":
        return `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${urls}`;
      case "gradient":
        return `linear-gradient(0deg, #000000 11.39%, transparent 110.35%), ${urls}`;
      case "grey":
        return `linear-gradient(to right, #00000080, #00000080), ${urls}`;
      case "transparent":
        return `${singleUrl}`;
      default:
        return urls;
    }
  };

  const x = image?.x ?? 0.5;
  const y = image?.y ?? 0.5;

  return {
    backgroundImage: getBackgroundImage(),
    backgroundPosition: `${Number(x) * 100}% ${Number(y) * 100}%`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
};
