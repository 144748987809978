import clsx from "clsx";
import { ClientSide } from "~/@types";
import { IExpert } from "~/@types/generated/contentful";
import {
  Arrow,
  Email,
  Facebook,
  Linkedin,
  Twitter,
  YouTube,
} from "../icons/svgs";

export type ExpertSocialLinksProps = {
  className?: string;
  expert: ClientSide<IExpert>;
  invertedColors?: boolean;
};

export default function ExpertSocialLinks({
  className = "",
  expert,
  invertedColors,
}: ExpertSocialLinksProps) {
  return (
    <div
      id="expert-social-links"
      className={`col-span-full flex gap-8 ${className}`}
    >
      {expert.fields.email && (
        <div className="flex items-center gap-4">
          <span className="cta shrink-0">contact</span>
          <a
            href={`mailto:${expert.fields.email}`}
            className={clsx("rounded", {
              "bg-black text-white": !invertedColors,
              "bg-white text-black": invertedColors,
            })}
          >
            <Email />
          </a>
        </div>
      )}
      {expert.fields.socialMedia && (
        <div className="flex items-center gap-4">
          <span className="cta shrink-0">follow</span>
          <span className="flex flex-wrap gap-2">
            {expert.fields.socialMedia.map((link, index) => {
              switch (link.fields.platform) {
                case "twitter":
                  return (
                    <a
                      key={link.sys.id}
                      href={link.fields.url}
                      target="_blank"
                      rel="noreferrer"
                      className={clsx("rounded", {
                        "bg-black text-white": !invertedColors,
                        "bg-white text-black": invertedColors,
                      })}
                    >
                      <Twitter />
                    </a>
                  );
                case "linkedin":
                  return (
                    <a
                      key={link.sys.id}
                      href={link.fields.url}
                      target="_blank"
                      rel="noreferrer"
                      className={clsx("rounded", {
                        "bg-black text-white": !invertedColors,
                        "bg-white text-black": invertedColors,
                      })}
                    >
                      <Linkedin />
                    </a>
                  );
                case "youtube":
                  return (
                    <a
                      key={link.sys.id}
                      href={link.fields.url}
                      target="_blank"
                      rel="noreferrer"
                      className={clsx("rounded", {
                        "bg-black text-white": !invertedColors,
                        "bg-white text-black": invertedColors,
                      })}
                    >
                      <YouTube />
                    </a>
                  );
                case "facebook":
                  return (
                    <a
                      key={link.sys.id}
                      href={link.fields.url}
                      target="_blank"
                      rel="noreferrer"
                      className={clsx("rounded", {
                        "bg-black text-white": !invertedColors,
                        "bg-white text-black": invertedColors,
                      })}
                    >
                      <Facebook />
                    </a>
                  );
              }
              return (
                <a
                  key={link.sys.id}
                  href={link.fields.url}
                  target="_blank"
                  rel="noreferrer"
                  className={clsx("rounded", {
                    "bg-black text-white": !invertedColors,
                    "bg-white text-black": invertedColors,
                  })}
                >
                  <Arrow />
                </a>
              );
            })}
          </span>
        </div>
      )}
    </div>
  );
}
