import { Block, Inline, Text } from "@contentful/rich-text-types";
import QueryString from "qs";
import { createContext, ReactNode, useContext } from "react";
import { Params } from "react-router-dom";
import { ClientSide } from "~/@types";
import {
  IExpertFields,
  IExpertsCarouselFields,
  IExpertsListFields,
  IFootnoteFields,
  IInsight,
  IInsightsListFields,
  IPage,
  ITopic,
  SpecificLocale,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { INSIGHT_CARD_GROUP_SIZE } from "~/config";
import { getInsights, getMostRecentInsight } from "../contentful/index.server";
import {
  findAllFigures,
  findAllFootnotes,
  findCaseStudies,
  findChapters,
} from "./dataUtils/findInsightComponentData";
import { getActiveExperts } from "./dataUtils/getActiveExperts";
import { getTopicsWithTotals } from "./dataUtils/getTopicsWithTotals";
import { isLastBlockEmptyParagraph } from "./dataUtils/isLastBlockEmptyParagraph";

export interface IComponentDataTopic extends ClientSide<ITopic> {
  total: number;
}

export interface PageComponentData {
  page?: ClientSide<IPage>;
  topics?: IComponentDataTopic[];
  experts?: ClientSide<IExpertFields>[];
  expertsList?: {
    [k: string]: ClientSide<IExpertFields>[];
  };
  expertsCarousel?: {
    [k: string]: ClientSide<IExpertFields>[];
  };
  insightsList?: {
    [k: string]: ClientSide<IInsight>[];
  };
  totalInsights?: number;
  featuredInsight?: {
    [k: string]: ClientSide<IInsight>;
  };
  // any other entry types that need extra data go here
}

export interface Chapters {
  [k: string]: {
    number: number;
    heading: string;
  };
}

export interface CaseStudies {
  [k: string]: {
    number: number;
  };
}

export interface Figures {
  [k: string]: {
    number: number;
  };
}

export interface Footnote {
  id: string;
  footnote: ClientSide<IFootnoteFields>;
}

export interface InsightComponentData {
  insight: ClientSide<IInsight>;
  chapters?: Chapters;
  caseStudies?: CaseStudies;
  figures?: Figures;
  footnotes?: Footnote[];
  featuredInsight?: {
    [k: string]: SpecificLocale<IInsight>;
  };
}

export const ComponentDataContext = createContext<
  PageComponentData | InsightComponentData | null
>(null);

export type ComponentDataProviderProps = {
  componentData: Partial<PageComponentData | InsightComponentData>;
  children: ReactNode;
};

export const ComponentDataProvider = ({
  componentData,
  children,
}: ComponentDataProviderProps) => {
  return (
    <ComponentDataContext.Provider value={componentData as PageComponentData}>
      {children}
    </ComponentDataContext.Provider>
  );
};

export function useComponentData(): PageComponentData | InsightComponentData {
  const componentData = useContext(ComponentDataContext);
  if (!componentData) {
    throw new Error(
      "useComponentData was called without a ComponentDataContext"
    );
  }
  return componentData;
}

export const assertBlock = (x: Block | Inline | Text): x is Block => {
  return x.hasOwnProperty("content");
};

/**
 * Some components need additional data. Here we can fetch it depending on the entry type
 * @param params - Contentful entry, locale, and request params
 */
export async function getInsightComponentData({
  insight,
  locale,
  params,
}: {
  insight: SpecificLocale<IInsight>;
  locale: string;
  params: Params<string>;
}): Promise<InsightComponentData> {
  let componentData: InsightComponentData = {
    insight,
  };

  const bodyContent = insight.fields.body?.content;

  if (bodyContent?.length) {
    componentData.chapters = findChapters(bodyContent);
    componentData.caseStudies = findCaseStudies(bodyContent);
    componentData.figures = findAllFigures(bodyContent);
    componentData.footnotes = findAllFootnotes(bodyContent);

    // ensure last block isn't an empty paragraph
    if (isLastBlockEmptyParagraph(bodyContent)) {
      bodyContent.pop();
    }

    // ensure all content in the first chapter is marked as such
    if (Object.keys(componentData.chapters).length > 0) {
      let chapterHeadingsFound = 0;
      for (let i = 0; i < bodyContent.length; i++) {
        if (!bodyContent[i]) {
          break;
        }
        bodyContent[i].data.beforeSecondChapter = true;
        if (
          bodyContent[i]?.nodeType === "embedded-entry-block" &&
          bodyContent[i]?.data?.target?.sys?.contentType?.sys?.id ===
            "chapterHeading"
        ) {
          chapterHeadingsFound++;
        }
        if (chapterHeadingsFound > 1) {
          break;
        }
      }
    }

    const featuredInsights = bodyContent
      .filter(
        (entry) =>
          entry.data.target?.sys?.contentType?.sys.id === "featuredInsight"
      )
      .map((entry) => {
        return entry.data.target;
      });

    // If there is a featuredInsight component, don't fetch that insight in the list
    if (featuredInsights.length) {
      await Promise.all(
        featuredInsights.map(async (featuredInsight) => {
          if (!componentData.featuredInsight) {
            componentData.featuredInsight = {};
          }

          if (featuredInsight?.fields.insight?.sys.id) {
            componentData.featuredInsight[featuredInsight.sys.id] =
              featuredInsight.fields.insight;
          } else {
            const mostRecentInsight = await getMostRecentInsight(locale, {
              topicSlug: params.topicSlug,
            });
            if (mostRecentInsight) {
              componentData.featuredInsight[featuredInsight.sys.id] =
                mostRecentInsight;
            }
            return mostRecentInsight;
          }
        })
      );
    }
  }
  return componentData;
}

/**
 * Some components need additional data. Here we can fetch it depending on the entry type
 * @param params - Contentful entry, locale, and request params
 */
export async function getPageComponentData({
  page,
  locale,
  params,
  request,
}: {
  page: SpecificLocale<IPage>;
  locale: string;
  params: Params<string>;
  request: Request;
}): Promise<PageComponentData> {
  let componentData: PageComponentData = {
    page,
  };

  componentData.topics = await getTopicsWithTotals(locale);

  const bodyContent = page.fields.body?.content;

  if (
    bodyContent?.some((entry) =>
      ["expertsList", "expertsCarousel", "expertsHero"].includes(
        entry.data.target?.sys.contentType.sys.id
      )
    )
  ) {
    componentData.experts = await getActiveExperts(locale);
  }

  if (bodyContent?.length) {
    if (isLastBlockEmptyParagraph(bodyContent)) {
      bodyContent.pop();
    }
    const featuredInsights = bodyContent
      .filter(
        (entry) =>
          entry.data.target?.sys.contentType.sys.id === "featuredInsight"
      )
      .map((entry) => {
        return entry.data.target;
      });
    // If there is a featuredInsight component, don't fetch that insight in the list
    if (featuredInsights.length) {
      await Promise.all(
        featuredInsights.map(async (featuredInsight) => {
          if (!componentData.featuredInsight) {
            componentData.featuredInsight = {};
          }

          if (featuredInsight?.fields.insight?.sys.id) {
            componentData.featuredInsight[featuredInsight.sys.id] =
              featuredInsight.fields.insight;
          } else {
            const mostRecentInsight = (await getMostRecentInsight(locale, {
              topicSlug: params.topicSlug,
            })) as ClientSide<IInsight>;
            componentData.featuredInsight[featuredInsight.sys.id] =
              mostRecentInsight;
            return mostRecentInsight;
          }
        })
      );
    }
    await Promise.all(
      bodyContent.map(async (entry) => {
        switch (
          entry.data.target?.sys.contentType.sys.id as keyof PageComponentData
        ) {
          case "expertsList":
            // TODO: We may not need to fetch all the experts here
            const expertsListFields = entry.data.target
              .fields as SpecificLocaleFields<IExpertsListFields>;
            if (expertsListFields.experts) {
              if (!componentData.expertsList) {
                componentData.expertsList = {};
              }

              componentData.expertsList[entry.data.target.sys.id] =
                expertsListFields.experts.map(({ fields }) => fields);
            }
            break;
          case "expertsCarousel":
            // TODO: We may not need to fetch all the experts here
            const expertsCarouselFields = entry.data.target
              .fields as SpecificLocaleFields<IExpertsCarouselFields>;
            if (expertsCarouselFields.experts) {
              if (!componentData.expertsCarousel) {
                componentData.expertsCarousel = {};
              }

              componentData.expertsCarousel[entry.data.target.sys.id] =
                expertsCarouselFields.experts.map(({ fields }) => fields);
            }
            break;
          case "featuredInsight":
            // This happens above because we need to pull out all the featuredInsights before mapping through the other content
            // So just don't do anything here, but leaving this block for documentation purposes
            break;
          case "insightsList":
            const insightsListFields = entry.data?.target
              .fields as SpecificLocaleFields<IInsightsListFields>;
            let limit: number | undefined =
              insightsListFields.numberOfCards || INSIGHT_CARD_GROUP_SIZE;
            if (!insightsListFields.hideShowMoreButton) {
              const url = new URL(request.url);
              const searchParams = QueryString.parse(url.search.slice(1));
              const page = searchParams.page ? Number(searchParams.page) : 1;
              limit = page * INSIGHT_CARD_GROUP_SIZE;
            }
            if (!componentData.insightsList) {
              componentData.insightsList = {};
            }

            const topicsToInclude = insightsListFields.topics
              ?.map((topic) => topic.fields.slug)
              .toString();
            const expertsToInclude =
              insightsListFields.experts?.map((expert) => expert.fields.slug) ||
              [];
            const tagsToInclude =
              insightsListFields.tags?.map((tag) => tag.fields.slug) || [];

            const featureInsightToExclude = Object.values(
              componentData.featuredInsight ?? {}
            )[0]?.fields?.slug;
            const slugsToExclude =
              insightsListFields.excludeInsights?.map(
                (excludedInsight) => excludedInsight.fields?.slug
              ) || [];

            if (featureInsightToExclude) {
              slugsToExclude?.push(featureInsightToExclude);
            }

            const insightsCollection = await getInsights(locale, {
              topicSlug: topicsToInclude,
              tagSlugs: tagsToInclude,
              expertSlugs: expertsToInclude,
              skip: insightsListFields.skip,
              limit,
              excludeSlug: slugsToExclude?.toString(),
              select: [
                "sys.id",
                "fields.title",
                "fields.slug",
                "fields.image",
                "fields.experts",
                "fields.topic",
                "fields.tags",
                "fields.date",
                "fields.embargoed",
              ],
            });

            const items = insightsCollection.items as ClientSide<IInsight>[];
            componentData.insightsList[entry.data.target.sys.id] = items;
            componentData.totalInsights = insightsCollection.total;
            break;
          default:
            break;
        }
        return entry;
      })
    );
  }

  return componentData;
}
