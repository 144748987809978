import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import { ClientSide } from "~/@types";
import {
  IExpert,
  IExpertFiftyFiftyFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";

import { ExpertBioTakeover } from "../experts";
import { ContentfulCenteredImage } from "../shared";
import ImageSlowScroll from "../shared/ImageSlowScroll";

type ExpertFiftyFiftyProps = {
  fields: SpecificLocaleFields<IExpertFiftyFiftyFields>;
};

// And set up slate and white bgs
export default function ExpertFiftyFifty({ fields }: ExpertFiftyFiftyProps) {
  const renderImage = () => (
    <div
      className={clsx(
        " col-span-full h-min lg:col-span-5 lg:flex lg:items-center lgmax:mb-10",
        {
          "mb-10 lg:col-start-2": !fields.inverted,
          "lg:col-end-12": fields.inverted,
        }
      )}
    >
      {fields.image && (
        <ImageSlowScroll>
          <ContentfulCenteredImage
            className="aspect h-full w-full lg:aspect-[3/4] lgmax:aspect-square"
            image={fields.image.fields}
          />
        </ImageSlowScroll>
      )}
    </div>
  );

  const renderText = () => (
    <div
      className={clsx("col-span-full lg:col-span-4 lg:flex lg:items-center", {
        "mb-10 lg:col-start-2": fields.inverted,
        "lg:col-end-12": !fields.inverted,
      })}
    >
      <div>
        <h2
          className={clsx("h4 col-span-full lg:col-span-6", {
            "mb-2": fields.subheading,
            "mb-6": !fields.subheading,
          })}
        >
          {fields.heading}
        </h2>
        <h2 className="h4 col-span-full lg:col-span-6 lg:mb-10">
          {fields.subheading}
        </h2>
        {fields.body && (
          <div className="[&_p]:body pb-10 [&_p]:lgmax:mt-6">
            {documentToReactComponents(
              fields.body,
              getComponentFromContentfulRichTextEntry
            )}
          </div>
        )}
        {fields.expert && (
          <ExpertBioTakeover
            expert={fields.expert as ClientSide<IExpert>}
            label={fields.buttonText}
          />
        )}
      </div>
    </div>
  );

  return (
    <section
      data-content-type="50-50"
      data-module-title={fields.moduleTitle}
      className={clsx("w-screen", {
        "text-white": ["black"].includes(fields.backgroundColor ?? "white"),
        "text-black": ["white", "slate"].includes(
          fields.backgroundColor ?? "white"
        ),
        "bg-black": fields.backgroundColor === "black",
        "bg-white": fields.backgroundColor === "white",
        "bg-slate": fields.backgroundColor === "slate",
      })}
    >
      <div
        className={clsx(
          "global-container global-grid w-full py-16 lg:py-20 lgmax:flex",
          {
            "lgmax:flex-col-reverse": fields.inverted,
            "lgmax:flex-col": !fields.inverted,
          }
        )}
      >
        {fields.inverted ? renderText() : renderImage()}
        {fields.inverted ? renderImage() : renderText()}
      </div>
    </section>
  );
}
