import { AlgoliaInsight, ClientSide, InsightGetters } from "./@types";
import { IExpert, IInsight, ITag } from "./@types/generated/contentful";

export function assertUnreachable(x: never): never {
  throw new Error(`expected never to reach this case, but here we are: ${x}`);
}

export function getCommonExpertObject(e: ClientSide<IExpert>) {
  return {
    name: `${e?.fields?.firstName ?? ""} ${e?.fields?.lastName ?? ""}`,
    slug: e?.fields?.slug ?? "",
  };
}

export function getTagObject(tag: ClientSide<ITag>) {
  return {
    title: tag?.fields?.title ?? "",
    slug: tag?.fields?.slug ?? "",
  };
}

export function contentfulInsightGetters(
  insight: ClientSide<IInsight>
): InsightGetters {
  return {
    getId: () => insight.sys.id,
    getTitle: () => insight.fields.title,
    getSlug: () => insight.fields.slug,
    getImageFields: () => insight.fields.image?.fields,
    getExperts: () => insight.fields.experts?.map(getCommonExpertObject),
    getTopic: () => ({
      title: insight.fields.topic.fields
        ? insight.fields.topic.fields.title
        : "",
      slug: insight.fields.topic.fields ? insight.fields.topic.fields.slug : "",
      color: insight.fields.topic.fields
        ? insight.fields.topic.fields.color
        : "",
    }),
    getTags: () => insight.fields.tags?.map(getTagObject),
    getDate: () => insight.fields.date,
    original: () => insight,
  };
}

export function algoliaInsightGetters(insight: AlgoliaInsight): InsightGetters {
  return {
    getId: () => insight.contentfulId,
    getTitle: () => insight.title,
    getSlug: () => insight.slug,
    getImageFields: () => insight.image,
    getExperts: () => insight.experts,
    getTopic: () => insight.topic,
    getTags: () => insight.tags,
    getDate: () => insight.date,
    original: () => insight,
  };
}
