import { SVGProps } from "react";
const SvgEmailBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <g
      clipPath="url(#Email_black_svg__a)"
      stroke="currentColor"
      strokeWidth={1.5}
    >
      <path
        d="m20.6 5.811-8.53 4.09s-.11.04-.15 0l-8.66-4.12"
        strokeLinecap="round"
      />
      <path
        d="M20.48 4.8H3.52a.5.5 0 0 0-.5.5v13.4a.5.5 0 0 0 .5.5h16.96a.5.5 0 0 0 .5-.5V5.3a.5.5 0 0 0-.5-.5Z"
        strokeMiterlimit={10}
      />
    </g>
    <defs>
      <clipPath id="Email_black_svg__a">
        <path
          fill="#fff"
          transform="translate(2.27 4.05)"
          d="M0 0h19.46v15.9H0z"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEmailBlack;
