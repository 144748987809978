import { motion, useScroll, useTransform } from "framer-motion";
import { ReactNode, useRef } from "react";
import { useRefScrollProgress } from "../utils/useRefScrollProgress";

type ScaleUpOnScrollProps = {
  children?: ReactNode;
  style?: {
    [k: string]: any;
  };
  [k: string]: any;
};

export default function ScaleUpOnScroll({
  children,
  style,
  ...otherProps
}: ScaleUpOnScrollProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { start, end } = useRefScrollProgress(ref);
  const { scrollYProgress } = useScroll();

  const scale = useTransform(scrollYProgress, [start, end], [1, 1.5]);

  return (
    <motion.div
      ref={ref}
      style={{
        scale,
        ...style,
      }}
      {...otherProps}
    >
      {children && children}
    </motion.div>
  );
}
