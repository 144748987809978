import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  IExpertsHeroFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";

export type ExpertsHeroProps = {
  fields: SpecificLocaleFields<IExpertsHeroFields>;
};

export default function ExpertsHero({ fields }: ExpertsHeroProps) {
  return (
    <section
      data-content-type="expertsHero"
      data-module-title={fields.moduleTitle}
      className="tbi-grid w-full pt-10 lg:pt-20"
    >
      <div className="col-span-4 mb-24 lg:col-span-7 lg:mb-32">
        <div className="col-span-4 lg:hidden">
          <h1 className="mb-6 whitespace-nowrap">{fields.heading}</h1>
          {fields.body && (
            <div className="experts-hero">
              {documentToReactComponents(
                fields.body,
                getComponentFromContentfulRichTextEntry
              )}
            </div>
          )}
        </div>
        <div className="col-span-4 hidden lg:mb-0 lg:block">
          <h1 className="mb-6 whitespace-nowrap">{fields.heading}</h1>
          {fields.body && (
            <div className="experts-hero">
              {documentToReactComponents(
                fields.body,
                getComponentFromContentfulRichTextEntry
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
