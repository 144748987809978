import clsx from "clsx";
import { MouseEvent, useEffect, useRef, useState } from "react";
import {
  IExpertsSectionsFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { Arrow } from "../icons";
import ExpertsList from "./ExpertsList";

type ExpertsSectionsProps = {
  fields: SpecificLocaleFields<IExpertsSectionsFields>;
  sysId?: string;
};

export default function ExpertsSections({
  fields,
  sysId,
}: ExpertsSectionsProps) {
  const [showRight, setShowRight] = useState(false);
  const [showLeft, setShowLeft] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);
  const initialOffsetTop = useRef(0);

  const handleAnchorClick = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
    id: string
  ) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  // sticky bar
  useEffect(() => {
    // Store the initial position when the component mounts
    if (navbarRef.current) {
      initialOffsetTop.current = navbarRef.current.offsetTop;
    }

    const handleScroll = () => {
      if (window.scrollY > initialOffsetTop.current) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const currentRef = ref.current;

    const handleScroll = () => {
      if (currentRef) {
        setShowLeft(currentRef.scrollLeft > 0);
        setShowRight(
          currentRef.scrollLeft <
            currentRef.scrollWidth - currentRef.clientWidth
        );
      }
    };

    const handleResize = () => {
      handleScroll(); // Check arrows visibility on resize
    };

    window.addEventListener("resize", handleResize);
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollTopicsRight = () => {
    if (ref.current) {
      ref.current.scroll({
        left: ref.current.scrollLeft + 200,
        behavior: "smooth",
      });
    }
  };

  const scrollTopicsLeft = () => {
    if (ref.current) {
      ref.current.scroll({
        left: ref.current.scrollLeft - 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <section
      className="mb-10 w-full lg:mb-20"
      data-content-type="expertsSections"
      data-module-title={fields.moduleTitle}
    >
      <div ref={navbarRef} className={isSticky ? "sticky" : ""}>
        <div
          className={`tbi-grid relative col-span-full bg-white py-4 lg:pb-[20px]`}
        >
          <Arrow
            className={clsx(
              "flex-center absolute top-[50%] right-6 z-10",
              showRight ? "opacity-100" : "opacity-0",
              `right-arrow ${showRight ? "visible" : "hidden"}`
            )}
            onClick={scrollTopicsRight}
          />
          <Arrow
            className={clsx(
              "flex-center absolute left-6 top-[50%] z-10 rotate-180",
              showLeft ? "opacity-100" : "opacity-0",
              `left-arrow ${showLeft ? "visible" : "hidden"}`
            )}
            onClick={scrollTopicsLeft}
          />
          {/* anchor links start */}
          <div
            className={clsx(
              "col-span-full max-w-content-max border-t border-solid border-black text-black",
              {
                "fade-out-gradient-right": showRight,
                "fade-out-gradient-left": showLeft,
                "fade-out-gradient-both": showLeft && showRight,
              }
            )}
          >
            <div className="no-scrollbar overflow-x-scroll" ref={ref}>
              <div className="flex w-max gap-6 lg:gap-8">
                {fields.expertSection?.map((expertList, index) => {
                  const id = expertList.fields.sectionTitle.replace(/\s/g, "");
                  return (
                    <div
                      key={index}
                      className="aeonik text-14 font-medium uppercase"
                      onClick={() => setSelectedIndex(index)}
                    >
                      <a
                        href={`#${id}`}
                        onClick={(e) => handleAnchorClick(e, id)}
                        className={clsx("block pt-6 hover:underline", {
                          underline: index === selectedIndex,
                        })}
                      >
                        {expertList.fields.sectionTitle}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* anchor links end */}
      {/* expert lists start */}
      {fields.expertSection?.map((section, index) => (
        <div
          id={section.fields.sectionTitle.replace(/\s/g, "")}
          key={index}
          className="scroll-mt-[78px]"
        >
          <ExpertsList fields={section.fields} sysId={String(section.sys.id)} />
        </div>
      ))}
      {/* expert lists end */}
    </section>
  );
}
