import { useParams } from "@remix-run/react";
import {
  PageComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import { TopicFiltersPopover, TopicTakeover } from "../heros/TopicNav";
import FloatingNav from "../shared/FloatingNav";

export default function FloatingListPageNav() {
  const { topicSlug } = useParams();
  const { topics } = useComponentData() as PageComponentData;

  const topic = topics?.find((t) => t.fields.slug === topicSlug);

  return (
    <FloatingNav>
      {topic && <TopicFiltersPopover topic={topic} />}
      <TopicTakeover />
    </FloatingNav>
  );
}
