import { SVGProps } from "react";
const SvgXBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M19 1L1 19M1 1L19 19"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
export default SvgXBlack;
