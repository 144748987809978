import { AlgoliaPage } from "~/@types";
import NavOrExternalLink from "../shared/NavOrExternalLink";

type SiteContentSearchCardProps = {
  page: AlgoliaPage;
  onClick?: () => void;
};

export default function SiteContentSearchCard({
  page,
  onClick,
}: SiteContentSearchCardProps) {
  let path = "/" + page.slug;
  if (page.slugPrefix) {
    path = "/" + page.slugPrefix + path;
  }
  return (
    <li
      data-content-type="siteContentSearchCard"
      data-module-title={page.title}
      className="border-t border-black py-6 last:border-b lg:py-10"
    >
      <NavOrExternalLink
        to={path}
        className="global-grid list-none"
        onClick={onClick}
      >
        <h3 className="col-span-full col-start-1 mb-4 lg:col-span-4 xl:col-span-3">
          {page.title}
        </h3>
        <p className="body-small col-span-4">{page.description}</p>
      </NavOrExternalLink>
    </li>
  );
}
