import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useEffect, useRef, useState } from "react";
import {
  ITableFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import { isLastBlockEmptyParagraph } from "~/contexts/dataUtils/isLastBlockEmptyParagraph";

type TableProps = {
  fields: SpecificLocaleFields<ITableFields>;
};

export default function Table({ fields }: TableProps) {
  if (
    fields.table &&
    fields.table.content &&
    isLastBlockEmptyParagraph(fields.table.content)
  ) {
    fields.table.content.pop();
  }

  const tableContainer = useRef<HTMLDivElement>(null);
  let [width, setWidth] = useState("0px");
  useEffect(() => {
    if (tableContainer.current) {
      let width = tableContainer.current.querySelector("table")?.offsetWidth;
      if (width) {
        setWidth(width + "px");
      }
    }
  }, []);

  return (
    <div
      data-content-type="table"
      data-module-title={fields.moduleTitle}
      className="relative col-span-full my-20"
    >
      <div className="global-grid">
        <div className="col-span-full">
          <div
            className="mx-auto lg:pl-5 lgmax:!w-[100%]"
            style={{ width: width }}
          >
            <h3 className="lg:w-[600px]">{fields.heading}</h3>
            {fields.body && (
              <div className="pb-6 lg:w-[600px] [&>:first-child]:mt-6 [&>:last-child]:mb-6">
                {documentToReactComponents(
                  fields.body,
                  getComponentFromContentfulRichTextEntry
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        ref={tableContainer}
        className="[&>:first-child]:pt-0 [&>:last-child]:pb-0"
      >
        {fields.table && (
          <>
            {documentToReactComponents(
              fields.table,
              getComponentFromContentfulRichTextEntry
            )}
          </>
        )}
      </div>
      <span className="absolute -left-[100vw] -right-[100vw] -top-20 -bottom-20 -z-10 bg-slate"></span>
    </div>
  );
}
