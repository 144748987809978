import clsx from "clsx";
import { motion, useAnimationControls } from "framer-motion";
import { useEffect } from "react";

type PopupTextProps = {
  text?: string;
  className?: string;
  textClassName?: string;
  delay?: number;
  [k: string]: any;
};

export default function PopupText({
  text = "Tony Blair Institute for Global Change",
  className = "",
  textClassName,
  delay = 0.3,
  ...otherProps
}: PopupTextProps) {
  const controls = useAnimationControls();

  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      y: "0%",
      transition: {
        delay: i * 0.2 + 0.5 + delay,
        ease: "easeOut",
        duration: 0.4,
      },
    }));
  }, [controls, delay]);

  return (
    <div
      className={clsx(
        className,
        "relative flex w-max overflow-hidden text-white"
      )}
      {...otherProps}
    >
      {text.split(" ").map((word, ix) => (
        <motion.div
          key={`${word}_${ix}`}
          custom={ix}
          animate={controls}
          initial={{ opacity: 0, y: "100%" }}
          className={clsx(textClassName, "eyebrows mr-1")}
        >
          {word}{" "}
        </motion.div>
      ))}
    </div>
  );
}
