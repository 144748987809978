import { Arrow } from "~/components/icons";
import { CloseCta, TextCta } from "../ctas";
import CircleIconCta from "~/components/ctas/CircleIconCta";
import useEmblaCarousel from "embla-carousel-react";
import { Document as ContentfulDocument } from "@contentful/rich-text-types";

import { useState, useEffect, useCallback } from "react";
import {
  InsightComponentData,
  PageComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import {
  IExpertFields,
  IExpertsCarouselFields,
} from "~/@types/generated/contentful";
import ExpertsCard from "./ExpertsCard";
import { ClientSide } from "~/@types";
import clsx from "clsx";
import { bgColorOverrides } from "../Insight/CardsGrid";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";

export type ExpertsCarouselProps = {
  className?: string;
  fields?: ClientSide<IExpertsCarouselFields>;
  sysId?: string;
  experts?: ClientSide<IExpertFields>[] | { name: string; slug: string }[];
  closeFunction?: () => void;
};

type DotButtonPropType = {
  selected: boolean;
  onClick: () => void;
  index: number;
};

export const DotButton: React.FC<DotButtonPropType> = (props) => {
  const { selected, onClick, index } = props;

  return (
    <button
      role="presentation"
      className={clsx(
        "relative flex h-10 w-full cursor-pointer items-center bg-transparent p-0 after:h-px after:w-full",
        {
          "after:rounded-l-sm": index === 0,
        },
        selected ? "after:bg-black" : "after:bg-dark_grey"
      )}
      type="button"
      onClick={onClick}
    >
      <span className="sr-only">progress bar</span>
    </button>
  );
};

export default function ExpertsCarousel({
  className = "",
  fields,
  sysId,
  experts,
  closeFunction,
}: ExpertsCarouselProps) {
  const { experts: contextExperts, expertsCarousel } =
    useComponentData() as PageComponentData;
  const { insight } = useComponentData() as InsightComponentData;

  let theseExperts =
    experts ??
    expertsCarousel?.[sysId as keyof PageComponentData["expertsCarousel"]] ??
    contextExperts;

  const [viewportRef, embla] = useEmblaCarousel({
    loop: false,
    skipSnaps: false,
    align: "start",
    containScroll: "trimSnaps",
  });

  const [, update] = useState(false);

  const scrollTo = useCallback(
    (index: number) => embla && embla.scrollTo(index),
    [embla]
  );

  useEffect(() => {
    if (!embla) return;
    embla.on("select", () => update((i) => !i));
  }, [embla]);

  return (
    <div
      data-content-type="expertsCarousel"
      data-module-title={fields?.moduleTitle}
      className={`${
        fields?.backgroundColor
          ? bgColorOverrides[fields?.backgroundColor]
          : "bg-slate"
      } experts-carousel overflow-hidden py-10 ${className}`}
      id={fields?.id}
    >
      <div className="tbi-grid mb-6 items-center">
        {!insight && (
          <>
            <h2 className="h3 col-span-4 col-start-1 mb-6 lg:col-end-7">
              {fields?.heading}
            </h2>
            {fields?.bodyText && (
              <div className="col-span-4 col-start-1 lg:col-end-8">
                {documentToReactComponents(
                  fields?.bodyText as ContentfulDocument,
                  getComponentFromContentfulRichTextEntry
                )}
              </div>
            )}
          </>
        )}
        {insight && (
          <h4 className="col-span-3 lg:col-span-11">
            {fields?.heading ?? "The Experts"}
          </h4>
        )}

        <div
          className={clsx(
            "col-span-1",
            !closeFunction && "col-span-4 lg:col-span-12"
          )}
        >
          {closeFunction && (
            <CloseCta
              className="float-right"
              kind={"black"}
              onClick={closeFunction}
            />
          )}
          {fields?.buttonText && fields?.buttonUrl && !closeFunction && (
            <TextCta
              className={"float-right mt-[29px] lg:mt-[26px]"}
              kind={"black"}
              label={fields?.buttonText}
              icon={<Arrow />}
              to={fields.buttonUrl}
            />
          )}
        </div>
      </div>
      <div className="tbi-tablet-grid">
        <div
          className="embla relative col-span-full -mr-4 overflow-hidden lg:overflow-visible"
          ref={viewportRef}
        >
          <div className="col-span-full flex select-none">
            {theseExperts?.length &&
              theseExperts.map((expert, i) => (
                <ExpertsCard
                  className="shrink-0 grow-0 basis-3/4 pr-4 sm:basis-1/4"
                  key={i}
                  expert={expert}
                />
              ))}
          </div>
        </div>
        <div
          className={clsx(
            "col-span-full flex justify-center pt-5",
            theseExperts && theseExperts?.length <= 4 && "sm:hidden"
          )}
        >
          {embla?.scrollSnapList().map((_, index) => (
            <DotButton
              key={index}
              index={index}
              selected={index === embla.selectedScrollSnap()}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
        <div
          className={clsx(
            "col-span-full mt-1 flex gap-4 lg:mt-5",
            theseExperts && theseExperts?.length <= 4 && "sm:hidden"
          )}
        >
          <div className="grow" />
          <CircleIconCta
            className={
              "expert-hover-effect rotate-180 hover:bg-black focus:bg-black disabled:hover:bg-transparent"
            }
            label={"previous carousel item"}
            kind={"hollow"}
            icon={<Arrow />}
            disabled={!embla?.canScrollPrev()}
            onClick={() => embla?.scrollPrev()}
          />
          <CircleIconCta
            label={"next carousel item"}
            className="expert-hover-effect hover:bg-black focus:bg-black disabled:hover:bg-transparent"
            kind={"hollow"}
            icon={<Arrow />}
            disabled={!embla?.canScrollNext()}
            onClick={() => embla?.scrollNext()}
          />
        </div>
      </div>
    </div>
  );
}
