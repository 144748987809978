import { SVGProps } from "react";
const SvgLinkedinBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M8.415 6.88c0 .78-.57 1.42-1.51 1.42-.88 0-1.45-.63-1.45-1.42 0-.8.58-1.41 1.49-1.41s1.45.61 1.47 1.41Zm-2.89 11.65V9.42h2.79v9.11h-2.79Zm4.47-6.21c0-1.14-.04-2.08-.07-2.91h2.43l.13 1.27h.06c.36-.6 1.27-1.47 2.78-1.47 1.84 0 3.22 1.25 3.22 3.93v5.39h-2.8v-5.06c0-1.17-.4-1.98-1.42-1.98-.77 0-1.23.54-1.43 1.06-.07.23-.1.47-.09.71v5.26h-2.79v-6.21l-.02.01Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLinkedinBlack;
