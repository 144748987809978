import { ImgHTMLAttributes } from "react";
import type { SpecificLocale, Asset } from "~/@types/generated/contentful";
import { SupportedLang } from "~/i18n-config";

/**
 * This function creates a srcSet in the format url?w={withForIteration}&q=80&fm=webp {widthForIteration}w for the given url and width.
 * The withForIteration it's calculated by multiplying the iteration number by the increment. For example, if the increment is 100, the first iteration will be 100, the second 200, etc.
 * @param url The url of the image
 * @param width The width of the image. This is used to calculate the number of iterations.
 * @param increment The increment to use for the srcSet. By default this is 500. This means that the srcSet will have 500w, 1000w, 1500w, etc.
 * @returns a string that can be used as a srcset attribute
 */
export function getSrcSet({
  width,
  url,
  increment = 500,
}: {
  width: number;
  url: string;
  increment?: number;
}) {
  let srcSetItems = [];
  for (let i = 1; i < width / increment; i++) {
    const width = i * increment;
    srcSetItems.push(`${url}?w=${width}&q=80&fm=webp ${width}w`);
  }
  return srcSetItems.join(",");
}

export function getImageProps(
  asset: SpecificLocale<Asset>
): ImgHTMLAttributes<HTMLImageElement>;
export function getImageProps(
  asset: Asset,
  options: { locale: SupportedLang["code"] }
): ImgHTMLAttributes<HTMLImageElement>;
export function getImageProps(
  asset: Asset | SpecificLocale<Asset>,
  options?: { locale: SupportedLang["code"] }
): unknown {
  /**
   * If you're looking to add translations, make sure to add the locale to
   * Contentful before you try to use it (see the README).
   * If you add a "supportedLang" before setting up contentful (and then regenerating types),
   * you'll see type errors in this file.
   */
  const locale = options?.locale;

  const file = locale
    ? (asset as Asset).fields.file[locale]
    : (asset as SpecificLocale<Asset>).fields.file;
  if (file && !file.details.image) {
    throw new Error(
      "tried to get image props from an asset that's not an image"
    );
  }

  return {
    src: file?.url,
    srcSet:
      file &&
      getSrcSet({
        width: file.details.image!.width,
        url: file.url,
      }),
    alt: locale
      ? (asset as Asset).fields.description[locale]
      : (asset as SpecificLocale<Asset>).fields.description,
  };
}

export interface ContentfulAssetProps {
  asset: SpecificLocale<Asset>;
  wrapperClassName?: string;
  assetClassName?: string;
  imageSizes?: Array<string>;
}

/**
 * @param asset A contentful asset.
 * @param wrapperClassName CSS class names to append to the image wrapper
 * @param assetClassName CSS class names to append to the image itself.
 * @param sizeQueries an array of CSS size strings to generate img attribute sizes.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img
 */
export default function ContentfulAsset({
  asset,
  wrapperClassName,
  assetClassName,
  imageSizes,
}: ContentfulAssetProps) {
  const isImage = asset.fields.file.contentType.startsWith("image");
  const isVideo = asset.fields.file.contentType.startsWith("video");
  const imagesSizeQueries = imageSizes?.join(", ");

  return (
    <>
      {isImage && (
        <div className={wrapperClassName}>
          <picture>
            <source
              className={assetClassName}
              type="image/webp"
              srcSet={getSrcSet({
                // We know this is an image, so it's safe to assume image is set
                width: asset.fields.file.details.image!.width,
                url: asset.fields?.file?.url,
              })}
              sizes={imagesSizeQueries}
            />
            {
              // This is safe to ignore because we know alt text is coming from getImageProps()
              // eslint-disable-next-line jsx-a11y/alt-text
              <img
                className={assetClassName}
                sizes={imagesSizeQueries}
                {...getImageProps(asset)}
              />
            }
          </picture>
        </div>
      )}
      {isVideo && (
        <div className={wrapperClassName}>
          <video
            className={assetClassName}
            src={asset.fields?.file?.url}
            controls
          />
        </div>
      )}
    </>
  );
}
