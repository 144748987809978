import React, { createContext, MouseEvent, useContext, useEffect } from "react";
import type { ReactNode } from "react";
import { NavigateOptions, parsePath, To, useNavigate } from "react-router-dom";
import { useAnimationControls } from "framer-motion";
import { useLocation, usePreviousDistinct } from "react-use";

export const PAGE_TRANSITION_DELAY = 800;

interface PageTransitionContextType {
  handleNavigate: (
    e: MouseEvent<HTMLAnchorElement>
  ) => (
    to: To,
    onClick?: ((e: MouseEvent<HTMLAnchorElement>) => void) | undefined,
    navigateOptions?: NavigateOptions | undefined
  ) => Promise<void>;
}

const PageTransitionContext = createContext<
  PageTransitionContextType | undefined
>(undefined);

function PageTransitionProvider({ children }: { children: ReactNode }) {
  const { pathname: currentPathname, search: currentSearch } = useLocation();
  const prevPath = usePreviousDistinct(currentPathname);
  const navigate = useNavigate();
  const controls = useAnimationControls();

  const handleNavigate: PageTransitionContextType["handleNavigate"] =
    (e) => async (to, onClick, navigateOptions) => {
      onClick && onClick(e);
      const pathObj = typeof to === "string" ? parsePath(to) : to;
      const toPathname = pathObj.pathname;
      const toSearch = pathObj.search;

      if (toPathname === currentPathname && toSearch === currentSearch) return;
      if (
        (toPathname && toPathname.startsWith("?")) ||
        ((!toPathname || toPathname === currentPathname) &&
          toSearch !== currentSearch)
      ) {
        navigate(to, navigateOptions);
        return;
      }

      await controls.start({
        clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
        WebkitClipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
        display: "flex",
        transition: { duration: 0 },
      });
      // document.body.style.overflow = "hidden";

      return await controls
        .start({
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          WebkitClipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          transition: { duration: 0.4, ease: "easeInOut" },
        })
        .then(() => {
          navigate(to, navigateOptions);
        });
    };

  useEffect(() => {
    // if (prevPath !== currentPathname) {
    //   controls.start({
    //     clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
    //     WebkitClipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
    //     display: "none",
    //     transition: {
    //       clipPath: {
    //         duration: 0.4,
    //         ease: "easeInOut",
    //       },
    //       WebkitClipPath: {
    //         duration: 0.4,
    //         ease: "easeInOut",
    //       },
    //       display: {
    //         delay: 2,
    //         duration: 0,
    //       },
    //     },
    //   });
    //   document.body.style.overflow = "";
    // }
  }, [currentPathname, controls, prevPath]);

  return (
    <PageTransitionContext.Provider
      value={{
        handleNavigate,
      }}
    >
      {/* {!isIPad13 && (
        <motion.div
          id="page_transition"
          className="fixed inset-0 z-[9999] flex h-screen w-screen items-center justify-center bg-black text-white"
          animate={controls}
          style={{
            transform: "translate3d(0, 0, 0)",
            WebkitTransform: "translate3d(0, 0, 0)",
          }}
        >
          <FlutterText
            className="aeonik text-14 uppercase"
            text="Tony Blair Institute for Global Change"
          />
        </motion.div>
      )}
      {children} */}

      <React.Fragment>{children}</React.Fragment>
    </PageTransitionContext.Provider>
  );
}

function usePageTransition() {
  const context = useContext(PageTransitionContext);
  if (context === undefined) {
    throw new Error(
      "usePageTransition must be used within a PageTransitionProvider"
    );
  }
  return context;
}

export { PageTransitionProvider, usePageTransition };
