import { ClientSide } from "~/@types";
import { IInsightColumnCardsFields } from "~/@types/generated/contentful";
import { contentfulInsightGetters } from "~/utils";
import { ColumnCards } from "../Cards";

type InsightColumnCardsProps = {
  fields: ClientSide<IInsightColumnCardsFields>;
};

export default function InsightColumnCards({
  fields,
}: InsightColumnCardsProps) {
  return (
    <section
      data-content-type="insightColumnCards"
      data-module-title={fields.moduleTitle}
      className="w-screen bg-slate py-10 lg:py-16"
    >
      {fields.eyebrow && (
        <p className="global-container eyebrows mb-6 lg:mb-8">
          {fields.eyebrow}
        </p>
      )}
      <ColumnCards insights={fields.insights.map(contentfulInsightGetters)} />
    </section>
  );
}
