import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import {
  IIndentedTextFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import { RoundedCta } from "../ctas";
import { Arrow } from "../icons";

type IndentedTextProps = {
  fields: SpecificLocaleFields<IIndentedTextFields>;
};

/**
 * Heavily indented heading found on about page.
 * Use `classes.text` prop with `xl:indent-[x%]` if needed.
 */
export default function IndentedText({ fields }: IndentedTextProps) {
  return (
    <section
      data-content-type="indentedText"
      data-module-title={fields.moduleTitle}
      className={clsx("w-full", {
        "text-white": ["black"].includes(fields.backgroundColor),
        "text-black": ["white", "slate"].includes(fields.backgroundColor),
        "bg-black": fields.backgroundColor === "black",
        "bg-white": fields.backgroundColor === "white",
        "bg-slate": fields.backgroundColor === "slate",
      })}
      style={{
        backgroundColor: fields.colorPalette?.fields.backgroundColor,
        color: fields.colorPalette?.fields.fontColor,
      }}
    >
      <div className={clsx("global-container py-12 lg:py-16")}>
        <div className="global-grid relative">
          <p className="eyebrows col-span-2 mb-6 block xl:absolute xl:top-0 xl:mb-0 xl:leading-100">
            {fields.eyebrow}
          </p>
          {/* xl title */}
          <h2 className="h1 col-span-full hidden indent-[58%]  xl:block">
            {fields.heading}
          </h2>
          {/* normal title */}
          <h2 className="h1 col-span-full mb-6 block xl:mb-0 xl:hidden">
            {fields.heading}
          </h2>
          <div className="col-span-full lg:col-span-5 lg:col-start-8">
            {fields.body && (
              <div className={clsx("body-large mb-6 last:mb-0")}>
                {documentToReactComponents(
                  fields.body,
                  getComponentFromContentfulRichTextEntry
                )}
              </div>
            )}
            {/* TODO: remove fields.buttonText && fields.buttonUrl */}
            {fields.button ? (
              <RoundedCta
                kind={fields.button.fields.type}
                label={fields.button.fields.buttonText}
                icon={<Arrow />}
                to={fields.button.fields.buttonUrl}
                className="mt-6 mb-0"
                custom={fields.button.fields.colorPalette?.fields}
              />
            ) : fields.buttonText && fields.buttonUrl ? (
              <RoundedCta
                kind="black"
                label={fields.buttonText}
                icon={<Arrow />}
                to={fields.buttonUrl}
                className="mt-6 mb-0"
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}
