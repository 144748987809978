import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import {
  ITextOnlyHeroFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";

type ReducedHeroTextOnlyProps = {
  fields: SpecificLocaleFields<ITextOnlyHeroFields>;
};

export default function ReducedHeroTextOnly({
  fields,
}: ReducedHeroTextOnlyProps) {
  return (
    <section
      data-content-type="reducedHeroTextOnly"
      data-module-title={fields.moduleTitle}
      className={clsx("w-full", {
        "text-white": ["black"].includes(fields.backgroundColor),
        "text-black": ["white", "slate"].includes(fields.backgroundColor),
        "bg-black": fields.backgroundColor === "black",
        "bg-white": fields.backgroundColor === "white",
        "bg-slate": fields.backgroundColor === "slate",
      })}
    >
      <div className="tbi-grid gap-y-4 pt-10 lg:pt-20 lg:pb-10">
        <div className="col-span-full">
          <h2 className="col-span-full -mb-5 lg:col-span-6">
            {fields.heading}
          </h2>
        </div>
        {fields.body && (
          <div className="[&_p]:body col-span-4 pb-10">
            {documentToReactComponents(
              fields.body,
              getComponentFromContentfulRichTextEntry
            )}
          </div>
        )}
      </div>
    </section>
  );
}
