import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Block, Inline, Text } from "@contentful/rich-text-types";
import {
  IPullQuoteFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import { ContentfulImg } from "../shared";

type PullQuoteProps = {
  fields: SpecificLocaleFields<IPullQuoteFields>;
  beforeSecondChapter?: boolean;
};

const assertText = (content: Block | Inline | Text): content is Text => {
  return content.hasOwnProperty("value");
};

export default function PullQuote({
  fields,
  beforeSecondChapter = false,
}: PullQuoteProps) {
  const bodyContent = fields.body?.content[0]?.content;

  if (bodyContent) {
    const firstBodyContent = bodyContent[0];
    const lastBodyContent = bodyContent[bodyContent.length - 1];

    if (
      assertText(firstBodyContent) &&
      !["'", '"', "`", "“", "”"].includes(firstBodyContent.value[0])
    ) {
      firstBodyContent.value = "\u201C" + firstBodyContent.value;
      if (assertText(lastBodyContent)) {
        lastBodyContent.value = lastBodyContent.value + "\u201D";
      }
    }
  }

  return (
    <div
      data-content-type="pullQuote"
      data-module-title={fields.moduleTitle}
      className="relative col-span-full flex flex-col gap-10 py-20 lg:col-start-2 lg:col-end-12"
    >
      <span className="ppe h3 [&>p]:h3">
        {fields.body && (
          <>
            {documentToReactComponents(
              fields.body,
              getComponentFromContentfulRichTextEntry
            )}
          </>
        )}
      </span>
      <hr className="w-full bg-dark_grey" />
      <div className="flex">
        <div className="grow">
          <h5 className="mb-1">
            {fields.attributionName && fields.attributionName}
          </h5>
          <p className="meta text-secondary_text">
            {fields.attributionTitle && fields.attributionTitle}
          </p>
        </div>
        {fields.attributionImage && (
          <ContentfulImg
            image={fields.attributionImage.fields}
            className="max-h-[50px] max-w-[180px]"
          />
        )}
      </div>
      {beforeSecondChapter && (
        <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
      )}
    </div>
  );
}
