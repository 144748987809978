import clsx from "clsx";
import { AnimatePresence, motion, useAnimationControls } from "framer-motion";
import { useState } from "react";
import { useEffectOnce } from "react-use";
import {
  IInsightsHeroFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import FlutterText from "../animations/flutterText";
import { ContentfulCenteredImage } from "../shared";
// import { PAGE_TRANSITION_DELAY } from "~/contexts/PageTransitionProvider";

type InsightsHeroProps = {
  fields: SpecificLocaleFields<IInsightsHeroFields>;
};

// This is a temporary component so this animation does not need to be polished yet
export default function InsightsHeroComponent({ fields }: InsightsHeroProps) {
  const positions = [
    {
      initial: {
        x: "10%",
        y: "-20%",
      },
      animate: {
        x: "-46vw",
        y: "-10vh",
      },
    },
    {
      initial: {
        x: "0%",
        y: "-40%",
      },
      animate: {
        x: "-14vw",
        y: "-15vh",
      },
    },
    {
      initial: {
        x: "10%",
        y: "0%",
      },
      animate: {
        x: "15vw",
        y: "-14vh",
      },
      horizontal: true,
    },
    {
      initial: {
        x: "10%",
        y: "0%",
      },
      animate: {
        x: "46vw",
        y: "-22vh",
      },
      horizontal: true,
    },
    {
      initial: {
        x: "-25%",
        y: "0%",
      },
      animate: {
        x: "-29vw",
        y: "34vh",
      },
      horizontal: true,
    },
    {
      initial: {
        x: "15%",
        y: "25%",
      },
      animate: {
        x: "6vw",
        y: "24vh",
      },
    },
    {
      initial: {
        x: "-5%",
        y: "35%",
      },
      animate: {
        x: "35vw",
        y: "21vh",
      },
    },
  ];

  const images = useAnimationControls();
  const [showHeadings, setShowHeadings] = useState(false);

  // const baseDelay = 0;

  const sequence = async () => {
    // setTimeout(() => setShowHeadings(true), baseDelay * 1000 + 2100);
    setShowHeadings(true);
    await images.start((i) => ({
      scale: 1,
      opacity: 1,
      x: positions[i].animate.x,
      y: positions[i].animate.y,
      // transition: {
      //   scale: {
      //     duration: 0,
      //     // delay: i * 0.1 + baseDelay,
      //   },
      //   opacity: {
      //     duration: 0,
      //     // delay: i * 0.1 + baseDelay + 0.5,
      //   },
      //   x: {
      //     duration: 0,
      //     // delay: baseDelay + 1.5,
      //   },
      //   y: {
      //     duration: 0,
      //     // delay: baseDelay + 1.5,
      //   },
      // },
    }));
  };

  useEffectOnce(() => {
    sequence();
  });

  return (
    <section className="h-screen min-h-hero-min">
      <AnimatePresence>
        <motion.div className="relative h-full w-screen bg-black" key="images">
          <div className="absolute inset-0 flex h-full w-full items-center justify-center">
            {fields.images?.map((image, ix) => (
              <motion.div
                key={image.sys.id}
                custom={ix}
                animate={images}
                initial={{
                  opacity: 0,
                  scale: 0.7,
                  x: positions[ix].initial.x,
                  y: positions[ix].initial.y,
                }}
                className={clsx("ease-in-out absolute z-[9]", {
                  "w-[80px]": !positions[ix].horizontal,
                  "h-[80px]": positions[ix].horizontal,
                  "lg:w-[120px]": !positions[ix].horizontal,
                  "lg:h-[120px]": positions[ix].horizontal,
                  "xl:w-[150px]": !positions[ix].horizontal,
                  "xl:h-[150px]": positions[ix].horizontal,
                  "2xl:w-[200px]": !positions[ix].horizontal,
                  "2xl:h-[200px]": positions[ix].horizontal,
                  "aspect-[15/28]": !positions[ix].horizontal,
                  "aspect-video": positions[ix].horizontal,
                })}
              >
                <ContentfulCenteredImage
                  className="absolute inset-0 h-full w-full bg-cover"
                  image={image.fields}
                  aria-hidden={true}
                />
              </motion.div>
            ))}
          </div>
        </motion.div>
        <motion.div
          key="headings"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0,
            // delay: baseDelay + 2.2,
          }}
          className="global-container absolute inset-0 z-[11] flex h-full w-full flex-col items-center justify-center text-white"
        >
          <p className="eyebrows z-[11]">{fields.eyebrow}</p>
          <div className="relative mt-10 lg:mt-20">
            {fields.heading && showHeadings && (
              <FlutterText className="display" text={fields.heading} />
            )}
            {/* This is here so that the height exists before we activate the headings with showHeadings */}
            <h1 className="absolute inset-0 opacity-0">{fields.heading}</h1>
          </div>
          <p className="body-large z-[11] mt-10 max-w-[575px] text-center lg:mt-20">
            {fields.body}
          </p>
        </motion.div>
      </AnimatePresence>
    </section>
  );
}
