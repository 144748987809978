import {
  IVideoPlayerFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import ReactPlayer from "react-player";
import { useState } from "react";
import clsx from "clsx";
import useIsMobile from "../utils/useIsMobile";
import { adjustValueBasedOnScreenSize } from "../utils/getValuesBasedOnScreenSize";

type VideoPlayerProps = {
  fields: SpecificLocaleFields<IVideoPlayerFields>;
};

export default function VideoPlayer({ fields }: VideoPlayerProps) {
  const [playing, setPlaying] = useState(false);
  const isMobile = useIsMobile();
  const isTablet = useIsMobile(1024);

  return (
    <section
      data-content-type="videoPlayer"
      data-module-title={fields.moduleTitle}
      className={clsx("relative col-span-full py-20", {
        "bg-black": fields.bottomColor === "black",
        "bg-white": fields.bottomColor === "white",
        "bg-slate": fields.bottomColor === "slate",
      })}
      style={{ backgroundColor: fields.colorPalette?.fields.accentColor }}
    >
      <div
        className={clsx("absolute top-0 h-1/2 w-full", {
          "bg-black": fields.topColor === "black",
          "bg-white": fields.topColor === "white",
          "bg-slate": fields.topColor === "slate",
        })}
        style={{ backgroundColor: fields.colorPalette?.fields.backgroundColor }}
      />
      <div className="tbi-grid">
        <div
          className={"relative col-span-full"}
          style={{
            padding: `${
              fields.spacingAroundVideo &&
              adjustValueBasedOnScreenSize(
                fields.spacingAroundVideo,
                isMobile,
                isTablet
              )
            }px`,
          }}
        >
          {fields.graphicElements &&
            fields.graphicElements.map((svg, index) => {
              const x = svg.fields.x || 0;
              const y = svg.fields.y || 0;
              const svgWidth =
                svg.fields.graphicElement?.fields.file.details.image?.width;
              const adjustedWidth =
                svgWidth &&
                adjustValueBasedOnScreenSize(svgWidth, isMobile, isTablet);

              return (
                <img
                  key={index}
                  src={svg.fields.graphicElement?.fields.file.url}
                  alt={svg.fields.graphicElement?.fields.title}
                  style={{
                    position: "absolute",
                    left: `${x}%`,
                    top: `${y}%`,
                    transform: `translate(-${x}%, -${y}%)`,
                    zIndex: svg.fields.z === 1 ? 1 : 0,
                    width: adjustedWidth,
                  }}
                />
              );
            })}
          <div className="relative aspect-video bg-slate">
            <div className="absolute inset-0 flex h-full w-full cursor-pointer items-center justify-center bg-transparent">
              {!playing && (
                <div
                  className="z-10 flex h-16 w-16 items-center justify-center rounded-full bg-white text-black lg:h-24 lg:w-24"
                  onClick={() => setPlaying(!playing)}
                  tabIndex={0}
                >
                  <p className="body-large">PLAY</p>
                </div>
              )}
              {fields.video?.fields?.file?.url && (
                <ReactPlayer
                  playing={playing}
                  onPlay={() => setPlaying(true)}
                  onPause={() => setPlaying(false)}
                  url={fields.video?.fields?.file?.url}
                  width="100%"
                  height="100%"
                  controls={playing}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
