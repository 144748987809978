import { BLOCKS, TopLevelBlock } from "@contentful/rich-text-types";
import { assertBlock } from "../ComponentDataProvider";

export const isLastBlockEmptyParagraph = (
  bodyContent: TopLevelBlock[]
): boolean => {
  const lastBodyElement = bodyContent?.[bodyContent.length - 1];
  if (lastBodyElement && assertBlock(lastBodyElement)) {
    if (
      lastBodyElement.nodeType === BLOCKS.PARAGRAPH &&
      lastBodyElement.content.length === 1 &&
      lastBodyElement.content[0].nodeType === "text" &&
      lastBodyElement.content[0].value === ""
    ) {
      return true;
    }
  }
  return false;
};
