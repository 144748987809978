import { ReactNode, useRef, useState } from "react";
import clsx from "clsx";
import { useEffectOnce, useEvent, useMedia, useWindowSize } from "react-use";

type FloatingNavProps = {
  children: ReactNode;
};

export default function FloatingNav({ children }: FloatingNavProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { height } = useWindowSize();
  const [stickyNav, setStickNav] = useState(false);
  const [articleVisible, setArticleVisible] = useState(false);
  const [lockToTop, setLockToTop] = useState(false);
  const [floaterHeight, setFloaterHeight] = useState(0);
  const [bottom, setBottom] = useState<undefined | string>("-60px");

  // See tailwind config for this value
  const isLarge = useMedia("(min-width: 1024px)");

  const onScroll = () => {
    if (wrapperRef.current) {
      if (!floaterHeight) {
        const floatHeight =
          wrapperRef.current.children[0]?.getBoundingClientRect().height;
        setFloaterHeight(floatHeight);
      }

      const parentBox =
        wrapperRef.current.parentElement?.getBoundingClientRect();

      if (parentBox) {
        if (parentBox.bottom <= height && lockToTop) {
          setLockToTop(false);
        } else if (parentBox.bottom > height && !lockToTop) {
          setLockToTop(true);
        }
      }

      const distFromTop = 0;

      const { top } = wrapperRef.current.getBoundingClientRect();
      if (top <= distFromTop && !stickyNav) {
        setStickNav(true);
      } else if (top >= distFromTop && stickyNav) {
        setStickNav(false);
      }

      if (top + 100 <= height && !articleVisible) {
        setArticleVisible(true);
      } else if (top + 100 >= height && articleVisible) {
        setArticleVisible(false);
      }

      if (isLarge && !lockToTop) {
        setBottom(`calc(50vh - ${floaterHeight / 2}px)`);
      } else if (!isLarge && (!articleVisible || !lockToTop)) {
        setBottom("-60px");
      } else if (!isLarge && articleVisible) {
        setBottom("0");
      } else {
        setBottom(undefined);
      }
    }
  };
  useEvent("scroll", onScroll);
  useEffectOnce(onScroll);

  return (
    <div ref={wrapperRef} className="flex flex-col lgmax:h-0">
      <nav
        className={clsx(
          "z-10 w-screen duration-500",
          // max width of content is 1520 so 760 is half of that
          "2xl:right-[calc(50vw-760px)]",
          // Under 1680 (2xl) just use the gutter for "right"
          "lg:right-desktop-gutter lg:h-min lg:w-min",
          "lgmax:tbi-grid lgmax:transition-bottom lgmax:fixed lgmax:border-t lgmax:border-dark_grey lgmax:bg-white lgmax:py-4",
          {
            // desktop
            "lg:absolute": !stickyNav || !lockToTop,
            "lg:fixed": stickyNav && lockToTop,
          }
        )}
        style={{
          top:
            isLarge && lockToTop
              ? `calc(50vh - ${floaterHeight / 2}px)`
              : undefined,
          bottom,
        }}
      >
        <div className="col-span-full flex justify-around gap-6 lg:flex-col lg:items-end lg:justify-center">
          {children}
        </div>
      </nav>
    </div>
  );
}
