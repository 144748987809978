import { fallbackLanguage } from "~/i18n-config";

const nth = function (d: number) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

/**
 * @param dateStr Expects the contentful date format "YYYY-MM-DD"
 */
export const getPrettyDate = (dateStr: string) => {
  const parts = dateStr.match(/(\d\d\d\d)-(\d\d)-(\d\d)/);
  if (parts?.length !== 4) {
    throw Error("Invalid date format");
  }
  const [, year, month, day] = parts;
  const date = new Date(Number(year), Number(month) - 1, Number(day));
  const daySuffix = nth(date.getDate());
  const monthName = Intl.DateTimeFormat(fallbackLanguage, {
    month: "long",
  }).format(date);

  return `${date.getDate()}${daySuffix} ${monthName} ${date.getFullYear()}`;
};
