import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import {
  IComparisonChartFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import ContentfulCenteredImage from "../shared/ContentfulCenteredImage";
import ForcedAspectRatio from "../shared/ForcedAspectRatio";
import { Fragment } from "react";
import ImageSlowScroll from "../shared/ImageSlowScroll";

type ComparisonChartProps = {
  fields: SpecificLocaleFields<IComparisonChartFields>;
  beforeSecondChapter?: boolean;
};

export default function ComparisonChart({
  fields,
  beforeSecondChapter = false,
}: ComparisonChartProps) {
  return (
    <div
      data-content-type="comparisonChart"
      data-module-title={fields.moduleTitle}
      className="tbi-grid relative col-span-full gap-y-10 py-20 lg:gap-y-20 lgmax:!gap-x-0"
    >
      <h2 className="col-span-full -mb-4 lg:col-span-6">{fields.heading}</h2>
      <div className="col-span-full lg:col-span-4 lg:col-start-8 [&>:first-child]:mt-0">
        {fields.body && (
          <>
            {documentToReactComponents(
              fields.body,
              getComponentFromContentfulRichTextEntry
            )}
          </>
        )}
      </div>
      {fields.image ? (
        <ForcedAspectRatio className="col-span-full" ratio={[16, 9]}>
          <ImageSlowScroll>
            <ContentfulCenteredImage
              className="h-full"
              image={fields.image.fields}
            />
          </ImageSlowScroll>
        </ForcedAspectRatio>
      ) : null}
      {fields.rows &&
        fields.rows.map((row, index) => (
          <Fragment key={`row-one-mobile-${index}`}>
            <div className="col-span-6 lgmax:hidden">
              {index === 0 ? (
                <>
                  <h5>{fields.columnOneHeading}</h5>
                  <hr className="mt-4 mb-10 border border-solid border-[currentColor]" />
                </>
              ) : (
                <hr className="mb-10 border border-solid border-[currentColor]" />
              )}
              {row.fields.firstColumn
                ? documentToReactComponents(
                    row.fields.firstColumn,
                    getComponentFromContentfulRichTextEntry
                  )
                : null}
            </div>
            <div className="col-span-6 lgmax:hidden">
              {index === 0 ? (
                <>
                  <h5>{fields.columnTwoHeading}</h5>
                  <hr className="mt-4 mb-10 border border-solid border-[currentColor]" />
                </>
              ) : (
                <hr className="mb-10 border border-solid border-[currentColor]" />
              )}
              {row.fields.secondColumn
                ? documentToReactComponents(
                    row.fields.secondColumn,
                    getComponentFromContentfulRichTextEntry
                  )
                : null}
            </div>
          </Fragment>
        ))}
      <div className="col-span-full mb-10 lg:hidden">
        {fields.rows &&
          fields.rows.map((row, index) => (
            <div key={`row-one-mobile-${index}`}>
              {index === 0 ? (
                <>
                  <h5>{fields.columnOneHeading}</h5>
                  <hr className="mt-4 mb-4 border border-solid border-[currentColor]" />
                </>
              ) : (
                <hr className="mb-4 mt-10 border border-solid border-[currentColor]" />
              )}
              {row.fields.firstColumn
                ? documentToReactComponents(
                    row.fields.firstColumn,
                    getComponentFromContentfulRichTextEntry
                  )
                : null}
            </div>
          ))}
      </div>
      <div className="col-span-full lg:hidden">
        {fields.rows &&
          fields.rows.map((row, index) => (
            <div key={`row-two-mobile-${index}`}>
              {index === 0 ? (
                <>
                  <h5>{fields.columnTwoHeading}</h5>
                  <hr className="mt-4 mb-4 border border-solid border-[currentColor]" />
                </>
              ) : (
                <hr className="mb-4 mt-10 border border-solid border-[currentColor]" />
              )}
              {row.fields.secondColumn
                ? documentToReactComponents(
                    row.fields.secondColumn,
                    getComponentFromContentfulRichTextEntry
                  )
                : null}
            </div>
          ))}
      </div>
      {beforeSecondChapter && (
        <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
      )}
    </div>
  );
}
