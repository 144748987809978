import clsx from "clsx";
import { MouseEvent } from "react";
import { X } from "../icons";
import NodeIcon from "./NodeIcon";
import { CtaButtonProps, EventElementTypes } from "../types";
import NavOrExternalLink from "../shared/NavOrExternalLink";

const CloseCtaKinds = {
  black: "black",
  white: "white",
};

type CloseCtaKind = "black" | "white";

export interface CloseCtaProps extends CtaButtonProps {
  kind: CloseCtaKind;
  iconClassName?: string;
}

export default function CloseCta({
  kind,
  disabled,
  to,
  onClick,
  className = "",
  iconClassName = "",
  as = "button",
  id,
  dataAttrs,
  navLinkProps,
}: CloseCtaProps) {
  const btnClassName = clsx({
    cta: true,
    "w-[42px]": true,
    "h-[42px]": true,
    "rounded-full": true,
    "hover:underline": !disabled,
    "outline-none": true,
    flex: true,
    "justify-center": true,
    "items-center": true,
    "transition-[color,background]": true,
    "duration-300": true,
    "hover:bg-black": [CloseCtaKinds.black].includes(kind) && !disabled,
    "active:bg-black": [CloseCtaKinds.black].includes(kind) && !disabled,
    "hover:bg-white": [CloseCtaKinds.white].includes(kind) && !disabled,
    "active:bg-white": [CloseCtaKinds.white].includes(kind) && !disabled,
    "text-black": [CloseCtaKinds.black].includes(kind) && !disabled,
    "hover:text-white": [CloseCtaKinds.black].includes(kind) && !disabled,
    "active:text-white": [CloseCtaKinds.black].includes(kind) && !disabled,
    "text-white": [CloseCtaKinds.white].includes(kind) && !disabled,
    "hover:text-black": [CloseCtaKinds.white].includes(kind) && !disabled,
    "active:text-black": [CloseCtaKinds.white].includes(kind) && !disabled,
    "focus:bg-dark_grey": [CloseCtaKinds.black].includes(kind) && !disabled,
    "focus:bg-charcoal": [CloseCtaKinds.white].includes(kind) && !disabled,
    "active:text-charcoal": [CloseCtaKinds.black].includes(kind) && !disabled,
    "active:text-dark_grey": [CloseCtaKinds.white].includes(kind) && !disabled,
    "text-secondary_text": disabled,
  });

  const handleClick = (e: MouseEvent<EventElementTypes>) => {
    if (onClick && !disabled) {
      onClick(e);
    }
  };

  if (to && !disabled) {
    return (
      <NavOrExternalLink
        id={id}
        to={to}
        className={`${btnClassName} ${className}`}
        onClick={handleClick}
        title="Close"
        {...navLinkProps}
        {...dataAttrs}
      >
        <NodeIcon aria-label="Close" className={iconClassName}>
          <X />
        </NodeIcon>
      </NavOrExternalLink>
    );
  }

  const As = as;

  return (
    <As
      id={id}
      disabled={disabled}
      className={`${btnClassName} ${className}`}
      onClick={handleClick}
      title="Close"
      {...dataAttrs}
    >
      <NodeIcon aria-label="Close" className={iconClassName}>
        <X width="18px" height="18px" />
      </NodeIcon>
    </As>
  );
}
