import {
  IThreeUpRoutingFields,
  ITwoUpRoutingFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { RoundedCta } from "../ctas";
import { Arrow } from "../icons";
import { ContentfulCenteredImage, ForcedAspectRatio } from "../shared";
import clsx from "clsx";
import ThreeUpRouting from "./ThreeUpRouting";
import useIsMobile from "../utils/useIsMobile";

export type TwoUpRoutingProps = {
  fields: SpecificLocaleFields<ITwoUpRoutingFields>;
};

export default function TwoUpRouting({ fields }: TwoUpRoutingProps) {
  const isMobile = useIsMobile();

  return (
    <section
      data-content-type="twoUpRouting"
      data-module-title={fields.moduleTitle}
      style={{
        backgroundColor: fields.colorPalette?.fields.backgroundColor,
        color: fields.colorPalette?.fields.fontColor,
      }}
    >
      <div className={clsx(!isMobile && "tbi-tablet-grid py-20")}>
        {isMobile ? (
          <ThreeUpRouting
            fields={fields as SpecificLocaleFields<IThreeUpRoutingFields>}
            cta={true}
          />
        ) : (
          fields.routes &&
          fields.routes.map((route) => (
            <div
              key={route.sys.id}
              className="col-span-full flex h-full flex-col sm:col-span-6"
            >
              {route.fields.image && (
                <ForcedAspectRatio
                  className={clsx("max-h-[827px] min-h-[460px] w-full")}
                  ratio={[3, 4]}
                >
                  <ContentfulCenteredImage
                    className="h-full w-full"
                    image={route.fields.image.fields ?? ""}
                  />
                </ForcedAspectRatio>
              )}
              {route.fields.heading && (
                <h4 className="mt-6">{route.fields.heading}</h4>
              )}
              {route.fields.body && <p className="mt-6">{route.fields.body}</p>}
              <div className="mt-auto">
                {route.fields.cta && (
                  <RoundedCta
                    className="mt-6"
                    to={route.fields.cta.fields.buttonUrl}
                    label={route.fields.cta.fields.buttonText}
                    kind={route.fields.cta.fields.type}
                    icon={<Arrow />}
                    custom={route.fields.cta.fields.colorPalette?.fields}
                  />
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  );
}
