import clsx from "clsx";
import { ClientSide } from "~/@types";
import { IFeaturedInsight } from "~/@types/generated/contentful";
import { useComponentData } from "~/contexts/ComponentDataProvider";
import FeaturedInsightCard from "../Cards/FeaturedInsightCard";

type FeaturedInsightProps = {
  insight: ClientSide<IFeaturedInsight>;
};

export default function FeaturedInsight({ insight }: FeaturedInsightProps) {
  // featuredInsight exists on both page and insight component data
  const { featuredInsight } = useComponentData();
  let { fields } = insight;
  let fi = fields.insight?.fields;
  if (!fi) {
    fi = featuredInsight?.[insight.sys.id].fields;
  }

  return (
    <section
      data-content-type="featuredInsight"
      data-module-title={fields.moduleTitle}
      className={clsx(
        ["Sundry/Home Page", "Insight"].includes(fields.type) &&
          "tbi-grid pt-20 pb-12 lg:pb-20",
        { "article-columns-max": "Insight" === fields.type },
        "relative"
      )}
    >
      {fields.type === "Sundry/Home Page" && (
        <p className="eyebrows break-keep pb-6 lgmax:col-span-full">
          {fields.eyebrow}
        </p>
      )}

      {fields.type === "Insights Page" && (
        <div
          className={clsx({
            absolute: true,
            "inset-0": true,
            "-z-20": true,
            "h-40": true,
            "w-full": true,
            "bg-black": true,
            "lgmax:hidden": true,
          })}
        />
      )}
      {fi && <FeaturedInsightCard fields={fi} cardType={fields.type} />}
      {["Sundry/Home Page", "Insight"].includes(fields.type) && (
        <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate"></span>
      )}
    </section>
  );
}
