// This is bad. I know this is bad. But it's the only way to make a story for this component
/* eslint-disable react-hooks/rules-of-hooks */
import { useLocation, useNavigation } from "@remix-run/react";
import clsx from "clsx";
import { useContext, useState } from "react";
import { ITopic } from "~/@types/generated/contentful";
import {
  IComponentDataTopic,
  PageComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import { ContentfulCenteredImage } from "../../shared";
import { CircleIconCta, CloseCta, IconCta } from "../../ctas";
import { ClientSide } from "~/@types";
import { motion } from "framer-motion";
import { useNavShowing } from "~/contexts/NavShowingProvider";
import { usePrevious, useShallowCompareEffect } from "react-use";
import { SimpleSwipeUp } from "~/components/animations";
import SvgTopicBlack from "~/components/icons/svgs/TopicBlack";
import NavOrExternalLink from "~/components/shared/NavOrExternalLink";
import { StorybookContext } from "~/components/utils/StorybookContext";

export default function TopicTakeover() {
  const forStoryBook = useContext(StorybookContext);
  const location = useLocation();
  const { topics } = useComponentData() as PageComponentData;
  const { setNavDisabled, setNavShowing } = useNavShowing();
  const [imageOnly, setImageOnly] = useState(false);
  const [bgTopic, setBgTopic] = useState("white");
  const onMouseLeave = () => setBgTopic("white");

  const [open, _setOpen] = useState(false);
  const setOpen = (isOpen: boolean) => {
    _setOpen(isOpen);
    setNavDisabled(isOpen);
    setNavShowing(!isOpen);
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };

  const selectTopic = ({ topic }: { topic: ClientSide<ITopic> }) => {
    setBgTopic(topic.fields.slug);
    setImageOnly(true);
  };

  if (!forStoryBook) {
    const navigation = useNavigation();
    const prevNavigation = usePrevious(navigation);
    useShallowCompareEffect(() => {
      if (navigation.state === "loading") {
        const paths = navigation.location.pathname.split("/");
        const slug = paths[paths.length - 1] ?? "white";
        setBgTopic(slug);
      } else if (
        prevNavigation?.state === "loading" &&
        navigation.state === "idle"
      ) {
        if (open) {
          setOpen(false);
          setBgTopic("white");
          setImageOnly(false);
        }
      }
    }, [navigation, prevNavigation]);
  }

  const buttonChildren = (topic: IComponentDataTopic) => (
    <>
      {/* Don't move the active dot on hover and the other ones should fade out */}
      <motion.span
        className={clsx("mr-6 h-3 w-3 rounded-full transition-opacity", {
          "opacity-0": bgTopic !== "white" && bgTopic !== topic.fields.slug,
        })}
        style={{ backgroundColor: topic.fields.color }}
      />
      <h3 className="h4 sm:h3 relative whitespace-nowrap transition-transform lg:text-72 group-hover:lg:translate-x-6">
        {topic.fields.title}
        {/* TODO: Bring this back when they're ready to show the article count on each topic again */}
        {/* <motion.sup
          initial={{ opacity: "0" }}
          animate={{
            opacity: "1",
            transition: { duration: 0.7, delay: 1 },
          }}
          className="eyebrows absolute bottom-2 ml-1 lgmax:hidden"
        >
          ({topic.total})
        </motion.sup> */}
      </h3>
    </>
  );

  return (
    <>
      {topics?.length &&
        topics
          .sort((a, b) => {
            if (a.fields.title && b.fields.title) {
              return a.fields.title.localeCompare(b.fields.title);
            }
            return 0;
          })
          .map((topic) => (
            <div
              key={topic.sys.id}
              className="fixed inset-0 bg-cover bg-center bg-no-repeat transition-[opacity] duration-100 lg:hidden"
              style={{
                opacity: bgTopic === topic.fields.slug && imageOnly ? 1 : 0,
                zIndex:
                  bgTopic === topic.fields.slug && imageOnly ? 99999999 : -100,
                height:
                  bgTopic === topic.fields.slug && imageOnly ? "100vh" : 0,
                width: bgTopic === topic.fields.slug && imageOnly ? "100vw" : 0,
              }}
            >
              {topic.fields.image && (
                <ContentfulCenteredImage
                  bg="grey"
                  image={topic.fields.image.fields}
                  className="h-full w-full"
                />
              )}
            </div>
          ))}
      <div
        className="fixed inset-0 bg-cover bg-center bg-no-repeat transition-[opacity] duration-100 lg:hidden"
        style={{
          background: "white",
          opacity: bgTopic === "white" && imageOnly ? 1 : 0,
          zIndex: bgTopic === "white" && imageOnly ? 99999999 : -100,
          height: bgTopic === "white" && imageOnly ? "100vh" : 0,
          width: bgTopic === "white" && imageOnly ? "100vw" : 0,
        }}
      />
      <CircleIconCta
        className="border border-slate lgmax:hidden"
        kind={"white"}
        label="Topic"
        icon={<SvgTopicBlack />}
        as="div"
        onClick={() => setOpen(!open)}
      />
      <IconCta
        kind="black"
        icon={<SvgTopicBlack />}
        label="Topic"
        className="lg:hidden [&>span]:w-max"
        onClick={() => setOpen(!open)}
      />
      <SimpleSwipeUp open={open}>
        <div className="fixed inset-0 z-40 h-screen w-screen">
          <div className="h-screen w-screen bg-white">
            <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center overflow-y-scroll px-mobile-gutter lg:px-desktop-gutter">
              <div className="fixed inset-0 -z-10 h-screen w-screen bg-white">
                {topics?.length &&
                  topics
                    .sort((a, b) => {
                      if (a.fields.title && b.fields.title) {
                        return a.fields.title.localeCompare(b.fields.title);
                      }
                      return 0;
                    })
                    .map((topic) => (
                      <div
                        key={topic.sys.id}
                        className="absolute inset-0 z-10 h-screen w-screen bg-cover bg-center bg-no-repeat"
                        style={{
                          opacity: bgTopic === topic.fields.slug ? 1 : 0,
                        }}
                      >
                        {topic.fields.image && (
                          <ContentfulCenteredImage
                            bg="grey"
                            image={topic.fields.image.fields}
                            className="h-full w-full"
                          />
                        )}
                      </div>
                    ))}
              </div>
              <div
                className={clsx({
                  "z-30": true,
                  invisible: imageOnly,
                  visible: !imageOnly,
                })}
              >
                <nav className="fixed inset-0 z-20 flex h-min min-h-[42px] w-screen items-center justify-between px-mobile-gutter pt-6 lg:px-desktop-gutter">
                  <p
                    className={`transition-[color] duration-300 ${
                      bgTopic === "white" ? "text-black" : "text-white"
                    } eyebrows`}
                  >
                    Topics
                  </p>
                  <CloseCta
                    id="topic-takeover-close-cta"
                    onClick={() => setOpen(false)}
                    kind={bgTopic === "white" ? "black" : "white"}
                    as="div"
                  />
                </nav>
                <div className="z-20 mx-auto flex h-full flex-col">
                  {topics?.length &&
                    topics
                      .sort((a, b) => {
                        if (a.fields.title && b.fields.title) {
                          return a.fields.title.localeCompare(b.fields.title);
                        }
                        return 0;
                      })
                      .map((topic) => {
                        if (
                          location.pathname === `/insights/${topic.fields.slug}`
                        ) {
                          return (
                            <div
                              key={topic.sys.id}
                              className={`group flex w-full cursor-pointer items-center justify-start py-4 transition-[color,transform] duration-300 lg:hover:text-white ${
                                bgTopic === "white"
                                  ? "text-black"
                                  : "text-secondary_text"
                              }`}
                              onMouseEnter={() => setBgTopic(topic.fields.slug)}
                              onMouseLeave={onMouseLeave}
                              onClick={() => setOpen(false)}
                              tabIndex={0}
                            >
                              {buttonChildren(topic)}
                            </div>
                          );
                        }

                        return (
                          <NavOrExternalLink
                            key={topic.sys.id}
                            id={`topic-takeover-link-${topic.fields.slug}`}
                            to={`/insights/${topic.fields.slug}`}
                            className={`group flex w-full items-center justify-start py-4 transition-[color,transform] duration-300 lg:hover:text-white ${
                              bgTopic === "white"
                                ? "text-black"
                                : "text-secondary_text"
                            }`}
                            onClick={() => selectTopic({ topic })}
                            onMouseEnter={() => setBgTopic(topic.fields.slug)}
                            onMouseLeave={onMouseLeave}
                            prefetch="intent"
                          >
                            {buttonChildren(topic)}
                          </NavOrExternalLink>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </SimpleSwipeUp>
    </>
  );
}
