import clsx from "clsx";
import {
  IFeaturedInsightFields,
  IInsightFields,
  SpecificLocaleField,
} from "~/@types/generated/contentful";
import { ContentfulCenteredImage } from "../shared";
import { getPrettyDate } from "../utils/getPrettyDate";
import { ClientSide } from "~/@types";
import { motion } from "framer-motion";
import NavOrExternalLink from "../shared/NavOrExternalLink";

type FeaturedInsightCardProps = {
  fields: ClientSide<IInsightFields>;
  cardType?: SpecificLocaleField<IFeaturedInsightFields["type"]>;
};

export default function FeaturedInsightCard({
  fields,
  cardType,
}: FeaturedInsightCardProps) {
  const imageClass = clsx([
    "aspect-3/4",
    "lg:aspect-video",
    "w-full",
    "bg-cover",
    "lg:absolute",
    "lg:inset-0",
    "group-hover:scale-105",
    "transition-transform",
    "duration-1000",
    "ease-in-out",
  ]);

  if (!fields || !Object.keys(fields).length) return null;
  return (
    <div
      className={clsx(
        {
          "lg:text-white": cardType === "Topic Page",
          "lgmax:text-white": cardType === "Color Carousel",
          "lgmax:bg-black lgmax:text-white": cardType === "Insights Page",
          "mx-auto max-w-content-max py-8 px-mobile-gutter lg:px-desktop-gutter lg:pt-10":
            !["Insight", "Sundry/Home Page", "Color Carousel"].includes(
              cardType ?? ""
            ),
          "col-span-full": ["Sundry/Home Page"].includes(cardType ?? ""),
          "article-columns-max": "Insight" === cardType,
        },
        "global-grid block lg:relative lg:aspect-video lg:pb-0 lg:text-white"
      )}
    >
      <div className="global-grid group relative col-span-12 h-full w-full overflow-hidden">
        {/* MOBILE */}

        <div className="col-span-4 lg:hidden">
          {fields.image?.fields ? (
            <ContentfulCenteredImage
              className={`${imageClass}`}
              image={fields.image.fields}
            />
          ) : (
            <div className={`${imageClass} bg-slate`} />
          )}
        </div>

        {/* DESKTOP */}

        <div className="lgmax:hidden">
          {fields.image?.fields ? (
            <ContentfulCenteredImage
              className={`${imageClass}`}
              image={fields.image.fields}
              bg="gradient"
            />
          ) : (
            <div
              className="flex h-full w-full items-center justify-center text-white"
              style={{
                backgroundColor: fields.topic?.fields.color ?? "#F5F5F5",
              }}
            >
              <h4 className="text-center">{fields.topic?.fields.title}</h4>
            </div>
          )}
        </div>

        <div className="lg:global-grid z-0 col-span-4 self-end lg:col-span-12 lg:pb-20">
          <div className="z-10 flex flex-col justify-end lg:col-span-full lg:col-start-2 lg:col-end-12 xl:col-span-7 xl:col-start-2">
            <motion.span
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.5, delay: 0.5 },
              }}
              exit={{ opacity: 0, transition: { duration: 0.5 } }}
              className="mt-6 w-max uppercase"
            >
              <h4 className="cta uppercase">{fields.topic?.fields.title}</h4>
            </motion.span>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.5, delay: 0.5 },
              }}
              exit={{ opacity: 0, transition: { duration: 0.5 } }}
            >
              <NavOrExternalLink
                to={`/insights/${fields.topic?.fields.slug}/${fields.slug}`}
                prefetch="intent"
              >
                <h2
                  className={clsx(
                    "h2 lg:h3 xl:h2 mt-6 after:absolute after:inset-0",
                    {
                      h4: cardType === "Insight",
                    }
                  )}
                >
                  {fields.title}
                </h2>
              </NavOrExternalLink>
            </motion.div>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.5 } }}
              exit={{ opacity: 0 }}
              className={clsx({
                meta: true,
                "mt-6": true,
                uppercase: true,
              })}
            >
              {fields.date && getPrettyDate(fields.date)}
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
}
