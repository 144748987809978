import { v4 as uuidv4 } from "uuid";
import { useEffect, useMemo, useState } from "react";
import {
  IStatementRoutingFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import FlutterText from "../animations/flutterText";
import { RoundedCta } from "../ctas";
import { Arrow } from "../icons";

export type StatementRouterProps = {
  fields: SpecificLocaleFields<IStatementRoutingFields>;
};

export default function StatementRouter({ fields }: StatementRouterProps) {
  const [currentIndex, setCurrenIndex] = useState(0);

  const openingStatements = useMemo(
    () =>
      fields.rotatingStatements.map(() => ({
        statement: fields.statement,
        key: uuidv4(),
      })),
    [fields]
  );

  useEffect(() => {
    const changeCurrentIndex = setInterval(
      () => setCurrenIndex((i) => (i + 1) % fields.rotatingStatements.length),
      4000
    );
    return () => {
      clearInterval(changeCurrentIndex);
    };
  }, [currentIndex, fields.rotatingStatements.length]);

  return (
    <section
      className={`center relative h-full w-full`}
      data-content-type="statementRouter"
      data-module-title={fields.moduleTitle}
    >
      <div className="lg:my-49 mx-4 my-40 flex flex-col items-center gap-10 lg:my-48 lg:mx-20">
        <p className="eyebrows">{fields.eyebrow}</p>
        <h2 className={"lg-max:px-mobile-gutter text-center"}>
          <div className="lg:flex lg:h-14 lgmax:h-32">
            <FlutterText
              key={openingStatements[currentIndex].key}
              text={openingStatements[currentIndex].statement}
              className={"text-secondary_text"}
              cycleOut
            />
            <FlutterText
              className="ml-3.5"
              key={fields.rotatingStatements[currentIndex]}
              text={`${fields.rotatingStatements[currentIndex]}.`}
              cycleOut
            />
          </div>
        </h2>
        <RoundedCta
          label={fields.buttonText}
          kind={"black"}
          icon={<Arrow />}
          to={fields.url}
        />
      </div>
    </section>
  );
}
