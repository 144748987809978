import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  IBoxoutFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";

type BoxoutProps = {
  fields: SpecificLocaleFields<IBoxoutFields>;
  beforeSecondChapter?: boolean;
};

export default function Boxout({
  fields,
  beforeSecondChapter = false,
}: BoxoutProps) {
  return (
    <div
      data-content-type="boxout"
      data-module-title={fields.moduleTitle}
      className="relative col-span-full mb-20"
    >
      <div className="global-grid py-20">
        <div className="col-span-full lg:col-start-2 lg:col-end-6">
          <h3 className="mb-10">{fields.heading}</h3>
        </div>
        <div className="col-span-full lg:col-start-7 lg:col-end-12">
          {fields.items?.map((item) =>
            item.fields?.body ? (
              <div
                key={item.sys.id}
                className="mb-6 border-l border-black pl-4 pb-10 lg:mb-16 lg:pl-6 [&>:first-child]:mt-0 [&>:first-child]:pt-0"
              >
                {documentToReactComponents(
                  item.fields.body,
                  getComponentFromContentfulRichTextEntry
                )}
              </div>
            ) : null
          )}
        </div>
      </div>
      {beforeSecondChapter ? (
        <span className="absolute -left-[100vw] -right-[100vw] top-0 -bottom-20 -z-10 bg-slate" />
      ) : (
        <span className="absolute -left-4 -right-4 top-0 bottom-0 -z-10 bg-slate"></span>
      )}
    </div>
  );
}
