import { RefObject, useEffect, useState } from "react";
import { useEvent } from "react-use";

export function useRefScrollProgress(
  ref: RefObject<HTMLDivElement>,
  watch?: unknown
) {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  const getStartAndEnd = () => {
    if (!ref.current) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offsetTop = rect.top + scrollTop - window.innerHeight;

    const documentHeight = document.body.clientHeight - window.innerHeight;

    setStart(offsetTop / documentHeight);
    setEnd((offsetTop + window.innerHeight + rect.height) / documentHeight);
  };

  useEffect(getStartAndEnd, [watch, ref]);
  useEvent("resize", getStartAndEnd);

  return { ref, start, end };
}
