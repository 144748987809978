import clsx from "clsx";
import {
  IExpertsListFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import {
  PageComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import ExpertsCard from "./ExpertsCard";
import { useState } from "react";
import { RoundedCta } from "../ctas";
import { Arrow } from "../icons";

type ExpertsListProps = {
  fields: SpecificLocaleFields<IExpertsListFields>;
  sysId?: string;
};

export default function ExpertsList({ fields, sysId }: ExpertsListProps) {
  const { experts } = useComponentData() as PageComponentData;
  const [visibleCount, setVisibleCount] = useState(8);

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 8);
  };
  const expertList = fields.experts
    ? fields.experts.map((expert) => expert.fields)
    : experts;

  const sortedExperts =
    expertList &&
    [...expertList]
      .sort((a, b) => {
        const lastNameA = a.lastName || "";
        const lastNameB = b.lastName || "";
        return lastNameA.localeCompare(lastNameB);
      })
      .filter((expert) => expert.type === "Current TBI");

  return (
    <section
      className="tbi-grid w-full gap-y-10 pt-12 lg:gap-y-20 lg:pt-20"
      data-content-type="expertsList"
      data-module-title={fields.moduleTitle}
    >
      <div className="lg:col-start-1 lg:col-end-4 lgmax:col-span-4">
        <h4>{fields.sectionTitle}</h4>
        <p className="mt-4">{fields.sectionDescription}</p>
      </div>

      {sortedExperts?.length &&
        sortedExperts.slice(0, visibleCount).map((expert, index) => (
          <ExpertsCard
            className={clsx({
              "lg:col-start-5 lg:col-end-8": index === 0 || index % 3 === 0,
              "lg:col-start-9 lg:col-end-12": index === 1 || index % 3 === 1,
              "lg:col-start-1 lg:col-end-4": index % 3 === 2,
              "lgmax:col-span-2": true,
              "lg:mt-10": index % 3 !== 0,
              "lgmax:mt-10": index % 2 === 1,
            })}
            imageBackgroundColor="bg-slate"
            key={expert.slug}
            expert={expert}
          />
        ))}

      {sortedExperts && visibleCount < sortedExperts.length && (
        <div className="col-span-full flex justify-center">
          <RoundedCta
            className="hover:bg-black hover:text-white hover:no-underline"
            label="Show more"
            onClick={handleShowMore}
            kind={"hollow"}
            icon={<Arrow />}
            type="button"
          />
        </div>
      )}
      <div className="col-span-full h-px bg-black"></div>
    </section>
  );
}
