import { ReactNode, useRef } from "react";
import { Block, Inline } from "@contentful/rich-text-types";
import useIsVisible from "~/components/utils/useIsVisible";

type ParagraphProps = {
  node: Block | Inline;
  children: ReactNode;
  isTable?: boolean;
};

export default function Paragraph({ node, children, isTable }: ParagraphProps) {
  const elementRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(elementRef);

  // Return a line break for empty paragraphs to match what editors do in contentful
  if (Array.isArray(children) && children.length === 1 && children[0] === "") {
    children = <br />;
  }

  if (isTable) {
    return (
      <p className="article-columns body-large relative my-10 whitespace-pre-wrap">
        {children}
      </p>
    );
  }

  return (
    <p
      className={
        "article-columns body-large relative mt-10 whitespace-pre-wrap"
      }
      ref={elementRef}
    >
      <span
        style={{
          opacity: isVisible ? 1 : 0,
          transition: "opacity 0.6s ease-in-out",
        }}
      >
        {children}
      </span>
      {node.data.beforeSecondChapter && (
        <span className="absolute -left-[100vw] -right-[100vw] -top-10 -bottom-10 -z-10 bg-slate" />
      )}
    </p>
  );
}
