import { Play, Pause } from "../icons";
import { useRef, useState } from "react";
import ScaleUpOnScroll from "../shared/ScaleUpOnScroll";
import {
  IImageFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getImageURL } from "../utils/getImageURL";

export type FullscreenVideoProps = {
  className?: string;
  url: string;
  fallbackImage?: SpecificLocaleFields<IImageFields>;
  dimPercent?: number;
  showPlayBtn?: boolean;
};

export default function FullscreenVideo({
  className = "",
  url,
  dimPercent = 0,
  showPlayBtn = false,
  fallbackImage,
}: FullscreenVideoProps) {
  let videoRef = useRef<HTMLVideoElement>(null);
  const [_playing, setPlaying] = useState(true);

  const clickPlayPause = () => {
    let video = videoRef.current;
    if (!video) {
      return;
    }
    if (video.paused) {
      video.play();
      setPlaying(true);
    } else {
      video.pause();
      setPlaying(false);
    }
  };

  return (
    <ScaleUpOnScroll className={`relative h-full w-screen ${className}`}>
      <video
        data-content-type="fullscreenVideo"
        data-module-title={url}
        ref={videoRef}
        className="relative top-1/2 h-auto w-full max-w-none -translate-y-1/2 lgmax:h-screen"
        autoPlay
        muted
        loop
      >
        <source src={url} />
        {fallbackImage && (
          <img
            src={getImageURL(fallbackImage)}
            title="Your browser does not support the <video> tag"
            alt={fallbackImage.alt}
          />
        )}
        <track
          src="/noCaptions.vtt"
          kind="captions"
          srcLang="en"
          label="english captions"
        />
        Unsupported video.
      </video>
      {showPlayBtn ? (
        <button
          className={
            "absolute bottom-10 h-[40px] w-[40px] rounded-full border text-white lg:bottom-20 lg:right-20 lgmax:left-4"
          }
          onClick={clickPlayPause}
          title="Play Pause Toggle"
        >
          {_playing ? (
            <Pause className="inline" aria-label="Pause Background Video" />
          ) : (
            <Play className="inline" aria-label="Play Background Video" />
          )}
        </button>
      ) : null}
      <div
        className="absolute inset-0"
        style={{
          backgroundColor: `rgba(52, 52, 52, ${dimPercent / 100})`,
        }}
      ></div>
    </ScaleUpOnScroll>
  );
}
