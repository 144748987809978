import { SVGProps } from "react";
const SvgArrowBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <polyline
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      points="2 14 9 20 22 4"
    ></polyline>
  </svg>
);
export default SvgArrowBlack;
