import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import {
  IHeroWithImageFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";

type ReduceHeroWithImageProps = {
  fields: SpecificLocaleFields<IHeroWithImageFields>;
};

export default function ReduceHeroWithImage({
  fields,
}: ReduceHeroWithImageProps) {
  return (
    <section
      data-content-type="reducedHeroWithImage"
      data-module-title={fields.moduleTitle}
      className={clsx("w-full", {
        "text-white": ["black"].includes(fields.backgroundColor),
        "text-black": ["white", "slate"].includes(fields.backgroundColor),
        "bg-black": fields.backgroundColor === "black",
        "bg-white": fields.backgroundColor === "white",
        "bg-slate": fields.backgroundColor === "slate",
      })}
      style={{
        backgroundColor: fields.colorPalette?.fields.backgroundColor,
        color: fields.colorPalette?.fields.fontColor,
      }}
    >
      <div className="tbi-grid gap-y-10 pt-10 pb-10 lg:pt-24 lg:pb-20">
        <div className="col-span-6 lg:col-span-6">
          <div className="body mb-6 uppercase lg:mb-10">
            {fields.eyebrowText}
          </div>
          <h2 className="h1 col-span-full lg:col-span-6 lg:mb-10">
            {fields.heading}
          </h2>
          {fields.body && (
            <div className="[&_p]:body pb-8 lg:pb-10 [&_p]:mt-6">
              {documentToReactComponents(
                fields.body,
                getComponentFromContentfulRichTextEntry
              )}
            </div>
          )}
          {fields.logo && (
            <img
              className="h-[43px] lg:h-[56px]"
              src={
                fields.logo.fields.frontifyImage
                  ? fields.logo.fields.frontifyImage[0].generic_url
                  : fields.logo.fields.image?.fields?.file?.url
              }
              alt={fields.logo.fields.alt}
            />
          )}
        </div>
        <div
          className={clsx("col-span-6 lg:col-span-5 lg:col-start-8 lg:mt-36")}
        >
          {fields.image && (
            <img
              className="w-full object-contain"
              src={
                fields.image.fields.frontifyImage
                  ? fields.image.fields.frontifyImage[0].generic_url
                  : fields.image.fields.image?.fields?.file?.url
              }
              alt={fields.image.fields.alt}
            />
          )}
        </div>
      </div>
    </section>
  );
}
