import { ClientSide, InsightGetters } from "~/@types";
// import NavOrExternalLink from "../shared/NavOrExternalLink";
import { motion } from "framer-motion";
import { ExpertsCarouselModal } from "../experts/ExpertCarouselTakeover";
import {
  PageComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import { IExpertFields } from "~/@types/generated/contentful";
import { useNavShowing } from "~/contexts/NavShowingProvider";
import { useState } from "react";
import { SimpleExpert } from "../experts/ExpertsCard";
import clsx from "clsx";

type ExpertsProps = {
  experts: NonNullable<ReturnType<InsightGetters["getExperts"]>>;
  className?: string;
};

export default function Experts({ experts, className }: ExpertsProps) {
  const { experts: activeExperts } = useComponentData() as PageComponentData;
  const { setNavDisabled, setNavShowing } = useNavShowing();
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    if (show) {
      setShow(false);
      setNavDisabled(false);
      setNavShowing(true);
      document.body.style.overflow = "";
    } else {
      setShow(true);
      setNavShowing(false);
      setNavDisabled(true);
      document.body.style.overflow = "hidden";
    }
  };

  if (!experts.length) {
    return (
      <span className={clsx("meta w-max cursor-default", className)}>TBI</span>
    );
  }

  const fullExperts = experts.map((expert) => {
    const expertObj = activeExperts?.find((e) => e.slug === expert.slug);
    if (expertObj) {
      return expertObj;
    }
    return expert;
  }) as unknown as ClientSide<IExpertFields>[] | SimpleExpert[];

  return (
    <>
      <ExpertsCarouselModal
        experts={fullExperts}
        isOpen={show}
        onClose={toggleShow}
      />
      <motion.button
        onClick={toggleShow}
        whileHover="hover"
        whileInView="noHover"
        className="lgmax:text-left"
      >
        <span className={clsx("meta w-max", className)}>
          {experts.length === 1
            ? experts[0].name
            : `Multiple Experts (${experts.length})`}
        </span>
        <motion.div
          className="flex border-b border-solid border-black content-['']"
          variants={{
            hover: {
              transform: "scaleX(1)",
            },
            noHover: {
              transform: "scaleX(0)",
            },
          }}
          transition={{
            duration: 0.2,
            ease: "easeInOut",
          }}
        />
      </motion.button>
    </>
  );
}
