import { SVGProps } from "react";
const SvgChapterBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M7.625 4.74h-3.1a.04.04 0 0 0-.04.04v3.1c0 .022.018.04.04.04h3.1a.04.04 0 0 0 .04-.04v-3.1a.04.04 0 0 0-.04-.04ZM7.625 16.08h-3.1a.04.04 0 0 0-.04.04v3.1c0 .022.018.04.04.04h3.1a.04.04 0 0 0 .04-.04v-3.1a.04.04 0 0 0-.04-.04ZM7.625 10.4h-3.1a.04.04 0 0 0-.04.04v3.1c0 .023.018.04.04.04h3.1a.04.04 0 0 0 .04-.04v-3.1a.04.04 0 0 0-.04-.04Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M19.515 6.33h-8.47M19.515 11.99h-8.47M19.515 17.67h-8.47"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgChapterBlack;
