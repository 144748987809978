import slugify from "slugify";
import { useEffect, useState } from "react";
import { useNavShowing } from "~/contexts/NavShowingProvider";
import { CircleIconCta, CloseCta, IconCta } from "../ctas";
import { Chapter } from "../icons";
import { HashLink } from "react-router-hash-link";
import { useEffectOnce, useLocation } from "react-use";
import {
  InsightComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import { SimpleSwipeUp } from "../animations";
import { useAnimationControls, motion } from "framer-motion";

export default function ChapterTakeover() {
  const { hash } = useLocation();
  const { setNavDisabled, setNavShowing } = useNavShowing();
  const { chapters } = useComponentData() as InsightComponentData;
  const [open, setOpen] = useState(false);

  useEffectOnce(() => {
    // Added a check for no equals since this doesn't work if the hash is used as something other than an anchor tag.
    // e.g. /insights/public-services/page#msdynttrid=JVE3Ja3Wu10pdayKX0WLD9wxVV8Y4P5AOfNpAEzg7Mg
    if (hash && hash.indexOf("=") === -1) {
      const hashChapter = document.querySelector(hash);
      hashChapter?.scrollIntoView();
    }
  });

  const controls = useAnimationControls();

  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.15 + 0.2,
        duration: 0.7,
      },
    }));
  }, [open, controls]);

  const onOpenPopover = () => {
    document.body.style.overflow = "hidden";
    setOpen(true);
    setNavShowing(false);
    setNavDisabled(true);
  };

  const onClosePopover = () => {
    document.body.style.overflow = "";
    setOpen(false);
    setNavShowing(true);
    setNavDisabled(false);
  };

  return (
    <>
      <CircleIconCta
        id="chapter-takeover-button-mobile"
        as="div"
        kind="white"
        icon={<Chapter />}
        label="Chapters"
        className="mb-4 border border-solid border-dark_grey lgmax:hidden"
        onClick={onOpenPopover}
      />
      <IconCta
        id="chapter-takeover-button-desktop"
        as="div"
        kind="black"
        icon={<Chapter />}
        label="Chapters"
        className="lg:hidden"
        onClick={onOpenPopover}
      />
      <SimpleSwipeUp open={open}>
        <div className="fixed inset-0 z-40 h-screen w-screen bg-black">
          <div
            id="chapter-takeover"
            data-open={open}
            className="relative h-full w-full"
          >
            <div className="absolute inset-0 z-[1] h-min w-screen lg:bg-transparent lgmax:bg-black">
              <div className="tbi-grid w-full py-6 lg:pt-16">
                <p className="eyebrows col-span-1 flex items-center text-white lg:col-span-1">
                  Chapters
                </p>
                <div className="col-span-1 col-start-4 flex h-full w-full justify-end lg:col-start-12">
                  <CloseCta
                    className="flex items-start justify-end"
                    id="chapter-takeover-close"
                    kind="white"
                    onClick={onClosePopover}
                  />
                </div>
              </div>
            </div>
            <div className="tbi-grid absolute inset-0 h-screen w-full overflow-y-scroll pt-20 lg:pt-[88px]">
              <ul className="col-span-4 h-max border-t border-white pb-36 lg:col-span-8 lg:col-start-3 lg:pb-24">
                {chapters &&
                  Object.entries(chapters).map(([id, chapter], ix) => {
                    return (
                      <motion.div
                        key={id}
                        className="w-full"
                        initial={{
                          opacity: 0,
                          x: "-100%",
                        }}
                        custom={ix}
                        animate={controls}
                      >
                        <HashLink
                          smooth
                          id={`chapter-takeover-link-${chapter.heading}`}
                          to={`#${slugify(chapter.heading, {
                            lower: true,
                            strict: true,
                            remove: new RegExp("^[0-9]*"),
                          })}`}
                          className="grid w-full gap-4 border-b border-solid border-white py-6 text-left lg:grid-cols-8 lg:py-9"
                          onClick={onClosePopover}
                        >
                          <p className="eyebrows text-white lg:col-span-1">
                            {("00" + (ix + 1)).slice(-2)}
                          </p>
                          <h4 className="text-white lg:col-span-7">
                            {chapter.heading}
                          </h4>
                        </HashLink>
                      </motion.div>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </SimpleSwipeUp>
    </>
  );
}
