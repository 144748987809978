import { SVGProps } from "react";
const SvgPlayBlack = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : 24}
    height={props?.height ? props.height : 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M7.863 7.201v9.6c0 .42.47.67.82.43l7.22-4.89c.31-.21.31-.67 0-.87l-7.22-4.71a.522.522 0 0 0-.81.44h-.01Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPlayBlack;
