import { Document as ContentfulDocument } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ClientSide } from "~/@types";
import { IExpert } from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import { ContentfulCenteredImage, ForcedAspectRatio } from "../shared";
import ExpertSocialLinks from "./ExpertSocialLinks";

type BioProps = {
  expert: ClientSide<IExpert>;
  close?: () => void;
};

export default function Bio({ expert }: BioProps) {
  return (
    <div className="h-full w-full lgmax:overflow-y-scroll lgmax:pb-24">
      <div
        className="absolute inset-0 h-full w-[calc(80px+16vw+24px)] bg-onyx lgmax:hidden"
        onClick={() => close && close()}
      />
      <div className="tbi-grid pt-9 lg:pt-10">
        <div className="col-span-full h-max lg:col-span-3 lg:col-start-4">
          {expert.fields.image ? (
            <ForcedAspectRatio ratio={[3, 4]} className="w-full lg:mb-4">
              <ContentfulCenteredImage
                className="h-full w-full"
                image={expert.fields.image.fields}
              />
            </ForcedAspectRatio>
          ) : (
            <ForcedAspectRatio
              ratio={[3, 4]}
              className="h1 flex-center align-center w-full bg-slate text-black lg:mb-4"
            >
              {expert.fields.firstName?.trim()[0]}
              {expert.fields.lastName?.trim()[0]}
            </ForcedAspectRatio>
          )}
          <ExpertSocialLinks
            invertedColors
            expert={expert}
            className="justify-center lgmax:hidden"
          />
        </div>
        <div className="col-span-full h-max lg:col-span-4 lg:col-start-8">
          <div className="col-span-full text-left lg:col-span-4 lgmax:mt-10">
            <h2 className="h3 mb-4">
              {expert.fields.firstName} {expert.fields.lastName}
            </h2>
            <p className="eyebrows mb-2">{expert.fields.role}</p>
            <ExpertSocialLinks
              invertedColors
              expert={expert}
              className="justify-start lg:hidden"
            />
            <div className="lg:max-h-[50vh] lg:overflow-y-scroll">
              {expert.fields.bio && (
                <div className="[&_p]:body-small h-max pb-36 lg:pb-[25vh]">
                  {documentToReactComponents(
                    expert.fields.bio as ContentfulDocument,
                    getComponentFromContentfulRichTextEntry
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
