import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import {
  IStatisticsRowFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import {
  InsightComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";

type StatisticsRowProps = {
  fields: SpecificLocaleFields<IStatisticsRowFields>;
};

const colStartMap = {
  1: "lg:first:col-start-5",
  2: "lg:first:col-start-3",
  3: "lg:first:col-start-1",
};

export default function StatisticsRow({ fields }: StatisticsRowProps) {
  const length = fields.blocks.length as 1 | 2 | 3;
  const { insight } = useComponentData() as InsightComponentData;

  return (
    <section
      data-content-type="statisticsRow"
      data-module-title={fields.moduleTitle}
      className={clsx(
        "global-container global-grid col-span-full py-24 lg:py-32",
        {
          "text-white": ["black"].includes(fields.backgroundColor),
          "text-black": ["white", "slate"].includes(fields.backgroundColor),
          "bg-black": fields.backgroundColor === "black",
          "bg-white": fields.backgroundColor === "white",
          "bg-slate": fields.backgroundColor === "slate",
          "lg:px-0": insight,
        }
      )}
    >
      {fields.blocks.map((block, i) => (
        <div
          key={i}
          className={clsx(
            "col-span-4 mb-16 text-center first:col-start-1 last:mb-0 lg:mb-0",
            colStartMap[length]
          )}
        >
          <p className="display whitespace-nowrap">{block.fields.heading}</p>

          <div className="body m-auto mt-1 w-9/12 max-w-xs lgmax:[&>:first-child]:mt-0">
            {block.fields.body &&
              documentToReactComponents(
                block.fields.body,
                getComponentFromContentfulRichTextEntry
              )}
          </div>
        </div>
      ))}
    </section>
  );
}
