import clsx from "clsx";
import {
  IAnchorLinksFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { Arrow } from "~/components/icons";
import { RoundedCta, TextCta } from "../ctas";
import { bgColorOverrides } from "../Insight/CardsGrid";
import React from "react";

type AnchorLinksProps = {
  fields: SpecificLocaleFields<IAnchorLinksFields>;
};

export default function AnchorLinks({ fields }: AnchorLinksProps) {
  return (
    <div
      data-content-type="anchorLinks"
      data-module-title={fields.moduleTitle}
      className={clsx(
        "w-screen text-black",
        fields.backgroundColor &&
          !fields.colorPalette?.fields.backgroundColor &&
          bgColorOverrides[fields.backgroundColor],
        {
          "text-white": fields.backgroundColor === "black",
        }
      )}
      style={{
        backgroundColor: fields.colorPalette?.fields.backgroundColor,
        color: fields.colorPalette?.fields.fontColor,
      }}
    >
      <div
        className={clsx(
          "global-container global-grid w-screen pt-12 pb-6 lg:pt-16"
        )}
      >
        <div className="col-span-3">
          <h2 className="h3 mb-4 normal-case lg:mb-6">{fields.eyebrow}</h2>
          {/* CTA */}
          {fields.button && (
            <RoundedCta
              className="mt-6"
              kind={
                fields.backgroundColor ? fields.button?.fields.type : "hollow"
              }
              label={fields.button?.fields.buttonText}
              icon={<Arrow />}
              to={fields.button?.fields.buttonUrl}
              custom={fields.button.fields.colorPalette?.fields}
            />
          )}
        </div>

        <nav className="col-span-full mt-8 lg:col-span-7 lg:col-start-6 lg:mt-0">
          {fields.links &&
            fields.links.map((link) => (
              <React.Fragment key={link.sys.id}>
                <div className="flex items-center justify-between">
                  <TextCta
                    className={
                      "h4 float-right !w-full justify-between gap-[32px] normal-case hover:decoration-1 hover:underline-offset-2 lg:!w-auto"
                    }
                    kind={
                      fields.backgroundColor === "black" ? "white" : "black"
                    }
                    label={link.fields.heading ?? "Link"}
                    icon={<Arrow />}
                    to={`#${encodeURIComponent(link.fields.anchorLink)}`}
                    colorOverride={fields.colorPalette?.fields.fontColor}
                    iconOverride={fields.colorPalette?.fields.accentColor}
                  />
                </div>
                {/* horizontal line */}
                <div
                  className={clsx(
                    "my-6 h-[1px] w-full bg-black lg:my-10",
                    fields.backgroundColor === "black" && "bg-white"
                  )}
                  style={{
                    backgroundColor: fields.colorPalette?.fields.fontColor,
                  }}
                />
              </React.Fragment>
            ))}
        </nav>
      </div>
    </div>
  );
}
