import {
  IRoundedCtaButtonFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { RoundedCta } from "../ctas";
import { Arrow } from "../icons";

type RoundedCtaItemProps = {
  fields: SpecificLocaleFields<IRoundedCtaButtonFields>;
};

export default function RoundedCtaItem({ fields }: RoundedCtaItemProps) {
  return (
    <div>
      <RoundedCta
        dataAttrs={{
          "data-content-type": "singleImageParallax",
          "data-module-title": fields.moduleTitle,
        }}
        label={fields.buttonText}
        icon={<Arrow />}
        kind={fields.type}
        to={fields.buttonUrl}
        custom={fields.colorPalette?.fields}
      />
    </div>
  );
}
