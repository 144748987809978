import {
  ISingleImageParallaxFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import MonologueAndMedia from "./MonolugeAndMedia";
import SingleParallax from "./SingleParallax";

type SingleImageParallaxProps = {
  fields: SpecificLocaleFields<ISingleImageParallaxFields>;
};

export default function SingleImageParallax({
  fields,
}: SingleImageParallaxProps) {
  if (fields.textInFront) {
    return <SingleParallax fields={fields} />;
  }

  return <MonologueAndMedia fields={fields} />;
}
