import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import {
  IFiftyFiftyFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";

import { RoundedCta } from "../ctas";
import { Arrow } from "../icons";
import { ContentfulCenteredImage } from "../shared";
import ImageSlowScroll from "../shared/ImageSlowScroll";

type FiftyFiftyProps = {
  fields: SpecificLocaleFields<IFiftyFiftyFields>;
};

// And set up slate and white bgs
export default function FiftyFifty({ fields }: FiftyFiftyProps) {
  const renderImage = () => (
    <div
      className={clsx(
        "col-span-full h-min  lg:col-span-5 lg:flex lg:items-center lgmax:mb-10",
        {
          "mb-10 lg:col-start-2": !fields.inverted,
          "lg:col-end-12": fields.inverted,
        }
      )}
    >
      {fields.image && (
        <ImageSlowScroll>
          <ContentfulCenteredImage
            className="aspect h-full w-full lg:aspect-[3/4] lgmax:aspect-square"
            image={fields.image.fields}
          />
        </ImageSlowScroll>
      )}
    </div>
  );
  const renderText = () => (
    <div
      className={clsx("col-span-full lg:col-span-4 lg:flex lg:items-center", {
        "lg:col-start-2": fields.inverted,
        "lg:col-end-12": !fields.inverted,
      })}
    >
      <div>
        <h2 className="h4 col-span-full lg:col-span-6 lg:mb-10">
          {fields.heading}
        </h2>
        {fields.body && (
          <div className="[&_p]:body pb-10 [&_p]:lgmax:mt-6">
            {documentToReactComponents(
              fields.body,
              getComponentFromContentfulRichTextEntry
            )}
          </div>
        )}
        {fields.buttonText && fields.buttonUrl && !fields.button && (
          <RoundedCta
            label={fields.buttonText}
            className="mb-10"
            kind={fields.backgroundColor === "black" ? "white" : "black"}
            icon={<Arrow />}
            to={fields.buttonUrl}
          />
        )}
        {fields.button && (
          <RoundedCta
            label={fields.button.fields.buttonText}
            className="mb-10"
            kind={fields.button.fields.type}
            icon={<Arrow />}
            to={fields.button.fields.buttonUrl}
            custom={fields.button.fields.colorPalette?.fields}
          />
        )}
      </div>
    </div>
  );

  return (
    <section
      data-content-type="50-50"
      data-module-title={fields.moduleTitle}
      className={clsx("w-screen", {
        "text-white": ["black"].includes(fields.backgroundColor ?? "white"),
        "text-black": ["white", "slate"].includes(
          fields.backgroundColor ?? "white"
        ),
        "bg-black": fields.backgroundColor === "black",
        "bg-white": fields.backgroundColor === "white",
        "bg-slate": fields.backgroundColor === "slate",
      })}
      style={{
        backgroundColor: fields.colorPalette?.fields.backgroundColor,
        color: fields.colorPalette?.fields.fontColor,
      }}
    >
      <div
        className={clsx(
          "global-container global-grid w-full py-16 lg:py-20 lgmax:flex",
          {
            "lgmax:flex-col-reverse": fields.inverted,
            "lgmax:flex-col": !fields.inverted,
          }
        )}
      >
        {fields.inverted ? renderText() : renderImage()}
        {fields.inverted ? renderImage() : renderText()}
      </div>
    </section>
  );
}
