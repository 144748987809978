import { AlgoliaInsight } from "~/@types";
import { ContentfulCenteredImage } from "../shared";
import NavOrExternalLink from "../shared/NavOrExternalLink";
import { getPrettyDate } from "../utils/getPrettyDate";

type SearchCardProps = {
  insight: AlgoliaInsight;
  onClick?: () => void;
};

export default function InsightSearchCard({
  insight,
  onClick,
}: SearchCardProps) {
  const insightLocation = `/insights/${insight.topic?.slug}/${insight.slug}`;
  const topicLocation = `/insights/${insight.topic?.slug}`;

  return (
    <div
      data-content-type="insightsSearchCard"
      data-module-title={insight.slug}
      className="global-grid items-top w-full border-t-[1px] border-none border-black pb-10 last:border-b-[1px] lg:border-solid lg:py-10"
    >
      {/* topic label */}
      <NavOrExternalLink
        to={topicLocation}
        className="col-[2_/_-1] mb-3 ml-5 flex items-center lg:col-[4_/_-1] lg:mb-4 lg:ml-0"
      >
        <div
          className="mr-2 aspect-square h-[10px] rounded-full lg:mr-3 lg:h-3"
          style={{
            backgroundColor: insight.topic?.color,
          }}
        />
        <p className="eyebrows">{insight.topic?.title}</p>
      </NavOrExternalLink>
      {/* Thumbnail and heading card */}
      <div className="global-grid relative col-span-full">
        {insight.image && (
          <ContentfulCenteredImage
            className="col-span-1 -mt-[1.8rem] aspect-[9_/_16] max-h-28 w-[calc(100%_+_1.25rem)] lg:col-span-2 lg:mt-0 lg:aspect-[16_/_9] lg:w-full"
            image={insight.image}
          />
        )}
        {/* Title/Date */}
        <NavOrExternalLink
          onClick={onClick}
          to={insightLocation}
          className="col-[2_/_-1] ml-5 before:absolute before:inset-0 before:content-[''] lg:col-[4_/_9] lg:ml-0"
        >
          <h3 className="h5 lg:h3 mb-4">{insight.title}</h3>
          <p className="meta-small text-secondary_text">
            {getPrettyDate(insight.date)}
          </p>
        </NavOrExternalLink>
      </div>
    </div>
  );
}
