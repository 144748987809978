import {
  IVideoHeroFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { ContentfulCenteredImage } from "../shared";
import { BarnDoorSwipe, FadeIn, PopUpText } from "../animations";
import FullscreenVideo from "./FullscreenVideo";

type VideoHeroProps = {
  fields: SpecificLocaleFields<IVideoHeroFields>;
};

export default function VideoHero({ fields }: VideoHeroProps) {
  const _firstAnimationDuration = 2000;
  const _secondAnimationDuration = 2000;
  const _thirdAnimationDuration = 1000;

  const videoUrl = fields.video ? fields.video.fields?.file?.url : "";

  return (
    <section
      data-content-type="videoHero"
      data-module-title={fields.moduleTitle}
      className="h-1 min-h-screen w-full pb-10 lg:pb-16"
    >
      <div className="relative h-full w-full">
        <div className="h-full lgmax:hidden">
          <PopUpText
            text={fields.popupText}
            className={"absolute inset-0"}
            textClassName={"text-2xl"}
          />
          <FadeIn
            className="absolute inset-0 z-10"
            durationInMilliseconds={_thirdAnimationDuration}
            delayInMilliseconds={
              _firstAnimationDuration + _secondAnimationDuration - 1000
            }
          >
            <div className="tbi-grid absolute left-0 right-0 bottom-20 text-white">
              <p className="eyebrows col-span-8 pb-10">{fields.eyebrow}</p>
              <h1 className="col-span-8">{fields.title}</h1>
            </div>
          </FadeIn>
          <BarnDoorSwipe
            durationInMilliseconds={_secondAnimationDuration}
            delayInMilliseconds={_firstAnimationDuration}
          >
            <FullscreenVideo
              url={videoUrl}
              dimPercent={50}
              fallbackImage={
                fields.fallbackImage && fields.fallbackImage.fields
              }
            />
          </BarnDoorSwipe>
        </div>
        <div className="h-full lg:hidden">
          {fields.fallbackImage && (
            <ContentfulCenteredImage
              className={"h-full w-full"}
              image={fields.fallbackImage.fields}
            />
          )}
          <div className="absolute inset-0 z-10">
            <div className="tbi-grid absolute bottom-[120px] text-white">
              {fields.eyebrow && (
                <p className="eyebrows col-span-8 pb-10">{fields.eyebrow}</p>
              )}
              <h1 className="col-span-8">{fields.title}</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
