import { Arrow } from "../icons/svgs";
import { CloseCta, RoundedCta } from "../ctas";
import { IExpert } from "~/@types/generated/contentful";
import { ClientSide } from "~/@types";
import { useNavShowing } from "~/contexts/NavShowingProvider";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Bio from "./Bio";

type ExpertBioTakeoverProps = {
  className?: string;
  expert: ClientSide<IExpert>;
  label?: string;
};

export default function ExpertBioTakeover({
  className = "",
  expert,
  label = "Biography",
}: ExpertBioTakeoverProps) {
  const { setNavDisabled, setNavShowing } = useNavShowing();
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    if (show) {
      setShow(false);
      setNavDisabled(false);
      setNavShowing(true);
      document.body.style.overflow = "";
    } else {
      setShow(true);
      setNavShowing(false);
      setNavDisabled(true);
      document.body.style.overflow = "hidden";
    }
  };

  return (
    <section className={`w-min ${className}`}>
      <RoundedCta
        onClick={toggleShow}
        id="expert-bio-takeover-open"
        className="mx-auto"
        kind="black"
        icon={<Arrow />}
        label={label}
        type="button"
      />
      <AnimatePresence>
        {show && (
          <motion.div
            key="takeover"
            // z is one higher than nav
            className="fixed top-0 z-[1001] h-screen w-screen"
            initial={{
              left: "100vw",
            }}
            animate={{
              left: "0vw",
            }}
            exit={{
              left: "100vw",
            }}
            transition={{
              duration: 0.5,
            }}
          >
            <div className="relative h-full w-full bg-black pt-4 text-white lg:pt-20">
              <div className="global-container col-span-full flex h-10 items-center justify-end">
                <CloseCta
                  id="expert-bio-takeover-close"
                  kind={"white"}
                  onClick={toggleShow}
                />
              </div>
              <Bio expert={expert} close={toggleShow} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
}
