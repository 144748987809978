import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useEffectOnce, useEvent } from "react-use";
import {
  IMediaTabsFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { Arrow } from "../icons";
import clsx from "clsx";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import { Document as ContentfulDocument } from "@contentful/rich-text-types";
import ReactPlayer from "react-player";

type MediaTabsProps = {
  fields: SpecificLocaleFields<IMediaTabsFields>;
};

type VideoUrl = {
  url: string;
};

const VideoTab = ({ url }: VideoUrl) => {
  const [playing, setPlaying] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <div className="relative col-span-full aspect-video">
      <div className="absolute inset-0 z-10 flex h-full w-full cursor-pointer items-center justify-center bg-transparent">
        {!playing && (
          <div
            className="z-10 flex h-16 w-16 items-center justify-center rounded-full bg-white text-black lg:h-24 lg:w-24"
            onClick={() => setPlaying(!playing)}
            tabIndex={0}
          >
            <p className="body-large">PLAY</p>
          </div>
        )}
        {url && (
          <ReactPlayer
            playing={playing}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            url={url}
            width="100%"
            height="100%"
            controls={playing}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default function MediaTabs(props: MediaTabsProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { fields } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showRight, setShowRight] = useState(false);
  const [showLeft, setShowLeft] = useState(false);

  const setShowArrows = () => {
    if (ref.current) {
      const shouldShowRight =
        ref.current.scrollLeft !==
        ref.current.scrollWidth - ref.current.offsetWidth;
      setShowRight(shouldShowRight);

      const shouldShowLeft = ref.current.scrollLeft !== 0;
      setShowLeft(shouldShowLeft);
    }
  };

  const scrollTopicsRight = () => {
    if (ref.current) {
      ref.current.scroll({
        left: ref.current.scrollLeft + 200,
        behavior: "smooth",
      });
    }
  };

  const scrollTopicsLeft = () => {
    if (ref.current) {
      ref.current.scroll({
        left: ref.current.scrollLeft - 200,
        behavior: "smooth",
      });
    }
  };

  useEffectOnce(() => {
    setShowArrows();
  });

  useEvent("scroll", setShowArrows, ref.current);

  return (
    <section
      id={fields.moduleTitle && encodeURIComponent(fields.moduleTitle)}
      data-content-type="mediaTabs"
      data-module-title={fields.moduleTitle}
      className={clsx({
        "bg-white": fields.backgroundColor === "white",
        "bg-slate":
          fields.backgroundColor === "slate" || !fields.backgroundColor,
      })}
    >
      {fields.heading && (
        <div className="tbi-grid relative h-full w-screen px-mobile-gutter pt-16 lg:px-desktop-gutter lg:pt-20">
          <h2 className="h3 col-span-full mb-16 max-w-content-max border-b border-black pb-2 -tracking-[.72px] text-black lg:col-span-10 lg:col-start-2 lg:-tracking-[.96px]">
            {fields.heading}
          </h2>
        </div>
      )}
      <div
        className={clsx(
          "tbi-grid relative h-full w-screen px-mobile-gutter lg:px-desktop-gutter",
          fields.heading ? "pt-0 pb-16 lg:pb-20" : "py-16 lg:py-20"
        )}
      >
        {/* TABS */}
        <Arrow
          className={clsx(
            "flex-center absolute right-6 z-10",
            showRight ? "opacity-100" : "opacity-0",
            fields.heading ? "top-0" : "top-[4rem]"
          )}
          onClick={scrollTopicsRight}
        />
        <Arrow
          className={clsx(
            "flex-center absolute left-6 z-10 rotate-180",
            showLeft ? "opacity-100" : "opacity-0",
            fields.heading ? "top-0" : "top-[4rem]"
          )}
          onClick={scrollTopicsLeft}
        />
        <div
          className={clsx(
            "col-span-full max-w-content-max text-black lg:col-span-10 lg:col-start-2",
            {
              "fade-out-gradient-right": showRight,
              "fade-out-gradient-left": showLeft,
              "fade-out-gradient-both": showLeft && showRight,
            }
          )}
        >
          <div className="no-scrollbar mb-16 overflow-x-scroll" ref={ref}>
            <div className="flex w-max">
              {fields.tabs?.map((tab, index) =>
                tab.fields ? (
                  <div key={index}>
                    <button
                      className="p text-shadow mr-6 min-w-max text-14 font-medium uppercase lg:mr-9 lg:hover:border-b lg:hover:border-solid lg:hover:border-black"
                      onClick={() => {
                        setSelectedIndex(index);
                      }}
                    >
                      {tab.fields.tabLabel}
                    </button>
                    <AnimatePresence>
                      {selectedIndex === index && (
                        <motion.div
                          className="mr-6 -mt-0.5 flex border-b border-solid border-black lg:mr-9"
                          initial={{
                            transform: "scaleX(0)",
                          }}
                          animate={{
                            transform: "scaleX(1)",
                            transition: { duration: 0.5 },
                          }}
                          exit={{
                            transform: "scaleX(0)",
                            transition: { duration: 0.5 },
                          }}
                        />
                      )}
                    </AnimatePresence>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </div>

        <div className="col-span-full lg:col-span-10 lg:col-start-2">
          <div className="relative overflow-hidden">
            {fields.tabs?.map(
              (tab, index) =>
                index == selectedIndex && (
                  <div
                    key={index}
                    className={`${
                      fields.twoColumn
                        ? "lg:grid lg:grid-flow-col lg:grid-cols-10 lg:gap-x-6"
                        : ""
                    }`}
                  >
                    <div
                      className={`${
                        fields.twoColumn
                          ? "col-start-6 col-end-11 aspect-video"
                          : "aspect-video"
                      }`}
                    >
                      {tab.fields.media.fields.file.contentType.startsWith(
                        "video"
                      ) && <VideoTab url={tab.fields.media.fields.file.url} />}
                      {tab.fields.media.fields.file.contentType.startsWith(
                        "image"
                      ) && (
                        <img
                          src={tab.fields.media.fields.file.url}
                          alt={tab.fields.media.fields.title ?? "image"}
                          className="h-full w-full object-cover"
                          onError={(
                            e: React.SyntheticEvent<HTMLImageElement, Event>
                          ) =>
                            (e.currentTarget.src =
                              "https://tbi-assets.s3.eu-west-2.amazonaws.com/all-images/placeholder.png")
                          }
                        />
                      )}
                    </div>
                    {/* OPTIONAL TEXTS */}
                    {(tab.fields.title || tab.fields.description) && (
                      <div
                        className={`${
                          fields.twoColumn
                            ? "col-start-1 col-end-5 mt-6 lg:mt-0 lg:flex lg:flex-col lg:gap-6"
                            : "mt-6 grid grid-cols-4 gap-x-5 lg:grid-cols-10 lg:gap-x-6"
                        }`}
                      >
                        {tab.fields.title && (
                          <h4 className="col-span-full lg:col-span-4 lg:col-start-1">
                            {tab.fields.title}
                          </h4>
                        )}
                        {tab.fields.description && (
                          <div className="mediaTabs col-span-full mt-6 lg:col-span-6 lg:col-start-5 lg:mt-0">
                            {documentToReactComponents(
                              tab.fields.description as ContentfulDocument,
                              getComponentFromContentfulRichTextEntry
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
