import { Entry } from "contentful";
import {
  IComparisonChart,
  IGridCards,
  IHtmlStringFields,
  IImageFields,
  ITableFields,
  SpecificLocale,
} from "~/@types/generated/contentful";

export const assertImage = (
  item: SpecificLocale<Entry<any>> | undefined
): item is SpecificLocale<Entry<IImageFields>> => {
  return item?.sys?.contentType?.sys?.id === "image";
};

export const assertHtmlString = (
  item: SpecificLocale<Entry<any>> | undefined
): item is SpecificLocale<Entry<IHtmlStringFields>> => {
  return item?.sys?.contentType?.sys?.id === "htmlString";
};

export const assertTable = (
  item: SpecificLocale<Entry<any>> | undefined
): item is SpecificLocale<Entry<ITableFields>> => {
  return item?.sys?.contentType?.sys?.id === "table";
};

export const assertGridCards = (
  item: SpecificLocale<Entry<any>> | undefined
): item is SpecificLocale<Entry<IGridCards>> => {
  return item?.sys?.contentType?.sys?.id === "gridCards";
};

export const assertComparisonChart = (
  item: SpecificLocale<Entry<any>> | undefined
): item is SpecificLocale<Entry<IComparisonChart>> => {
  return item?.sys?.contentType?.sys?.id === "comparisonChart";
};
