import { Arrow } from "../icons";
import { Children, ReactNode, useState, useEffect, useCallback } from "react";
import CircleIconCta from "../ctas/CircleIconCta";
import useEmblaCarousel from "embla-carousel-react";
import clsx from "clsx";

export type EmblaCarouselProps = {
  className?: string;
  children: ReactNode;
  navigationType?: "none" | "footer" | "overlay";
  navigationCallback?: (index: number) => void;
  startIndex?: number;
  loops?: boolean;
  peek?: boolean;
  speed?: number;
  reset?: boolean;
};

export default function EmblaCarousel({
  className = "",
  children,
  navigationType = "none",
  navigationCallback,
  startIndex = 0,
  loops = false,
  peek = false,
  speed = 10,
  reset = false,
}: EmblaCarouselProps) {
  const [viewportRef, embla] = useEmblaCarousel({
    loop: loops,
    skipSnaps: false,
    startIndex: startIndex,
    speed: speed,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollTo = useCallback(
    (index: number) => embla && embla.scrollTo(index),
    [embla]
  );

  useEffect(() => {
    if (reset)
      embla?.reInit({
        loop: loops,
        skipSnaps: false,
        speed: speed,
      });
  }, [reset, embla, loops, startIndex, speed]);

  const _numberOfSlides = Children.toArray(children).length;

  const onSelect = useCallback(() => {
    if (!embla) {
      return;
    }
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
    if (navigationCallback) {
      navigationCallback(selectedIndex);
    }
  }, [embla, setSelectedIndex, selectedIndex, navigationCallback]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <div
      className={`embla relative overflow-hidden ${className}`}
      ref={viewportRef}
    >
      <div
        className={`${
          peek ? "w-[90%]" : ""
        } -mr-[50%] ml-[50%] flex select-none`}
      >
        {Children.map(children, (child, index) => (
          <div key={index} className="relative min-w-full">
            <div className="min-h-full w-auto min-w-full max-w-none -translate-x-1/2">
              {child}
            </div>
          </div>
        ))}
      </div>
      {navigationType === "footer" && (
        <>
          <div className="flex justify-center pt-5">
            {scrollSnaps.map((_, ix, scrollSnaps) => (
              <button
                className={clsx(
                  "relative flex h-10 w-full cursor-pointer items-center bg-transparent p-0 after:h-1 after:w-full",
                  {
                    "after:rounded-l-sm": ix === 0,
                  },
                  ix <= selectedIndex ? "after:bg-black" : "after:bg-dark_grey",
                  ix <= scrollSnaps.length - 1 ? "after:rounded-r-sm" : ""
                )}
                key={ix}
                onClick={() => scrollTo(ix)}
                aria-label={"Go to slide #" + ix}
              />
            ))}
          </div>
          <div className="mt-1 flex gap-4">
            <div className="grow" />
            <CircleIconCta
              className={"rotate-180"}
              label={"previous carousel item"}
              kind={"hollow"}
              icon={<Arrow />}
              disabled={!prevBtnEnabled}
              onClick={scrollPrev}
            />
            <CircleIconCta
              label={"next carousel item"}
              kind={"hollow"}
              icon={<Arrow />}
              disabled={!nextBtnEnabled}
              onClick={scrollNext}
            />
          </div>
        </>
      )}
      {navigationType === "overlay" && (
        <div className="absolute inset-x-4 bottom-10 flex items-center gap-6 lg:inset-x-10">
          <div className="eyebrows grow text-white">
            {selectedIndex + 1} / {_numberOfSlides}
          </div>
          <CircleIconCta
            className={"rotate-180"}
            label={"previous item"}
            kind={"white-hollow"}
            icon={<Arrow />}
            disabled={!prevBtnEnabled}
            onClick={scrollPrev}
          />
          <CircleIconCta
            label={"next item"}
            kind={"white-hollow"}
            icon={<Arrow />}
            disabled={!nextBtnEnabled}
            onClick={scrollNext}
          />
        </div>
      )}
    </div>
  );
}
