import { SVGProps } from "react";
const SvgTwitterBlack = (props?: SVGProps<SVGSVGElement>) => {
  const width = props?.width
    ? typeof props?.width == "string"
      ? parseInt(props?.width)
      : props?.width
    : 24;
  const height = props?.height
    ? typeof props?.height == "string"
      ? parseInt(props?.height)
      : props?.height
    : 24;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M16.2175 5.37134H18.4665L13.5532 10.987L19.3334 18.6287H14.8075L11.2627 13.994L7.20665 18.6287H4.95631L10.2116 12.6221L4.66669 5.37134H9.30743L12.5116 9.60756L16.2175 5.37134ZM15.4282 17.2825H16.6744L8.63028 6.64676H7.29299L15.4282 17.2825Z"
        fill={props?.fill ? props.fill : "currentColor"}
      />
    </svg>
  );
};
export default SvgTwitterBlack;
